import React from 'react';
import classes from './Button.module.css';
const Button = (props) => {
    return (
        <button
            id={props.id}
            type={props.type || 'button'}
            className="button"
            onClick={props.onClick}
            disabled={props.disabled}
            className={props.className}
            style={props.style}>
            {props.children}
        </button>
  );
};

export default Button;
