export const menuData_Tester = [
    {
        name: "Home",
        level: "1",
        url: "/Designer",
        children: [
            {
                name: "Notō Designer",
                level: "2",
                url: "/",
                children: [
                    {
                        name: "Study Configurator",
                        level: "3",
                        url: "/Designer"
                    },
                    {
                        name: "Paradigm Designer",
                        level: "3",
                        url: "/Paradigms"
                    }
                ]
            },
            {
                name: "Image Services",
                level: "2",
                url: "/",
                children: [
                    {
                        name: "Dashboard",
                        level: "3",
                        url: "/Dashboard"
                    },
                    {
                        name: "Closed Tasks",
                        level: "3",
                        url: "/ClosedTaskDashboard"
                    },
                    {
                        name: "CR Current Tasks",
                        level: "3",
                        url: "/CRCurrentTask"
                    }          
                ]
            },
            {
                name: "Admin",
                level: "2",
                url: "/",
                children: [
                    {
                        name: "Assign Role",
                        level: "3",
                        url: "/AssignUserRoleList"
                    },
                    {
                        name: "Assign User",
                        level: "3",
                        url: "/AssignUserByProject"
                    },
                    {
                        name: "Manage User",
                        level: "3",
                        url: "/ManageUserList"
                    }]
            }
        ]
    },
    {
        name: "Help",
        level: "1",
        url: "/",
        children: [
            {
                name: " ",
                level: "2",
                url: ""
            }]
    },
    {
        name: "Contact Support",
        level: "1",
        url: "/",
        children: [
            {
                name: " ",
                level: "2",
                url: ""
            }]
    },
    {
        name: "About",
        level: "1",
        url: "/AboutUs",
        children: [
            {
                name: " ",
                level: "2",
                url: ""
            }]
    }
]