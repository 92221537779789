import React from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
const AdvanceSearch = (props) => {
    return (

        <div className="container" style={{"width":"100%", "marginTop":"200px"}}>
            <Row>
                <Col xxl={12}>
                    <div style={{ "border": "solid 1px" }}>
                        sample data!!!!
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default AdvanceSearch;