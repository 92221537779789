import React from 'react'
import { Row } from 'react-bootstrap'
import { Col } from 'react-bootstrap'
import InputNumber from '../../components/UI/Control/InputNumber'
import InputContainer from '../../components/UI/Container/InputContainer'
import MandatoryLabel from '../UI/Control/MandatoryLabel';

const ParadigmStage = (props) => {

    return (
        <InputContainer Expended='true' border='true' header={props.title}>
            <Row style={{ "margin-bottom": "25px", "margin-top": "25px" }}>
                <Col md={5} style={{ "paddingLeft": "35px" }}># of Adj<MandatoryLabel></MandatoryLabel>:</Col>
                <Col md={6}>
                    <input type="hidden" id={"hdnParadigmStageID_" + props.sequence} value={props.item.ParadigmStageID} />
                    <input type="hidden" id={"hdnStageNo_" + props.sequence} value={props.item.StageNo} />
                    <InputNumber id={"NoOfADJ_" + props.sequence} disabled={props.disabled} value={props.item.Adjudications} onchange={(evt) => props.handleInput(evt, (props.sequence - 1))}/>
                </Col>
            </Row>           
        </InputContainer>
    );
}

export default ParadigmStage;