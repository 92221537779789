import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import DataTable from "react-data-table-component";
import SelectList from '../UI/Control/SelectList';
import Button from '../UI/Button/Button';
import InputString from '../UI/Control/InputString';
import classes from '../TrialstatNoto.module.css'
import customStyles from '../customStyles';
import NoData from '../NoData';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';

const AssignedUserRoleModal = (props) => {
    const EDCUserID = useSelector(state => state.auth.gValue01);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        FillAssignedRole();
        fetchUsersData();
        setShow(true);
    }

    const [q, setQ] = useState('');
    const [inputVal, setInputVal] = useState('');
    const [rowData, setRows] = useState([]);
    const [arrAssignedRole, setAssignedRoleList] = useState([]);

    const columns = [
        {
            name: "UserID",
            selector: row => row.UserID,
            cellExport: row => row.UserID,
            omit: true
        },
        {
            name: "Username",
            selector: row => row.UserID,
            cellExport: row => row.UserName,
            omit: true
        },
        {
            name: "Name",
            selector: row => row.Name,
            cellExport: row => row.Name,
            sortable: true,
            center: true
        },
        {
            name: "Email",
            selector: row => row.Email,
            cellExport: row => row.Email,
            sortable: true,
            center: true
        },
        {
            name: "Role",
            sortable: true,
            center: true,
            cell: (row) => {
                return <div style={{ width: "100%" }}>
                    <input type="hidden" id={"hdnFullName_" + row.UserID} value={row.Name} />
                    <input type="hidden" id={"hdnEmail_" + row.UserID} value={row.Email} />
                    <input type="hidden" id={"hdnUserName_" + row.UserID} value={row.UserName} />
                        <SelectList id={"ddlAssignRole_" + row.UserID } dataSource={arrAssignedRole} onChangeHandler={roleChangeHandler} style={{ margin: "5px", width: "90%" }} />
                    </div>
            }
        }
    ];

    const filteredColumns = ['Name', 'Email'];
    function search(rows) {
        let selString = q.toString();
        let searchText = selString.toLowerCase().split(",");
        return rows.filter((row) => {
            return filteredColumns.some((column) => {
                if (searchText.length > 1)
                    return searchText.indexOf(row[column].toString().toLowerCase()) > -1;
                else
                    return row[column].toString().toLowerCase().indexOf(q.toLowerCase()) > -1;
            })
        })

        return rows;
    }

    const searchChangeHandler = (e) => {
        setQ(e.target.value);
        setInputVal(e.target.value);
    }

    const FillAssignedRole = () => {
        fetch('api/Users/FillAssignedRole')
            .then(response => response.json())
            .then(data => {
                let arr = [{ Value: '0', Name: '' }]
                for (var iCount = 0; iCount < data.length; iCount++) {
                    arr.push({ Value: data[iCount].Value, Name: data[iCount].Name });
                }
                setAssignedRoleList(arr);
            });
    }

    const fetchUsersData = () => {
        fetch('api/Users/GetEDCUsers')
            .then(response => response.json())
            .then(data => {
                setRows(data);
            });
    }

    //useEffect(() => {
       
    //}, []);

    let arrUserRole = new Array();
    const roleChangeHandler = (e) => {
        let arr = e.target.id.split('_');
        let edcUserId = arr[1];
        let fullName = document.getElementById('hdnFullName_' + edcUserId).value;
        let email = document.getElementById('hdnEmail_' + edcUserId).value;
        let username = document.getElementById('hdnUserName_' + edcUserId).value;

        let idx = arrUserRole.findIndex(x => x.Username == username);
        if (e.target.value == 0 && idx != -1) {
            arrUserRole.splice(idx, 1);
        }
        else if (e.target.value > 0 && idx != -1) {
            arrUserRole.splice(idx, 1);
            arrUserRole.push({ EDCUserID: edcUserId, FullName: fullName, RoleID: e.target.value, Email: email, Username: username });
        }
        else if (e.target.value > 0) {
            arrUserRole.push({ EDCUserID: edcUserId, FullName: fullName, RoleID: e.target.value, Email: email, Username: username });
        }
    }

    const SaveUserPermissions = (event) => {
        if (arrUserRole.length == 0) {
            alert('No data to save.');
            return false;
        }
        let objUsersModel = {
            Username: EDCUserID,
            listUserPermissionsModel: new Array()
        };

        for (var iCount = 0; iCount < arrUserRole.length; iCount++) {
            objUsersModel.listUserPermissionsModel.push({ EDCUserID: arrUserRole[iCount].EDCUserID, FullName: arrUserRole[iCount].FullName, RoleID: arrUserRole[iCount].RoleID, Email: arrUserRole[iCount].Email, Username: arrUserRole[iCount].Username });
        } 
        
        fetch('api/Users/UpdatePermission', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(objUsersModel)
        }).then(r => r.json()).then(res => {
            if (res == -1) {
                alert('User permission not saved, Something went wrong.');
                return false;
            }

            if (res > 0) {
                props.fetchUsersData(props.userRoleId);
                setShow(false);
            }
        })
        return true;
    };

    return (
        <>
            <button style={props.style} onClick={handleShow} className="btn btn-success"> {props.text} </button>
            <Modal show={show} onHide={handleClose} dialogClassName={classes.TSModalDailog_Two} animation={false} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>{props.header}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row style={{ marginBottom: "10px" }}>
                        <Col md={6}>
                            <div className="input-group">
                                <InputString id="txtSearch" value={inputVal} onChangeHandler={searchChangeHandler} placeholder="Search" />
                            </div>
                        </Col>
                        <Col md={6}>
                        </Col>
                    </Row>
                    <div className="tableContainer">
                        {search(rowData).length > 0 ? <DataTable
                            columns={columns}
                            data={search(rowData)}
                            className="table table-striped table-bordered table-hover"
                            customStyles={customStyles}
                            striped
                            pagination
                            paginationPerPage={50}
                            paginationRowsPerPageOptions={[20, 50, 100]}
                        /> : <NoData headers={columns}></NoData>}
                    </div>
                </Modal.Body>
                <Modal.Footer>

                    <Button variant="secondary" className="btn btn-success" onClick={SaveUserPermissions}> Submit </Button>
                    <Button variant="primary" className="btn btn-danger" onClick={handleClose}>Cancel</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default AssignedUserRoleModal;