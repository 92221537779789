import { createSlice } from '@reduxjs/toolkit';

const initData = {
    gblDt01: "0"
}
const globalDataSlice = createSlice({
    name: 'globalData',
    initialState: initData,
    reducers: {
        storeStudyProtocolID(state, action) {
            state.gblDt01 = action.payload;
        }       
    }
})
export const globalDataAction = globalDataSlice.actions;

export default globalDataSlice.reducer;