import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useSearchParams } from 'react-router-dom';
import DataTable from "react-data-table-component";
import SelectList from '../UI/Control/SelectList';
import Button from '../UI/Button/Button';
import Card from '../UI/Card/Card';
import InputString from '../UI/Control/InputString';
import ArrowLeft from '../../Images/Icons/ArrowLeftBorder.svg';
import ArrowRight from '../../Images/Icons/ArrowRightBorder.svg';
import ExternalReceiptModal from "./ExternalReceiptModal";
import customStyles from '../customStyles';
import NoData from '../NoData';

const Distribution = (props) => {
    const navigate = useNavigate();
    const [q, setQ] = useState('');
    const [searchParams] = useSearchParams();
    const action = searchParams.get('action');
    const isDisabled = (action == 'view' ? true : false);

    let userData = [
        {
            UserID: 1,
            Name: 'Sample1',
            Email: "",
            Company: "acb",
            Role: "Demo User"
        },        
    ];
    let receipientData = [
        {
            ReceiptID: 4,
            Name: 'Sample2',
            Email: "",
            Company: "cdf",
            Role: "Demo User"
        }        
    ];

    const [rowUserData, setUserRows] = useState(userData);
    const [rowReceiptData, setReceiptRows] = useState(receipientData);

    const arrUserType = [
        {
            Name: "Internal",
            Value: "Internal"
        },
        {
            Name: "External",
            Value: "External"
        }
    ]
    const arrApplicableStudy = [
        {
            Name: "Study 1",
            Value: "Study1"
        },
        {
            Name: "Study 2",
            Value: "Study2"
        },
        {
            Name: "Study 3",
            Value: "Study3"
        },
        {
            Name: "Study 4",
            Value: "Study4"
        },
        {
            Name: "Study 5",
            Value: "Study5"
        }
    ]
    const columns = [
        {
            name: "",
            width:"50px",
            cell: (row) => (
                <input
                    type="checkbox"
                    className="chkClassName"
                    onClick={() => handleUserSelectRow(row.UserID, event)}
                    style={{ height: '20px', width: '20px' }}
                />
                )
        },
        {
            name: "Name",
            selector: row => row.Name,
            cellExport: row => row.Name,
            sortable: true,
            center: true
        },
        {
            name: "Email",
            selector: row => row.Email,
            cellExport: row => row.Email,
            sortable: true,
            center: true
        },
        {
            name: "Company",
            selector: row => row.Company,
            cellExport: row => row.Company,
            sortable: true,
            center: true,
        },
        {
            name: "Role",
            selector: row => row.Role,
            cellExport: row => row.Role,
            sortable: true,
            center: true,
            style: {
                'border-right-style': 'solid',
                'border-width': '1px',
                'border-color': 'rgba(0, 0, 0, 0.12)'
            },
        }
    ];
    const receiColumns = [
        {
            name: "",
            width: "50px",
            cell: (row) => (
                <input
                    type="checkbox"
                    className="chkRClassName"
                    onClick={() => handleReceiptSelectRow(row.ReceiptID, event)}
                    style={{ height: '20px', width: '20px' }}
                />
            )
        },
        {
            name: "Name",
            selector: row => row.Name,
            cellExport: row => row.Name,
            sortable: true,
            center: true
        },
        {
            name: "Email",
            selector: row => row.Email,
            cellExport: row => row.Email,
            sortable: true,
            center: true
        },
        {
            name: "Company",
            selector: row => row.Company,
            cellExport: row => row.Company,
            sortable: true,
            center: true,
        },
        {
            name: "Role",
            selector: row => row.Role,
            cellExport: row => row.Role,
            sortable: true,
            center: true,
            style: {
                'border-right-style': 'solid',
                'border-width': '1px',
                'border-color': 'rgba(0, 0, 0, 0.12)'
            },
        }
    ];    
    function search(rows) {
        const columns = rows[0] && Object.keys(rows[0]);
        if (q != '') {
            let selString = q.toString();
            let searchText = selString.toLowerCase().split(",");
            return rows.filter((row) => {
                return columns.some((column) => {
                    if (searchText.length > 1)
                        return searchText.indexOf(row[column].toString().toLowerCase()) > -1;
                    else
                        return row[column].toString().toLowerCase().indexOf(q.toLowerCase()) > -1;
                })
            })
        }
        else
            return rows;
    }
    const searchChangeHandler = (e) => {
        setQ(e.target.value);
    }
   
    const SubmitData = (event) => {
        navigate('/DistributionList');
    };
    const HandleClose = (event) => {
        navigate('/DistributionList');
    };

    var arrUserId = new Array();
    const handleUserSelectRow = (state, event) => {
        if (event.target.checked == true) {
            arrUserId.push(state);
        } else {
            arrUserId = arrUserId.filter(item => item !== state)
        }
    };

    var arrReceiptId = new Array();
    const handleReceiptSelectRow = (state, event) => {
        if (event.target.checked == true) {
            arrReceiptId.push(state);
        }
        else {
            arrReceiptId = arrReceiptId.filter(item => item !== state)
        }
    };

    let itemToMove = new Array();
    let arrMovedData = new Array();
    const handleLeftShift = (e) => {   
        let arrChk = document.getElementsByClassName('chkRClassName');
        for (iCount = 0; iCount < arrChk.length; iCount++) {
            arrChk[iCount].checked = false;
        }
        itemToMove = new Array();       
        for (var iCount = 0; iCount < arrReceiptId.length; iCount++) {
            let itemToMove1 = rowReceiptData.find(item => item.ReceiptID === arrReceiptId[iCount]);
            if (typeof itemToMove1 != 'undefined') {
                itemToMove.push(itemToMove1);
            }
        }

        arrMovedData = new Array();
        for (var jCount = 0; jCount < itemToMove.length; jCount++) {
            let idx = rowUserData.findIndex(item => item.UserID === itemToMove[jCount].ReceiptID)
            if (idx == -1) {
                arrMovedData.push({
                    UserID: itemToMove[jCount].ReceiptID,
                    Name: itemToMove[jCount].Name,
                    Email: itemToMove[jCount].Email,
                    Company: itemToMove[jCount].Company,
                    Role: itemToMove[jCount].Role
                });
            }
        }

        for (var jCount = 0; jCount < rowUserData.length; jCount++) {
            arrMovedData.push({
                UserID: rowUserData[jCount].UserID,
                Name: rowUserData[jCount].Name,
                Email: rowUserData[jCount].Email,
                Company: rowUserData[jCount].Company,
                Role: rowUserData[jCount].Role
            });
        }
       

        //Remove from receipientData
        let tempreceipientData = rowReceiptData.filter((item) => {
            let index = arrReceiptId.indexOf(item.ReceiptID)
            if (index == -1) {
                return item;
            }
        });
        setReceiptRows(tempreceipientData); 
        setUserRows(arrMovedData);
        arrUserId = [];
        arrReceiptId = [];
        arrUserId.length = 0;
        arrReceiptId.length = 0;
    };
    
    const handleRightShift = (e) => {
        let arrChk = document.getElementsByClassName('chkClassName');
        for (iCount = 0; iCount < arrChk.length; iCount++) {
            arrChk[iCount].checked = false;
        }

        itemToMove = new Array();
        for (var iCount = 0; iCount < arrUserId.length; iCount++) {
            let itemToMove1 = rowUserData.find(item => item.UserID === arrUserId[iCount]);
            if (typeof itemToMove1 != 'undefined') {
                itemToMove.push(itemToMove1);
            }
           
        }

        
        for (var jCount = 0; jCount < itemToMove.length; jCount++) {
            let idx = rowReceiptData.findIndex(item => item.ReceiptID === itemToMove[jCount].UserID)
            if (idx == -1) {
                arrMovedData.push({
                    ReceiptID: itemToMove[jCount].UserID,
                    Name: itemToMove[jCount].Name,
                    Email: itemToMove[jCount].Email,
                    Company: itemToMove[jCount].Company,
                    Role: itemToMove[jCount].Role
                });
            }
        }

        for (var jCount = 0; jCount < rowReceiptData.length; jCount++) {
            arrMovedData.push({
                ReceiptID: rowReceiptData[jCount].ReceiptID,
                Name: rowReceiptData[jCount].Name,
                Email: rowReceiptData[jCount].Email,
                Company: rowReceiptData[jCount].Company,
                Role: rowReceiptData[jCount].Role
            });
        }

        //Remove from userData
        let tempuserData = rowUserData.filter((item) => {
            let index = arrUserId.indexOf(item.UserID)
            if (index == -1) {
                return item;
            }
        });

        setUserRows(tempuserData);
        setReceiptRows(arrMovedData);
        arrUserId = [];
        arrReceiptId = [];
        arrUserId.length = 0;
        arrReceiptId.length = 0;
    };

    return (
        <Card>
            <div className="row">
                <div className="col-sm-1">
                </div>
                <div className="col-sm-10">
                    <div className="row">
                        <div className="col-sm-4">
                            <strong>Distribution List Name</strong>
                            <InputString id="txtDistListName" placeholder="Enter the name of the list" disabled={isDisabled} />
                        </div>
                        <div className="col-sm-4">
                            <strong>Internal / External</strong>
                            <SelectList id="selectUserType" dataSource={arrUserType} disabled={isDisabled} />
                        </div>
                        <div className="col-sm-4">
                            <strong>Applicable Studies</strong>
                            <SelectList id="selectApplicableStudy" dataSource={arrApplicableStudy} disabled={isDisabled} />
                        </div>
                    </div>
                </div>
                <div className="col-sm-1">
                </div>
            </div>
            <div className="row mt-5">
                <div className="col-sm-5" style={{ width: "46%" }}>
                    <div className="tableContainer">
                        <div className="row">
                            <div className="col-sm-4 d-flex align-items-center" style={{ textAlign:"left" }}>
                                <h5><strong>Noto Users</strong></h5>
                            </div>
                            <div className="col-sm-8">
                                <InputString id="txtSearch" onChangeHandler={searchChangeHandler} placeholder="Search" style={{ marginBottom: "6px" }} disabled={isDisabled} />
                            </div>
                        </div>
                        { search(rowUserData).length > 0 ? <DataTable
                                columns={columns}
                                data={search(rowUserData)}
                                className="table table-striped table-bordered table-hover"
                                customStyles={customStyles}
                                selectableRowsNoSelectAll="false"
                                highlightOnHover
                                striped
                                pagination
                                /> : <NoData headers={columns}></NoData> }
                        </div>
                </div>
                <div className="col-sm-2 d-flex align-items-center justify-content-center" style={{ width: "8%" }}>
                    <table style={{ border: "none", marginTop: "75px", display: action == 'view' ? "none" : ""  }}>
                        <tr>
                            <td>
                                <img src={ArrowRight} style={{ width: "55px", height: "55px", cursor: "pointer" }} alt=">>" onClick={handleRightShift} />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <img src={ArrowLeft} style={{ width: "55px", height: "55px", cursor: "pointer" }} alt="<<" onClick={handleLeftShift} />
                            </td>
                        </tr>
                    </table>
                </div>
                <div className="col-sm-5" style={{ width: "46%" }}>
                    <div className="tableContainer">
                        <div className="row">
                            <div className="col-sm-6 d-flex align-items-center" style={{ textAlign: "left" }}>
                                <h5><strong>Distribution List Recipients</strong></h5>
                                </div>
                            <div className="col-sm-6">
                                <div style={{ float: "right", marginBottom: "6px", display: action == 'view' ? "none" : ""  }}>
                                    <ExternalReceiptModal header="Add External Recipient" />
                                    </div>
                            </div>
                        </div>
                        { rowReceiptData.length > 0 ? <DataTable
                                columns={receiColumns}
                                data={rowReceiptData}
                                className="table table-striped table-bordered table-hover"
                                customStyles={customStyles}
                                selectableRowsNoSelectAll="false"
                                striped
                                pagination
                            /> : <NoData headers={receiColumns} /> }
                        </div>
                </div>
            </div>
            <div className="row mt-5">
                <div className="col-sm-12" style={{ textAlign: "center" }}>
                    <Button variant="secondary" className="btn btn-success" onClick={SubmitData} style={{ display: action == 'view' ? "none" : "" }} >
                       Save Distribution List
                    </Button>
                    <Button variant="primary" className="btn btn-danger" style={{ marginLeft: "10px" }} onClick={HandleClose}>
                        Close
                    </Button>
                </div>
            </div>
        </Card>
        
    );
};

export default Distribution;
