import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import DataTable from "react-data-table-component";
import Button from '../UI/Button/Button';
import Card from '../UI/Card/Card';
import InputString from '../UI/Control/InputString';
import classes from '../TrialstatNoto.module.css';
import customStyles from '../customStyles';
import NoData from '../NoData';
import OutcomeMappingDesigner from './OutcomeMappingDesigner';

const OutcomeMappingList = (props) => {
    const location = useLocation();
    const { studyId, studyProtocolId } = location.state;
    const EDCUserID = useSelector(state => state.auth.gValue01);
    const [q, setQ] = useState('');
    const [inputVal, setInputVal] = useState('');
    const [rowData, setRows] = useState([]);
    const [userPermission, setUserPermisssion] = useState('RO'); //RO, RW, NA
    const [arrUserRoles, setUserRoles] = useState([]);
    const navigate = useNavigate();
    const [isReadOnly, setIsReadOnly] = useState(false);
    const columns = [
        {
            name: "OutcomeMappingID",
            selector: row => row.OutcomeMappingID,
            cellExport: row => row.OutcomeMappingID,
            omit: true
        },
        {
            name: "Outcome Name",
            selector: row => row.Name,
            cellExport: row => row.Name,
            sortable: true,
            center: true
        },
        {
            name: "Form",
            selector: row => row.Form,
            cellExport: row => row.Form,
            sortable: true,
            center: true
        },
        {
            name: "Viewer(s)",
            selector: row => row.Viewer,
            cellExport: row => row.Viewer,
            sortable: true,
            center: true
        },
        {
            name: "Visit(s)",
            selector: row => row.Visit,
            cellExport: row => row.Visit,
            sortable: true,
            center: true
        },
        {
            name: "Question(s)",
            selector: row => row.Question,
            cellExport: row => row.Question,
            sortable: true,
            center: true,
            width: '35%',
            cell: (row) => (
                <div className={classes.textWrap}>{row.Question}</div>
            )
        },
        {
            name: "Action",
            button: true,
            className: 'headercaption',
            width: userPermission == "RW" ? '15%' : '0%',
            style: {
                'border-right-style': 'solid',
                'border-width': '1px',
                'border-color': 'rgba(0, 0, 0, 0.12)'
            }, 
            cell: (row) => (
                <div style={{ "padding": "5px" }}>
                    {userPermission == "RW" && !isReadOnly && <button onClick={() => DeleteOutcomeMapping(row.OutcomeMappingID, row.Name, event)} style={{ "margin": "5px", float: "right" }} className="btn btn-danger"> Delete </button>}
                    {userPermission == "RW" && !isReadOnly &&
                        <OutcomeMappingDesigner studyId={studyId} studyProtocolId={studyProtocolId} ocMappingId={row.OutcomeMappingID} refreshdata={fetchOutcomeMappingData} style={{ "margin": "5px", float: "right" }} action="edit" text="Edit" header="Edit Outcome" />
                    }
                    {isReadOnly &&
                        <OutcomeMappingDesigner studyId={studyId} studyProtocolId={studyProtocolId} ocMappingId={row.OutcomeMappingID} refreshdata={fetchOutcomeMappingData} style={{ "margin": "5px", float: "right" }} action="view" text="View" header="View Outcome" />
                    }
                </div>
            )
        }
    ];

    const filteredColumns = ['Name', 'Form', 'Viewer', 'Visit', 'Question'];
    function search(rows) {
        let selString = q.toString();
        let searchText = selString.toLowerCase().split(",");
        return rows.filter((row) => {
            return filteredColumns.some((column) => {
                if (searchText.length > 1)
                    return searchText.indexOf(row[column].toString().toLowerCase()) > -1;
                else
                    return row[column].toString().toLowerCase().indexOf(q.toLowerCase()) > -1;
            });
        });
    }

    const searchChangeHandler = (e) => {
        setQ(e.target.value);
        setInputVal(e.target.value);
    }

    const DeleteOutcomeMapping = (ocmid, ocName, event) => {
        var con = confirm('Are you sure, you want to delete "' + ocName + '" outcome mapping?');
        if (con == true) {
            let OutcomeMappingDetailsModel =
            {
                OutMappID: ocmid
            };
            fetch('api/OutcomeNotification/DeleteOutComeMappings', {
                method: 'POST',
                headers: { 'Content-type': 'application/json' },
                body: JSON.stringify(OutcomeMappingDetailsModel)
            }).then(r => r.json()).then(res => {
                if (res > 0) {
                    fetchOutcomeMappingData();
                }
                else if (res <= 0) {
                    alert('Mappings not deleted, Something went wrong.');
                    return false;
                }
                else {
                    alert('Mappings not deleted, Something went wrong.');
                    return false;
                }
            });
        }
    };

    const GetUserPermissionRoles = () => {
        fetch('api/Users/GetUserPermissionRoles')
            .then(response => response.json())
            .then(data => {
                setUserRoles(data);
            });
    }

    const fetchOutcomeMappingData = () => {
        fetch('api/OutcomeNotification/GetOutcomeMappingList?StudyId=' + studyId)
            .then(response => response.json())
            .then(data => {
                setRows(data);
            });
    }

    useEffect(() => {
        GetUserPermissionRoles();
        fetchOutcomeMappingData();
        getUserRole();
    }, []);

    const getUserRole = () => {
        fetch('api/Users/GetRoleByID?userID=' + EDCUserID)
            .then(response => response.text())
            .then(role => {
                setCompPermission(role);

            });
    };

    const setCompPermission = (usrRole) => {
        if (usrRole == "Read-Only")
            setUserPermisssion("RO");
        else if (usrRole == "Database Designer" || usrRole == "Super User")
            setUserPermisssion("RW");
        else if (usrRole == "Tester")
            setUserPermisssion("RW");
        else if (usrRole == "Database Administrator")
            setUserPermisssion("RW");
        else if (usrRole == "Management, Image Services" || usrRole == "Image Services Coordinator") {
            setUserPermisssion("RO");
            setIsReadOnly(true);
        }
        else if (usrRole == "Clinical Data Manager" || usrRole == "Manager, Clinical Data Management") {
            setUserPermisssion("RW");
            setIsReadOnly(true);
        }
        else
            setUserPermisssion("NA");
    }
    
    const handleClose = (event) => {
        navigate('/Designer');
    };
    return <Card>
        <Row style={{ marginBottom: "10px" }}>
            <Col md={6}>
                <div className="input-group">
                    <InputString id="txtSearch" value={inputVal} onChangeHandler={searchChangeHandler} placeholder="Search" />
                </div>
            </Col>
            <Col md={3} />
            <Col md={3} style={{ "display": "flex", float: "right", justifyContent: "space-between" }}>
                <Button style={{ float: "right" }} className="btn btn-md btn-success" variant="secondary" onClick={handleClose}>Return to Dashboard</Button>
                {!isReadOnly &&
                    <OutcomeMappingDesigner studyId={studyId} ocMappingId="0" studyProtocolId={studyProtocolId} refreshdata={fetchOutcomeMappingData} style={{ float: "right" }} action="edit" text="Add Outcome" header="Add Outcome" />}
            </Col>
        </Row>
        <div className="tableContainer">
            {search(rowData).length > 0 ? <DataTable
                columns={columns}
                data={search(rowData)}
                className="table table-striped table-bordered table-hover"
                customStyles={customStyles}
                striped
                pagination
                paginationPerPage={50}
                paginationRowsPerPageOptions={[20, 50, 100]}
            /> : <NoData headers={columns} />}
        </div>
    </Card>
}

export default OutcomeMappingList;