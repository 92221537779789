import React, { useState } from 'react';
import { Button, Modal, Row, Col } from 'react-bootstrap';
import InputString from '../UI/Control/InputString';
import classes from './../TrialstatNoto.module.css';
import SelectList from '../UI/Control/SelectList';

const ExternalReceiptModal = (props) => {
    const [show, setShow] = useState(false);
    const handleValidate = () => { }
    const handleSave = () => { };
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const arrRole = [
        {
            Name: "",
            Value: ""
        },
        {
            Name: "Data Management",
            Value: "1"
        },
        {
            Name: "Image Services Team",
            Value: "2"
        }
    ]

    return (
        <>
            <Button variant="primary" className="btn btn-sm btn-success" onClick={handleShow}> Add External Recipient </Button>

            <Modal centered
                show={show}
                onHide={handleClose}
                dialogClassName={classes.TSModalDailog_Five} backdrop="static">

                <Modal.Header closeButton>
                    <Modal.Title style={{ textAlign: "center" }}>{props.header}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row mt-2">
                        <div className="col-sm-1" />
                        <div className="col-sm-4">
                            <label>Name :</label>
                        </div>
                        <div className="col-sm-6">
                            <InputString id="txtName" />
                        </div>
                        <div className="col-sm-1" />
                    </div>
                    <div className="row mt-2">
                        <div className="col-sm-1" />
                        <div className="col-sm-4">
                            <label>Email Address :</label>
                        </div>
                        <div className="col-sm-6">
                            <InputString id="txtEmail" />
                        </div>
                        <div className="col-sm-1" />
                    </div>
                    <div className="row mt-2">
                        <div className="col-sm-1"/>
                        <div className="col-sm-4">
                            <label>Company : </label>
                        </div>
                        <div className="col-sm-6">
                            <InputString id="txtCompany" />
                        </div>
                        <div className="col-sm-1" />
                    </div>
                    <div className="row mt-2">
                        <div className="col-sm-1" />
                        <div className="col-sm-4">
                            <label>Role : </label>
                        </div>
                        <div className="col-sm-6">
                            <SelectList id="selectRole" dataSource={arrRole} />
                        </div>
                        <div className="col-sm-1" />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-md btn-success" variant="primary" onClick={handleClose}>
                        Save
                        </Button>
                    <Button className="btn btn-md btn-danger" variant="secondary" onClick={handleClose}>
                        Cancel
                        </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default ExternalReceiptModal;