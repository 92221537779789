import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Modal, Row, Col } from 'react-bootstrap';
import classes from './../TrialstatNoto.module.css';


const AdjCodeHistoryModal = (props) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const handleShow = () => {
        setShow(true);
    };

    return (
        <>
            {props.btn === "true" && <Button variant="primary" className="btn btn-info" onClick={handleShow}> {props.text}  </Button>}
            <Modal centered
                show={show}
                onHide={handleClose}
                dialogClassName={classes.TSModalDailog_One}
                backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title style={{ "text-align": "center" }}>{props.AdjTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={12}>
                            <textarea id="txtAdjCode" rows="16" cols="75" defaultValue={props.AdjCode} readOnly style={{ "width": "100%", "resize": "none", "border": "none", "outline": "none" }} />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-md btn-danger" variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};
export default AdjCodeHistoryModal;