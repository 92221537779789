import React from 'react';
import classes from './Card.module.css';
import { Disable } from 'react-disable';
const Card = (props) => {
    return (
        //<div className={classes.mxauto}>
        <Disable disabled={props.disabled}>
            <div className={classes.cardBorder + " " + classes.mt2 + " " + classes.mxauto + " " + classes.p4 + " " + classes.bglight}>
                <div className={classes.cardbody + " " + classes.bglight}>
                    <div className="container-fluid">
                        {props.children}
                    </div>
                </div>
            </div>
        </Disable>
        //</div>
    );
};
export default Card;
