import React from 'react';
export const menuData_CentralReader_Histo = [
    {
        name: "Home",
        level: "1",
        url: "/DashboardHisto",
        children: [
            {
                name: "Central Reader",
                level: "2",
                url: "/",
                children: [                   
                    {
                        name: "Histo",
                        level: "3",
                        url: "/DashboardHisto"
                    }
                ]

            }
            //{
            //    name: "Report",
            //    level: "2",
            //    url: ""
            //},            
        ]
    },
    {
        name: "Help",
        level: "1",
        url: "/",
        children: [
            {
                name: " ",
                level: "2",
                url: ""
            }]
    },
    {
        name: "Contact Support",
        level: "1",
        url: "/",
        children: [
            {
                name: " ",
                level: "2",
                url: ""
            }]
    },
    {
        name: "About",
        level: "1",
        url: "/AboutUs",
        children: [
            {
                name: " ",
                level: "2",
                url: ""
            }]
    }
]