import React from 'react';
const InputNumber = (props) => {   
    return (
        <input id={props.id}
            value={props.value}
            type="text"
            name={props.name}
            maxLength={props.maxlength}
            className="form-control"
            autoComplete="off"
            onChange={props.onchange}
            style={props.style}
            disabled={props.disabled}
            onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                }
            }}/>
    );
};

export default InputNumber;
