import React from 'react';

//import classes from './Card.module.css';

const Container = (props) => {
    return (
        <div className="container">
            {props.children}
        </div>
    );
};

export default Container;
