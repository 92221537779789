import React from 'react';
import { useSelector } from 'react-redux'
import { Button, Row, Col } from 'react-bootstrap';
import TextArea from '../UI/Control/InputTextarea';
import AddManualTaskModal from './AddManualTaskModal';

const QCCompleteAgno = (props) => {   
    const taskId = props.taskId;
    const EDCUserID = useSelector(state => state.auth.gValue01);
    
    const SaveQCComplete = (event) => {        
        let objNote = document.getElementById('QCCompletNote');    
        let qcModel = {
            TaskID: taskId,
            QCCompletNote: objNote.value,
            VideoQC: null, 
            TestVidApproved: null,
            CorrectProtocol: null,
            CorrectSubject: null,
            IdentifiersVisible: null,
            MaskingApplied: null,
            Landmarks: null,
            MoveNextStage: null,
            CIMSDataUpdated: null,
            CreationUser: EDCUserID
        };

        fetch('api/ImageService/SaveQCComplete', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(qcModel)
        }).then(r => r.json()).then(res => {
            if (res == -1) {
                alert('QC not completed, Something went wrong.');
            }
            if (res > 0) {
                createSubject();
                props.relaodAction();
            }
        })
    };
    const createSubject = async () => {
        const response = await fetch('api/ServiceCall/CreateSubject?TaskId=' + taskId + '&CreationUser=' + EDCUserID,
            {
                method: "POST",
                mode: 'cors'
            }
        )
        let retData = await response.text();        
        if (retData.trim() != "\"SubjectCreated\"") {
            alert("Error while creating subject: " + retData);
        }        
    }
    return (
        <div>
            <Row className="mt-2 mb-2">
                <Col md={12}>
                <Row>
                    <Col md={1}> Notes: </Col>
                    <Col md={10}>
                            {props.isTaskDetailsMissing && <Row>
                                <Col md={4}>
                                    <div class="alert alert-warning" style={{ paddingTop: "6px", paddingBottom:"6px" }}>
                                        <strong>Warning!</strong> Site, Subject or Visit is not available.
                                    </div>
                                </Col>
                                <Col md={8}>
                                    <AddManualTaskModal taskId={taskId} userPermission={props.userPermission} reloadActionQCEditTask={props.reloadActionQCEditTask} navigateFrom="qccomplete" btn="true" text="Save" />
                                </Col>
                            </Row>
                            }
                        <TextArea rows='4' cols='1200' id="QCCompletNote" />
                        </Col>                       
                    </Row>
                    <Row>
                        <Col md={11}></Col>
                        <Col md={1} style={{ "text-align": "center", "display": "inline-block" }}>
                            <Button className="btn-success" onClick={SaveQCComplete} disabled={props.isTaskDetailsMissing} > Submit </Button>
                        </Col>
                    </Row>
                </Col>        
        </Row>
        <Row className="mt-2 mb-2">
                <Col md={12} style={{ "text-align": "center" }}>                  
            </Col>
            </Row>
        </div>
    );
}
export default QCCompleteAgno;
