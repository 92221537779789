import { useEffect } from 'react';
import React from 'react';
import { Link } from 'react-router-dom';
import menuClass from './Menu.css';
import submitall from '../Images/submitall.jpg';

var previousL1 = '', previousL2 = '', previousL3 = '', previousL4 = '';
var menuName = 'Home';

function removeSelectBG() {
    var elems = document.querySelectorAll(".SelectBGLevel1");
    [].forEach.call(elems, function (el) {
        el.classList.remove("SelectBGLevel1");
    });

    var elems2 = document.querySelectorAll(".SelectBGLevel2");
    [].forEach.call(elems2, function (el) {
        el.classList.remove("SelectBGLevel2");
    });
}
const MenuClick = (event) => {
    if (event.currentTarget.getAttribute('menutext') != null) {
        menuName = event.currentTarget.getAttribute('menutext');
    }
    
    var elements = document.getElementsByClassName("Level3");
    for (var i = 0, len = elements.length; i < len; i++) {
        elements[i].children[0].classList.add("backgroundLevel3");
    }
    if (previousL3 != "") {
        document.getElementById(previousL3).style.display = "none";
    }
    if (event.target.parentElement.className == "Level1") {
        removeSelectBG();
        var elements = document.getElementsByClassName("Level1");
        for (var i = 0, len = elements.length; i < len; i++) {
            elements[i].children[0].classList.add("backgroundLevel1");
        }
        event.target.classList.add("SelectBGLevel1");
        if (previousL1 != "") {
            document.getElementById(previousL1).style.display = "none";
        }
        if (document.getElementById(event.target.parentElement.id + "Sub") != null) {
            document.getElementById(event.target.parentElement.id + "Sub").style.display = "inline";
            previousL1 = event.target.parentElement.id + "Sub";
        }
        
        if (previousL2 != "") {
            document.getElementById(previousL2).style.display = "none";
        }

        elements = document.getElementsByClassName("Level2");
        for (var i = 0, len = elements.length; i < len; i++) {
            elements[i].children[0].classList.add("backgroundLevel2");
        }
         
    }
    if (event.target.parentElement.className == "Level2") {
        var elems2 = document.querySelectorAll(".SelectBGLevel2");
        [].forEach.call(elems2, function (el) {
            el.classList.remove("SelectBGLevel2");
        });
        var elements = document.getElementsByClassName("Level2");
        for (var i = 0, len = elements.length; i < len; i++) {
            elements[i].children[0].classList.add("backgroundLevel2");
        }
        event.target.classList.add("SelectBGLevel2");
        if (previousL2 != "") {
            document.getElementById(previousL2).style.display = "none";
        }
        if (document.getElementById(event.target.parentElement.id + "Sub") != null) {
            document.getElementById(event.target.parentElement.id + "Sub").style.display = "block";
            var left = event.target.getBoundingClientRect().x - 200;
            document.getElementById(event.target.parentElement.id + "Sub").style.left = left + "px";
            previousL2 = event.target.parentElement.id + "Sub";
        }
    }
    if (event.target.parentElement.className == "Level3") {
        var elems2 = document.querySelectorAll(".SelectBGLevel2");
        [].forEach.call(elems2, function (el) {
            el.classList.remove("SelectBGLevel2");
        });
        if (previousL3 != "") {
            document.getElementById(previousL3).style.display = "none";
        }

        elements = document.getElementsByClassName("Level2");
        for (var i = 0, len = elements.length; i < len; i++) {
            elements[i].children[0].classList.add("backgroundLevel2");
        }

        if (document.getElementById(event.target.parentElement.id + "Sub") != null) {
            document.getElementById(event.target.parentElement.id + "Sub").style.display = "block";
            var left = event.target.getBoundingClientRect().x - 200;
            var top = event.target.getBoundingClientRect().y - 80;
            document.getElementById(event.target.parentElement.id + "Sub").style.marginTop = top + "px";
            event.target.classList.add("backgroundLevel1");
            previousL3 = event.target.parentElement.id + "Sub";
        }
    }
    if (event.currentTarget.getAttribute('href') == '/') {
        event.preventDefault();
        return false
    }
    document.getElementById('locationParag').innerHTML = menuName; 
};

const ClickOutside = (event) => {
    if ((previousL2 != "" || previousL3 != "") && event.target.pathname != "/") {

        var elems2 = document.querySelectorAll(".SelectBGLevel2");
        [].forEach.call(elems2, function (el) {
            el.classList.remove("SelectBGLevel2");
        });

        if (document.getElementById(previousL2) != null)

            document.getElementById(previousL2).style.display = "none";
    }
};

var level3Left = 0;
const MultilevelMenu = ({ data }) => {
    if (menuName == '')
        menuName = 'Home';
    useEffect(() => {
        document.addEventListener('mouseup', ClickOutside);

        if (localStorage.getItem('isFisrtTimeLoading') == null) {

            localStorage.setItem('isFisrtTimeLoading', 'yes');
            var elements = document.getElementsByClassName("Level1");
            for (var i = 0, len = 1; i < len; i++) {
                elements[i].children[0].classList.add("SelectBGLevel1");

                if (document.getElementById(elements[i].children[0].parentElement.id + "Sub") != null) {
                    document.getElementById(elements[i].children[0].parentElement.id + "Sub").style.display = "inline";
                    previousL1 = elements[i].children[0].parentElement.id + "Sub";
                }
            }
        }
        document.getElementById('locationParag').innerText = menuName; 
        menuName = '';
    });

    level3Left = 0;
    const renderMenuItems = data => {
       
        return data.map((item, index) => {
            if (item.level == 1) {
                return (item.children && item.children.length) ? (<li className={"Level" + item.level} id={"L" + item.level + index} key={index}><Link onClick={MenuClick} to={item.url} menutext={item.name}>{item.name}</Link>
                    <ul id={"L" + item.level + index + "Sub"} ss="aa" className="backgroundLevel2" style={{ position: "absolute", left: "0px", width: "100%", display: "none", height: "30px", marginTop: "40px", paddingLeft: "0px" }}>
                        {renderMenuItems(item.children)}
                    </ul></li>
                ) : <li className={"Level" + item.level} id={"L" + item.level + index} key={index}><Link onClick={MenuClick} to={item.url} menutext={item.name}>{item.name}</Link></li>
            }
            else if (item.level == 2) {
                return (item.children && item.children.length) ? (<li className={"Level" + item.level} id={"L" + item.level + index} key={index}><Link onClick={MenuClick} to={item.url}>{item.name}</Link>
                    <ul id={"L" + item.level + index + "Sub"} className="backgroundLevel2" style={{ position: "absolute", left: level3Left + "px", width: "155px", minWidth: "auto", display: "none", height: "auto", marginTop: "40px", padding: "0px" }}>
                        {renderMenuItems(item.children)}
                    </ul></li>
                ) : <li className={"Level" + item.level} id={"L" + item.level + index} key={index}><Link onClick={MenuClick} to={item.url} menutext={item.name}>{item.name}</Link></li>
            }
            else if (item.level == 3) {
                return (item.children && item.children.length) ? (<li className={"Level" + item.level} id={"L" + item.level + index} key={index}><Link style={{ width: "100%", backgroundImage: "url({" + submitall + "})" }} onClick={MenuClick} to={item.url}>{item.name}</Link>
                    <ul id={"L" + item.level + index + "Sub"} className="backgroundLevel2" style={{ position: "absolute", left: "153px", width: "155px", minWidth: "auto", display: "none", height: "auto", marginTop: "40px", padding : "0px" }}>
                        {renderMenuItems(item.children)}
                    </ul></li>
                ) : <li className={"Level" + item.level} id={"L" + item.level + index} key={index}><Link id={item.name == "Dashboard Viewer" ? "mnuDashboardViewer" : item.name == "Custom Report" ? "mnuCustomReport" : "mnu" + index} style={{ width: "100%" }} onClick={MenuClick} to={item.url} menutext={item.name}>{item.name}</Link></li>
            }
            else if (item.level == 4) {
                return (item.children && item.children.length) ? (<li className={"Level" + item.level} id={"L" + item.level + index} key={index}><Link style={{ width: "100%" }} onClick={MenuClick} to={item.url}>{item.name}</Link>
                    <ul id={"L" + item.level + index + "Sub"} className="backgroundLevel2" style={{ position: "absolute", left: "155px", width: "155px", minWidth: "auto", display: "none", height: "30px", marginTop: "40px", padding : "0px" }}>
                        {renderMenuItems(item.children)}
                    </ul></li> 
                ) : <li className={"Level" + item.level} id={"L" + item.level + index} key={index}><Link style={{ width: "100%" }} onClick={MenuClick} to={item.url} menutext={item.name}>{item.name}</Link></li>
            }
        }
        )
    }
    return data && (
        <ul id="nav" style={{ minWidth: "100%" }}>
            {renderMenuItems(data)}
        </ul>
    );
}

export default MultilevelMenu;