import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import classes from './../TrialstatNoto.module.css';
import ParadigmDesigner from './ParadigmDesigner';
import EditIcon from '../../Images/Icons/edit.svg';
import ViewIcon from '../../Images/Icons/view.svg';

const ParadigmDesignerModel = (props) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
   
    return (
        <>
            {props.action == 'view' && <img className="" style={{ "border": "1px solid #dcdcdc", "cursor": "pointer", marginLeft: "10px" }} height="34px" width="34px" src={ViewIcon} alt="View" onClick={handleShow} />}
            {props.action == 'edit' && <img className="" style={{ "border": "1px solid #dcdcdc", "cursor": "pointer", marginLeft: "10px" }} height="34px" width="34px" src={EditIcon} alt="Edit" onClick={handleShow} />}

            <Modal show={show} onHide={handleClose} dialogClassName={classes.TSModalDailog_Two} animation={false} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>{props.header}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ParadigmDesigner action={props.action} paradigmID={props.paradigmID} onHandleClose={handleClose} fetchParadigmsData={props.fetchParadigmsData} />
                </Modal.Body>                
            </Modal>
        </>
    );
}
export default ParadigmDesignerModel;