import React, { useState, useEffect } from 'react';
import classes from './../TrialstatNoto.module.css';
import { Row, Col } from 'react-bootstrap';
import InputContainer from '../UI/Container/InputContainer'
import ViewQueryDetails from './ViewQueryDetails'
import QueryAddEditModal from './QueryAddEditModal';

const AssignedCRTaskList = (props) => {
    const [arrAssignedCRTask, setArrAssignedCRTask] = useState([]);

    const GetAssignedTaskBySubID = (subjectId) => {
        fetch('api/ImageService/GetAssignedTaskBySubID?subjectId=' + subjectId + "&TaskId=" + props.taskId)
            .then(response => response.json())
            .then(data => {
                setArrAssignedCRTask(data);
            });
    };

    useEffect(() => {
        GetAssignedTaskBySubID(props.subjectID);
    }, []);

    return (
        <div>
            {
                arrAssignedCRTask.length > 0 && (
                    <Row>
                        <Col md={12} >
                            <InputContainer Expended='false' header="Assigned CR(s) Details" style={{ "text-align": "center", "margin-top": "15px" }}>
                                <Row style={{ "margin-left": "0px", "margin-right": "0px" }}>
                                    <Col md={1} className={classes.cellRLBBorder}><b>TaskID</b></Col>
                                    <Col md={2} className={classes.cellRightBorder}><b>Read Stage</b></Col>
                                    <Col md={2} className={classes.cellRightBorder}><b>Visit</b></Col>
                                    <Col md={7} className={classes.cellRightBorder}><b>Assigned CR(s)</b></Col>
                                </Row>
                                {arrAssignedCRTask.map((item, index) =>
                                    <Row style={{ "margin-left": "0px", "margin-right": "0px" }}>
                                        <Col md={1} className={classes.cellRLBBorder}>{item.TaskID}</Col>
                                        <Col md={2} className={classes.cellRightBorder}>{item.ReadStage}</Col>
                                        <Col md={2} className={classes.cellRightBorder}>{item.Visit}</Col>
                                        <Col md={7} className={classes.cellRightBorder}>{item.AssignedCRs}</Col>
                                    </Row>
                                )}
                            </InputContainer>
                        </Col>
                    </Row>)
            }
        </div>
    );
};

export default AssignedCRTaskList;
