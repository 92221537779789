import './index.css';
import React from 'react';
import ReactDOM, { createRoot} from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import notostore, { persistor } from './app/notostore';
import { PersistGate } from 'redux-persist/integration/react';

import style from './components/table.css';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

function IsBrowserAllowed() {
    let userAgent = navigator.userAgent;
    let isBrowserRelevant = false;

    if (userAgent.match(/chrome|chromium|crios/i) && !userAgent.match(/Opera|OPR/i)) {
        isBrowserRelevant = true;
    }
    else if (userAgent.match(/firefox|fxios/i)) {
        isBrowserRelevant = true;
    }
    else if (userAgent.match(/edg/i)) {
        isBrowserRelevant = true;
    }

    return isBrowserRelevant;
}

const root = createRoot(rootElement);
root.render(
    <Provider store={notostore}>
    <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter basename={baseUrl}>
            {IsBrowserAllowed() && <App />}
            {!IsBrowserAllowed() && <div className="container" style={{ marginTop: "20px" }}>
                <div className="alert alert-warning"> <strong>Warning!</strong> Noto applications allow only Firefox, Chrome, and Edge browsers. </div></div>
            }
        </BrowserRouter>
    </PersistGate>
    </Provider>
);

registerServiceWorker();
