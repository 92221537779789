import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";
import Card from '../UI/Card/Card';
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import extScreenImg from '../../Images/Icons/ExpandScreen.svg'
import colScreenImg from '../../Images/Icons/CollapseScreen.svg'
import closeImg from '../../Images/Icons/CloseWindow.svg'
import classes from '../TrialstatNoto.module.css'
import Button from '../UI/Button/Button';

let noofCol_iframe = 6
let noofCol_image = 6

const iFrameResizeHandler = (e) => {
    e.preventDefault();
    if (noofCol_iframe < 10) {
        noofCol_iframe = noofCol_iframe + 2;
        noofCol_image = noofCol_image - 2;
        document.getElementById("imgBtn").src = extScreenImg
        document.getElementById("imgBtn").className = classes.enable
        if (noofCol_iframe >= 10) {
            document.getElementById("ifrmBtn").src = colScreenImg
            document.getElementById("ifrmBtn").className = classes.disable
        }
    }   
    document.getElementById("divIFrame").className = "col-md-" + noofCol_iframe;
    document.getElementById("divImage").className = "col-md-" + noofCol_image;
}
const imgResizeHandler = (e) => {
    e.preventDefault();
    if (noofCol_image < 10) {
        noofCol_iframe = noofCol_iframe - 2;
        noofCol_image = noofCol_image + 2;
        document.getElementById("ifrmBtn").src = extScreenImg
        document.getElementById("ifrmBtn").className = classes.enable
        if (noofCol_image >= 10) {
            document.getElementById("imgBtn").src = colScreenImg
            document.getElementById("imgBtn").className = classes.disable
        }
    }    
    document.getElementById("divIFrame").className = "col-md-" + noofCol_iframe;
    document.getElementById("divImage").className = "col-md-" + noofCol_image;
}

const ReaderEndo = (props) => {
    const location = useLocation();
    const { TaskID } = location.state;
    const { StudyParadigmCRFID } = location.state;
    const { ReadAssignmentID } = location.state;   
    const [eCRFUrl, seteCRFUrl] = useState();
    const [imgUrl, setImgUrl] = useState();
    const [httpError, setHttpError] = useState(null)
    const navigate = useNavigate();

    const winCloseHandler = () => {        
        fetch('api/Users/GetRoleByID')
            .then(response => response.text())
            .then(usrRole => {
                { usrRole === 'Endoscopy Central Reader' ? navigate("/DashboardEndo") : navigate("/DashboardHisto") }
                {/* usrRole === 'Endoscopy Central Reader' && navigate("/DashboardEndo") }
                { usrRole === 'Histopathology Central Reader' && navigate("/DashboardHisto") */}
            });
    }
    useEffect(() => {
        //find the your current id is exist in the array
        noofCol_iframe = 6
        noofCol_image = 6
        const fetcheCRFUrl = async () => {
            const response = await fetch('/api/ReadsAssigned/eCRFAPIUrl?TaskID=' + TaskID + '&StudyParadigmCRFID=' + StudyParadigmCRFID + '&RdAssID=' + ReadAssignmentID)
            if (!response.ok) {
                throw new Error("Something went wrong, message :" + response.message + " \nHttp Error:" + response.httpError)
            }
            const eCRFAPIUrl = await response.text();
            seteCRFUrl(eCRFAPIUrl);
        }

        const fetchImgFUrl = async () => {
            const response = await fetch('/api/AppSettings/Img_APIUrl_Endo?TaskID=' + TaskID)
            if (!response.ok) {
                throw new Error("Something went wrong, message :" + response.message + " \nHttp Error:" + response.httpError)
            }
            const imgAPIUrl = await response.text();
            setImgUrl(imgAPIUrl);
        }

        fetcheCRFUrl().catch((error) => {
            setHttpError(error.message)
        })
        fetchImgFUrl().catch((error) => {
            setHttpError(error.message)
        })
    }, []);

    return ( 
        <Card>             
        <Row> 
             <Col id="divIFrame" md={noofCol_iframe}>
                    <img id="ifrmBtnClose" onClick={winCloseHandler} style={{ "border": "1px solid #dcdcdc", "cursor": "pointer", "float": "right" }} height="25px" width="25px" src={closeImg} />
                    <img id="ifrmBtn" className={classes.enable} onClick={iFrameResizeHandler} style={{ "border": "1px solid #dcdcdc", "float": "right" }} height="25px" width="25px" src={extScreenImg} />                    
                    <div style={{ "margin-top": "5px" }}>
                        <iframe style={{ "border": "1px solid #dcdcdc" }} id="iframeCRF" height="750px" width="100%" src={eCRFUrl} allowfullscreen="true"></iframe>
                    </div>
             </Col>
             <Col id="divImage" md={noofCol_image}>   
                    <img id="imgBtnClose" onClick={winCloseHandler} style={{ "border": "1px solid #dcdcdc", "cursor": "pointer", "float": "right" }} height="25px" width="25px" src={closeImg} />
                    <img id="imgBtn" className={classes.enable} onClick={imgResizeHandler} style={{ "border": "1px solid #dcdcdc", "float": "right" }} height="25px" width="25px" src={extScreenImg}/>                   
                <div style={{ "margin-top": "5px" }}>
                        <iframe style={{ "border": "1px solid #dcdcdc" }} height="750px" width="100%" src={imgUrl} allowfullscreen="true"></iframe>          
                </div>
            </Col>
        </Row> 
        </Card>
    );
};

export default ReaderEndo;
