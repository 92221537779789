import React from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import InputRadio from '../UI/Control/InputRadio';
import TextArea from '../UI/Control/InputTextarea';
import { useSelector } from 'react-redux'

const TaskReRead = (props) => {

    let taskId = props.taskId;
    const EDCUserID = useSelector(state => state.auth.gValue01);

    const SaveReRead = (event) => {

        let objNotes = document.getElementById('txtNotes');
        if (objNotes.value.trim() == '') {
            alert('Notes should not be empty.');
            objNotes.focus();
            return false;
        }

        let objReReadModel = {
            TaskID: taskId,
            Notes: objNotes.value,
            CreationUser: EDCUserID
        };

        fetch('api/ImageService/SaveReRead', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(objReReadModel)
        }).then(r => r.json()).then(res => {
            if (res == -1) {
                alert('Notes not saved, Something went wrong.');
            }
            if (res > 0) {
                props.relaodAction();
            }
        })

    };

    return (
        <div>
            <Row className="mt-2 mb-2">
                <Col md={1}>
                    Notes:
                 </Col>
                <Col md={10}>
                    <TextArea id="txtNotes" rows='5' cols='50' />
                </Col>
            </Row>
            <Row className="mt-2 mb-2">
                <Col md={2}>
                    Re-Read Read 1 for John Smith:
             </Col>
                <Col md={4}>
                    <InputRadio orientation='horizontal' value="1" name="VideoQCed" text="Yes" style={{ "margin-left": "10px", "margin-right": "5px" }}></InputRadio>
                    <InputRadio orientation='horizontal' value="0" name="VideoQCed" text="No" style={{ "margin-left": "10px", "margin-right": "5px" }}></InputRadio>
                </Col>
            </Row>

            <Row className="mt-2 mb-2">
                <Col md={2}>
                     Re-Read Read 2 for Jane Doe:
             </Col>
                <Col md={4}>
                    <InputRadio orientation='horizontal' value="1" name="VideoQCed" text="Yes" style={{ "margin-left": "10px", "margin-right": "5px" }}></InputRadio>
                    <InputRadio orientation='horizontal' value="0" name="VideoQCed" text="No" style={{ "margin-left": "10px", "margin-right": "5px" }}></InputRadio>
                </Col>
            </Row>
            <Row className="mt-2 mb-2">
                <Col md={12} style={{ "text-align": "center" }}>
                    <Button className="btn-success" onClick={SaveReRead} > Submit </Button>
                </Col>
            </Row>
        </div>
    );
}
export default TaskReRead;
