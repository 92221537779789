import React, { useState, useEffect} from 'react';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, convertFromHTML, ContentState } from 'draft-js';
import classes from '../../TrialstatNoto.module.css'
import draftToHtml from 'draftjs-to-html';

const HTMLEditor = (props) => {
    const html = props.currentEditorContent == null ? '' : props.currentEditorContent;

    const blocksFromHTML = convertFromHTML(html);
    const content = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
    );

    const [editorState, setEditorState] = useState(
        EditorState.createWithContent(content)
    );

    const handleEditorChange = (state) => {       
        setEditorState(state);
        sendContent();
    };

    const sendContent = () => {
        props.modifiedEditorContent(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    };

    useEffect(() => {        
        if (props.currentEditorContent != '') {           
            const contentBlocks = convertFromHTML(props.currentEditorContent == null ? '<p></p>\n' : props.currentEditorContent);
           
            const contentState = ContentState.createFromBlockArray(
                contentBlocks.contentBlocks,
                contentBlocks.entityMap
            );
            setEditorState(EditorState.createWithContent(contentState));
        }
        else {            
            const contentBlocks = convertFromHTML('<p></p>\n');

            const contentState = ContentState.createFromBlockArray(
                contentBlocks.contentBlocks,
                contentBlocks.entityMap
            );
            setEditorState(EditorState.createWithContent(contentState));
        }        
    }, [props.currentEditorContent]);

    return (
        <div className="App">
            <Editor
                editorState={editorState}
                onEditorStateChange={handleEditorChange}
                wrapperClassName={classes.wrapperclass}
                editorClassName={classes.editorclass}
                toolbarClassName={classes.toolbarclass}
                editorStyle={{ height: '450px' }}
            />
        </div>
    )
};
export default HTMLEditor;
