import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import InputPassword from '../UI/Control/InputPassword';
import MandatoryLabel from '../UI/Control/MandatoryLabel';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import NotoLogo from '../NotoLogo';


const ChangePassword = (props) => {
    const EDCUserID = useSelector(state => state.auth.gValue01);
    const [userName, setUername] = useState('');
    const navigate = useNavigate();
    let username = '';

    if (typeof props.userName == 'undefined') {
        username = userName;
    }
    else {
        username = props.userName;
    }

    const handleCancelButton = (event) => {
        if (typeof props.userName == 'undefined') {
            navigate('/');
        }
        else {
            props.setIsCancelUpdatePassword(true);
            props.setIsPassExpired(false);
        }
    };

    function getUserName() {
        fetch('api/Users/GetUserNameByID?userID=' + EDCUserID)
            .then(response => response.text())
            .then(username => {
                setUername(username);
            });
    }

    useEffect(() => {
        if (typeof props.userName != 'undefined') {
            document.getElementById('divNotoLogo').style.display = 'block';
            document.getElementById('divPassExpContent').style.display = 'block';
            username = props.userName;
        }
        else {
            getUserName();
        }
    }, []);

    const handleChangePassword = (event) => {

        const objOldPassword = document.getElementById('txtOldPassword');
        if (objOldPassword.value.trim() == '') {
            alert('Old password should not be empty.');
            objOldPassword.focus();
            return false;
        }

        const objNewPassword = document.getElementById('txtNewPassword');
        if (objNewPassword.value.trim() == '') {
            alert('New password should not be empty.');
            objNewPassword.focus();
            return false;
        }


        if (objNewPassword.value.length < 8) {
            alert('New password should be minimum 8 chracter.');
            objNewPassword.focus();
            return false;
        }

        const objConfirmPassword = document.getElementById('txtConfirmPassword');
        if (objConfirmPassword.value.trim() == '') {
            alert('Confirm password should not be empty.');
            objConfirmPassword.focus();
            return false;
        }

        if (objNewPassword.value != objConfirmPassword.value) {
            alert('New password and confirm password should be match.');
            objConfirmPassword.focus();
            return false;
        }

        let objUserPasswordDetails = {
            Username: username,
            OldPassword: objOldPassword.value,
            NewPassword: objNewPassword.value
        };

        fetch('api/Users/ChangePassword', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(objUserPasswordDetails)
        }).then(r => r.text()).then(res => {
            if (res == "NotFound") {
                alert('Invalid old password, Please try again!');
            }
            else if (res == "OK") {
                alert('Password updated successfully.');
                if (typeof props.userName == 'undefined') {
                    navigate('/');
                }
                else {
                    props.setIsCancelUpdatePassword(false);
                    props.setIsPassExpired(false);
                }
            }
            else if (res == "1") {
                alert('Password updated successfully.');
                if (typeof props.userName == 'undefined') {
                    navigate('/');
                }
                else {
                    props.setIsCancelUpdatePassword(false);
                    props.setIsPassExpired(false);
                    props.loginHandler(props.userName, objNewPassword.value, props.userType);
                }
            }
            else {
                alert('Password not updated, Something went wrong.');
            }
        })
    };

    return (

        <div className="row">
            <div className="col-sm-4"></div>
            <div className="col-sm-4">
                <div className="row" style={{ border: "solid", borderWidth: "thin", borderColor: "silver", padding: "10px", marginTop: "20px" }}>
                    <div id="divNotoLogo" style={{ display: "none" }}>
                        <NotoLogo></NotoLogo>
                    </div>
                    <div id="divPassExpContent" style={{ padding: "10px", display: "none" }}>
                        <label>Our records indicate that it has been at least 3 months since you last changed your password. Please change your password.</label>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <label className="mb-1"><b> Old Password </b></label>
                            <MandatoryLabel></MandatoryLabel> :
                                <InputPassword id="txtOldPassword" maxlength="50"></InputPassword>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-sm-12">
                            <label className="mb-1"><b> New Password </b></label>
                            <MandatoryLabel></MandatoryLabel> :
                                <InputPassword id="txtNewPassword" maxlength="50"></InputPassword>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-sm-12">
                            <label className="mb-1"><b> Confirm Password </b></label>
                            <InputPassword id="txtConfirmPassword" maxlength="50"></InputPassword>
                        </div>
                    </div>
                    <div className="row mt-2" style={{ marginTop: "10px" }}>
                        <div className="col-sm-12 d-flex justify-content-center">
                            <Button className="btn btn-md btn-success" onClick={handleChangePassword} style={{ marginRight: "5px" }}>
                                Save
                                        </Button>
                            <Button className="btn btn-md btn-danger" onClick={handleCancelButton}>
                                Cancel
                                        </Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-sm-4"></div>
        </div>
    );
};

export default ChangePassword;