import React, { useState } from 'react';
import { Button, Modal, Row, Col } from 'react-bootstrap';
import SelectList from '../UI/Control/SelectList';
import classes from './../TrialstatNoto.module.css';
import { useSelector } from 'react-redux';

const ProtocolVersionModal = (props) => {
    const EDCUserID = useSelector(state => state.auth.EDCUserID);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
        FillSiteProtocolVersion();
    }
    const [siteProtVer, setSiteProtVer] = useState([]);

    const FillSiteProtocolVersion = async () => {
        fetch('api/StudySites/FillSiteProtocolVersion?studyId='+props.studyId)
            .then(response => response.json())
            .then(data => {
                let arr = [{ Value: '0', Name: '--Select Protocol Version--' }];
                for (var iCount = 0; iCount < data.length; iCount++) {
                    arr.push({ Value: data[iCount].Value, Name: data[iCount].Name });
                }
                setSiteProtVer(arr);
            });
    }

    const UpdateProtocolVersion = (event) => {
        let objProVer = document.getElementById('selectProtocolVersion');

        if (objProVer.value == 0) {
            alert('Please select protocol version.');
            objProVer.focus();
            return false;
        }

        let objStudySitesModel = {
            StudySiteID: props.studySiteID,
            StudyProtocolID: objProVer.value,
            Username: EDCUserID
        };

        fetch('api/StudySites/UpdateProtocolVersion', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(objStudySitesModel)
        }).then(r => r.text()).then(res => {
            if (res == 0 || res == -1) {
                alert('Protocol version not updated, Something went wrong.');
            }
            else if (res > 0) {
                props.fetchData();
                setShow(false);
            }
        })
    };

    return (
        <>
            <Button variant="primary" className="btn btn-sm btn-warning" style={props.style} onClick={handleShow}> {props.text}  </Button>

            <Modal centered
                show={show}
                onHide={handleClose}
                dialogClassName={classes.TSModalDailog_Nine}
                backdrop="static">

                <Modal.Header closeButton>
                    <Modal.Title style={{ textAlign: "center", fontSize: "1.3rem" }}>{props.header}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-sm-1" style={{ width: "4%" }}>
                        </div>
                        <div className="col-sm-10" style={{ width: "95%" }}>
                            <div className="row">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <label className="mb-1"><b> Protocol Version : </b></label>
                                        <SelectList dataSource={siteProtVer} id="selectProtocolVersion" selectedData={props.studyProtocolID}></SelectList>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-1" style={{ width: "4%" }}>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-md btn-success" variant="primary" onClick={UpdateProtocolVersion}>
                        Save
                        </Button>
                    <Button className="btn btn-md btn-danger" variant="secondary" onClick={handleClose}>
                        Cancel
                        </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default ProtocolVersionModal;