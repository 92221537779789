import React, { useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import Button from '..//UI/Button/Button';
import InputString from '../UI/Control/InputString';
import InputTextarea from '../UI/Control/InputTextarea';
import SelectList from '../UI/Control/SelectList';
import Card from '../UI/Card/Card';
import classes from '../TrialstatNoto.module.css';
import ParadigmInitialStage from './ParadigmInitialStage';
import ParadigmStage from './ParadigmStage';
import DownArrow from '../../Images/Icons/DownArrow.svg'
import MandatoryLabel from '../UI/Control/MandatoryLabel';

const ParadigmDesigner = (props) => {
    const EDCUserID = useSelector(state => state.auth.gValue01);    
    const [arrNoOfStage, setArrNoOfStage] = useState([]);
    const [arrNoOfStageTemp, setArrNoOfStageTemp] = useState([]);
    const [noOfStage, setNoOfStage] = useState(0);
    const [ctrDisabled, setCTRDisabled] = useState('');
    const navigate = useNavigate();
    const cancelClickHandler = () => {
        navigate("/Paradigms");
    }

    const stages = [{
        Name: "1",
        Value: "1"
    },
    {
        Name: "2",
        Value: "2"
    },
    {
        Name: "3",
        Value: "3"
    },
    {
        Name: "4",
        Value: "4"
    },
    {
        Name: "5",
        Value: "5"
    },
    {
        Name: "6",
        Value: "6"
    },
    {
        Name: "7",
        Value: "7"
    },
    {
        Name: "8",
        Value: "8"
    },
    {
        Name: "9",
        Value: "9"

    },
    {
        Name: "10",
        Value: "10"
        }]

    useEffect(() => {
        if (props.paradigmID > 0) {
            fetchParadigmDetail(props.paradigmID);
        }
    }, []);

    const NoOfStageChangeHandler = (e) => {
        let NumStage = e.target.value;
        if (props.paradigmID == 0) {
            let arr = new Array();
            for (var iCount = 0; iCount < NumStage-1; iCount++) {
                arr.push({ ParadigmStageID: 0, ParadigmID: 0, StageNo: iCount, Adjudications: 0 });
            }
            setArrNoOfStage(arr);
        }
        else {
            let arr = new Array();
            let cnt = 1;
            if (NumStage == 1) {
                setArrNoOfStage(arr);
            }
            else if (NumStage == noOfStage) {
                for (var iCount = 0; iCount < arrNoOfStageTemp.length; iCount++) {
                    arr.push({ ParadigmStageID: arrNoOfStageTemp[iCount].ParadigmStageID, ParadigmID: arrNoOfStageTemp[iCount].ParadigmID, StageNo: cnt, Adjudications: arrNoOfStageTemp[iCount].Adjudications });
                    cnt++;
                }
                setArrNoOfStage(arr);
            }
            else if (NumStage > noOfStage) {
                for (var iCount = 0; iCount < arrNoOfStageTemp.length; iCount++) {
                    arr.push({ ParadigmStageID: arrNoOfStageTemp[iCount].ParadigmStageID, ParadigmID: arrNoOfStageTemp[iCount].ParadigmID, StageNo: arrNoOfStageTemp[iCount].StageNo, Adjudications: arrNoOfStageTemp[iCount].Adjudications });
                    cnt = arrNoOfStageTemp[iCount].StageNo;
                }
                for (var iCount = 0; iCount < NumStage - noOfStage; iCount++) {
                    cnt++;
                    arr.push({ ParadigmStageID: 0, ParadigmID: 0, StageNo: cnt, Adjudications: 0 });

                }
                setArrNoOfStage(arr);
            }
            else if (NumStage < noOfStage) {
                for (var iCount = 0; iCount < NumStage-1; iCount++) {
                    arr.push({ ParadigmStageID: arrNoOfStageTemp[iCount].ParadigmStageID, ParadigmID: arrNoOfStageTemp[iCount].ParadigmID, StageNo: arrNoOfStageTemp[iCount].StageNo, Adjudications: arrNoOfStageTemp[iCount].Adjudications });
                    cnt++;
                }
                setArrNoOfStage(arr);
            }
        }
    }

    function ValidateParadigms() {
        var retVal = true;
        const objName = document.getElementById('txtName');
        if (objName.value.trim() == '') {
            alert('Paradigm name should not be empty.');
            objName.focus();
            return false;
        }

        const objNoOfStage = document.getElementById('selectNoOfStage');
        if (objNoOfStage.value == '') {
            alert('Please select number of stage.');
            objNoOfStage.focus();
            return false;
        }

        const objNoOfLR = document.getElementById('txtNoOfLR');
        if (objNoOfLR.value.trim() == '') {
            alert('Local read should not be empty.');
            objNoOfLR.focus();
            return false;
        }

        const objNoOfCR = document.getElementById('txtNoOfCR');
        if (objNoOfCR.value.trim() == '') {
            alert('Central read should not be empty.');
            objNoOfCR.focus();
            return false;
        }

        let objNoOfAdj = null;
        for (var iCount = 1; iCount < objNoOfStage.value; iCount++) {
            objNoOfAdj = document.getElementById('NoOfADJ_' + iCount);
            if (objNoOfAdj.value.trim() == '' || objNoOfAdj.value.trim() == '0') {
                alert('Number of adjudication stage ' + (iCount+1) +' should not be empty or 0.');
                objNoOfAdj.focus();
                retVal = false;
                break;
            }
        }
        return retVal;
    }

    const fetchParadigmDetail = (paradigmID) => {
        fetch('api/Paradigms/GetParadigmDetail?paradigmID=' + paradigmID)
            .then(response => response.json())
            .then(data => {
                if (props.action == 'view') {
                    setCTRDisabled("disabled");
                }

                setData(data);
            });
    }

    function setData(data) {
        ParadigmID: data.ParadigmID;
        document.getElementById('txtName').value = data.Name;
        document.getElementById('txtDescription').value = data.Description;
        document.getElementById('selectNoOfStage').value = data.Stages;
        document.getElementById('txtNoOfLR').value = data.LocalReads;
        document.getElementById('txtNoOfCR').value = data.CentralReads;
        let objNoOfAdj = null;
        setArrNoOfStage(data.listParadigmStages);
        setArrNoOfStageTemp(data.listParadigmStages);
        setNoOfStage(data.Stages);
    }

    const SubmitParadigmsData = (event) => {
        if (props.paradigmID == 0) {
            SaveParadigms();
        }
        else {
            UpdateParadigms();
        }
    };

    const SaveParadigms = (event) => {
        const retVal = ValidateParadigms();
        if (retVal == false)
            return false;

        let paradigmsModel =
        {
            ParadigmID: 0,
            Name: document.getElementById('txtName').value,
            Description: document.getElementById('txtDescription').value,
            Stages: document.getElementById('selectNoOfStage').value,
            LocalReads: document.getElementById('txtNoOfLR').value,
            CentralReads: document.getElementById('txtNoOfCR').value,
            Username : EDCUserID,
            listParadigmStages: new Array()
        }

        let objNoOfAdj = null;
        const objNoOfStage = document.getElementById('selectNoOfStage');
        for (var iCount = 1; iCount < objNoOfStage.value; iCount++) {
            objNoOfAdj = document.getElementById('NoOfADJ_' + iCount);
            paradigmsModel.listParadigmStages.push({ ParadigmStageID: 0, ParadigmID: 0, StageNo: 0, Adjudications: objNoOfAdj.value });
        }

        fetch('api/Paradigms/SaveParadigms', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(paradigmsModel)
        }).then(r => r.json()).then(res => {
            if (res == -1) {
                alert('Paradigms not saved, Something went wrong.');
                return false;
            }
            if (res == 0) {
                alert('Paradigm name already exist.');
                return false;
            }
            if (res == 1) {
                navigate("/Paradigms");
            }
        })
        return true;
    };

    const UpdateParadigms = (event) => {
        const retVal = ValidateParadigms();
        if (retVal == false)
            return false;

        let paradigmsModel =
        {
            ParadigmID: props.paradigmID,
            Name: document.getElementById('txtName').value,
            Description: document.getElementById('txtDescription').value,
            Stages: document.getElementById('selectNoOfStage').value,
            LocalReads: document.getElementById('txtNoOfLR').value,
            CentralReads: document.getElementById('txtNoOfCR').value,
            Username: EDCUserID,
            listParadigmStages: new Array()
        }

        let objNoOfAdj = null;
        let objParadigmStageID = null;
        let objStageNo = null;
        const objNoOfStage = document.getElementById('selectNoOfStage');
        for (var iCount = 1; iCount < objNoOfStage.value; iCount++) {
            objNoOfAdj = document.getElementById('NoOfADJ_' + iCount);
            objParadigmStageID = document.getElementById('hdnParadigmStageID_' + iCount);
            objStageNo = document.getElementById('hdnStageNo_' + iCount);
            

            paradigmsModel.listParadigmStages.push({ ParadigmStageID: objParadigmStageID.value, ParadigmID: 0, StageNo: objStageNo.value, Adjudications: objNoOfAdj.value });
        }

        fetch('api/Paradigms/UpdateParadigms', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(paradigmsModel)
        }).then(r => r.text()).then(res => {
            if (res == -1) {
                alert('Paradigms not saved, Something went wrong.');
                return false;
            }
            if (res == 0) {
                alert('Paradigm name already exist.');
                return false;
            }
            if (res == 1) {
                props.onHandleClose();
                props.fetchParadigmsData();
            }
        })
        return true;
    };

    const handleInput = (inputEv, index) => {
        const value = inputEv.target.value;
        setArrNoOfStage((state) => state.map((val, i) => (i !== index ? val : value)));
    };

    return (
        <Card>
            <Row>
                <Col md={1}></Col>
                <Col md={5} className={classes.verticalLine}>
                    <Row style={{ marginBottom: "35px" }}>
                        <Col md={4}>Paradigm Name<MandatoryLabel></MandatoryLabel>:</Col>
                        <Col md={8}><InputString id="txtName" maxlength="150" disabled={ctrDisabled} /></Col>
                    </Row>
                    <Row style={{ marginBottom:"35px"}}>
                        <Col md={4}>Paradigm Description:</Col>
                        <Col md={8}><InputTextarea id="txtDescription" rows="8" maxlength="250" disabled={ctrDisabled} /></Col>
                    </Row>
                    <Row style={{ marginBottom:"35px"}}>
                        <Col md={4}>Number of Stages <MandatoryLabel></MandatoryLabel>:</Col>
                        <Col md={8}><SelectList id="selectNoOfStage" dataSource={stages} onChangeHandler={NoOfStageChangeHandler} disabled={ctrDisabled} /></Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <div style={{ "textAlign": "center", marginTop: "50px" }}>
                                {props.action != "view" && <Button id="btnSave" className="btn btn-success" style={{ marginRight: "10px" }} onClick={SubmitParadigmsData}> Save </Button>}
                                <Button id="btnCancel" className="btn btn-danger" onClick={props.onHandleClose || cancelClickHandler}> Close </Button>
                            </div>
                        </Col>
                    </Row>
                </Col> 
                <Col md={1}>                   
                </Col>
                <Col md={5}>
                    <Row style={{ marginBottom: "0px" }}>
                        <Col md={6}><ParadigmInitialStage title="Stage 1" disabled={ctrDisabled} /></Col>
                    </Row>

                    {(() => {
                        let td = [];
                        arrNoOfStage.map((item, index) => {
                            index++;
                            td.push(<Row key={index} style={{ "margin": "0px", "padding": "0px" }}>
                                <Col md={6} style={{ "margin": "0px", "padding": "0px" }}>
                                    <img style={{ marginLeft: "150px" }} height="65px" width="64px" src={DownArrow} />
                                    <ParadigmStage title={`Stage ${(index + 1)}`} sequence={index} disabled={ctrDisabled} item={item} handleInput={handleInput} />
                                </Col>
                            </Row>)
                        })
                        return td;
                    })()}

                 </Col>
            </Row>
        </Card>
        )
}

export default ParadigmDesigner;