import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import DataTable from "react-data-table-component";
import SelectList from '../UI/Control/SelectList';
import Button from '../UI/Button/Button';
import Card from '../UI/Card/Card';
import InputString from '../UI/Control/InputString';
import NotificationDetailsModel from './NotificationDetailsModel'
import classes from '../TrialstatNoto.module.css'
import customStyles from '../customStyles';
import NoData from '../NoData';

const Notifications = (props) => {
    const selectRef = useRef('');
    const usrRole = useSelector(state => state.auth.UserRole);
    const navigate = useNavigate();
    const [userPermission, setUserPermisssion] = useState('RO'); //RO, RW, NA
    const onClickHandler = () => {
        navigate('/NotificationDetails');
    }
    const [q, setQ] = useState('');
    const [inputVal, setInputVal] = useState('');
    useEffect(() => {
        setCompPermission();
    }, [])
    const setCompPermission = () => {
        if (usrRole == "Read-Only")
            setUserPermisssion("RO")
        else if (usrRole == "Database Designer" || usrRole == "Super User")
            setUserPermisssion("RW")
        else if (usrRole == "Tester")
            setUserPermisssion("RO")
        else if (usrRole == "Clinical Data Manager" || usrRole == "Manager, Clinical Data Management") {
            setUserPermisssion("RO")
            setmanageSitePermission("RW")
        }
        else
            setUserPermisssion("NA")
    }
    const columns = [
        {
            name: "Template Name",
            selector: row => row.TemplateName,
            cellExport: row => row.TemplateName,
            sortable: true,
            center: true
        },
        {
            name: "Last Updated",
            selector: row => row.LastUpdated,
            cellExport: row => row.LastUpdated,
            sortable: true,
            center: true
        },
        {
            name: "Updated By",
            selector: row => row.UpdatedBy,
            cellExport: row => row.UpdatedBy,
            sortable: true,
            center: true,
        },
        {
            name: "Study Name",
            selector: row => row.StudyName,
            cellExport: row => row.StudyName,
            sortable: true,
            center: true
        },
        {
            name: "Last Used",
            selector: row => row.LastUsed,
            cellExport: row => row.LastUsed,
            sortable: true,
            center: true
        },
        {
            name: "Status",
            selector: row => row.Status,
            cellExport: row => row.Status,
            sortable: true,
            center: true
        },
        {
            name: "Action",
            button: true,
            className: 'headercaption',
            width: '550px',
            style: {
                'border-right-style': 'solid',
                'border-width': '1px',
                'border-color': 'rgba(0, 0, 0, 0.12)'
            },
            cell: (row) => (
                <div style={{ "padding": "5px" }}>
                    {userPermission == "RW" && <button style={{ "margin": "5px" }} onClick={props.onClickSrcOpener} className="btn btn-sm btn-success"> Duplicate </button>}
                    {(userPermission == "RW" || userPermission == "RO") && <NotificationDetailsModel action="view" header="View Nofication Template" />}
                    {userPermission == "RW" && <NotificationDetailsModel action="edit" header="Edit Nofication Template" />}
                    {userPermission == "RW" && <button style={{ "margin": "5px" }} onClick={props.onClickSrcOpener} className="btn btn-sm btn-danger"> Delete </button>}
                    {userPermission == "RW" && (row.Status == "Active" ?
                            <button style={{ "margin": "5px" }} onClick={props.onClickSrcOpener} className="btn btn-sm btn-secondary">Disable </button>
                          : <button style={{ "margin": "5px" }} onClick={props.onClickSrcOpener} className="btn btn-sm btn-primary">Active </button>)}
                </div>
            )
        }
    ];

    let data = [
        {
            TemplateName: 'Temp01',
            LastUpdated: "02 January 2022",
            UpdatedBy: "Demo User",
            StudyName: "Demo",
            LastUsed: "21 January 2022",
            Status : "Active"
        }      
    ];

    function search(rows) {
        const columns = rows[0] && Object.keys(rows[0]);
        if (q != 'All') {
            let selString = q.toString();
            let searchText = selString.toLowerCase().split(",");
            return rows.filter((row) => {
                return columns.some((column) => {
                    if (searchText.length > 1)
                        return searchText.indexOf(row[column].toString().toLowerCase()) > -1
                    else
                        return row[column].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
                })
            })
        }
        else
            return rows;
    }
    const searchChangeHandler = (e) => {
        setQ(e.target.value);
        setInputVal(e.target.value);
        document.getElementById("ddlStudy").selectedIndex = 0;
    }
    
    const study = [
        {
            Name: "All (Default)",
            Value: "All"
        },
        {
            Name: "Merck-001",
            Value: "Merck-001"
        },
        {
            Name: "Global",
            Value: "Global"
        },
        {
            Name: "ELI-01243",
            Value: "ELI-01243"
        }
    ]
    const studyChangeHandler = (e) => {
        setQ(e.target.value.trim());
        setInputVal("");
    }
    return <Card>
        <Row>
            <Col md={4} className="form-inline mb-2">
                <SelectList id="ddlStudy" dataSource={study} onChangeHandler={studyChangeHandler}/>
            </Col>
            <Col md={6}>
                <div className="input-group">
                    <InputString id="txtSearch" value={inputVal} onChangeHandler={searchChangeHandler} placeholder="Search" />
                </div>
            </Col>
            <Col md={2}>
                <Button style={{ "float": "right" }} onClick={onClickHandler} className="btn btn-success">Create</Button>
            </Col>
        </Row>
        <div className="tableContainer">
            { search(data).length > 0 ? <DataTable
                    columns={columns}
                    data={search(data)}
                    className="table table-striped table-bordered table-hover"
                    customStyles={customStyles}
                    striped
                    pagination
                    paginationPerPage={50}
                    paginationRowsPerPageOptions={[20, 50, 100]}
                /> : <NoData headers={columns}></NoData> }
        </div>
    </Card>
}

export default Notifications;