import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Card from '../UI/Card/Card';
import SelectList from '../UI/Control/SelectList';
import classes from './../TrialstatNoto.module.css';


const SubjectStatusModal = (props) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        
        FillSubjectStatusList();
        setShow(true);
    };

    const [arrSubjectStatus, setSubjectStatus] = useState([]);

    const FillSubjectStatusList = () => {
        fetch('api/Site/GetSubjectStatusList')
            .then(response => response.json())
            .then(data => {
                setSubjectStatus(data);
                if (props.substatus != '') {
                    GetStatusValueByText(props.substatus);
                }
            });
    }
    const GetStatusValueByText = (text) => {
        let objDDList = document.getElementById("ddlSubjectStatus");
        const options = document.getElementById('ddlSubjectStatus').options;
        for (let i = 0; i < options.length; i++) {
            if (options[i].text === text) {
                objDDList.value = options[i].value;
                break;
            }
        }
        return null;
    };
    
    const UpdateSubjectStatus = (event) => {
        let objDDList = document.getElementById("ddlSubjectStatus");
        let objIpValue = objDDList.value;
        if (objIpValue == '-1' || objIpValue == '=') {
            alert('Please select the Subject Status.');
            objDDList.focus();
            return false;
        }

        let objQSS = {
            SubjectID: props.subjectid,
            StatusTypeId: document.getElementById("ddlSubjectStatus").value,
            Study: props.study
        };
        
        fetch('api/Site/UpdateSubjectStatus', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(objQSS)
        }).then(r => r.text()).then(res => {            
            if (res == "success") {
                setShow(false);
                props.refreshsata();
            }
            else if (res == "notsaved") {
                alert('Subject status not added, Something went wrong.');
                return false;
            }
            else if (res.startsWith("Error:")) {
                alert(res);
                return false;
            }
        })
    };

    return (
        <>
            <Button variant="secondary" className="btn btn-sm btn-success" style={props.style} onClick={handleShow}> Change Status </Button>

            <Modal centered
                show={show}
                onHide={handleClose}
                dialogClassName={classes.TSModalDailog_Ten} className="modal-dialog-top" style={{ "height": "auto" }} backdrop="static">

                <Modal.Header closeButton>
                    <Modal.Title style={{ "text-align": "center", display: "block" }}>{props.header}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card>
                        <div className="row mt-2">
                            <div className="col-sm-4">
                                Subject ID:
                                </div>
                            <div className="col-sm-8">
                                {props.subjectid}
                            </div>
                        </div>
                        <div className="row mt-2" id="divSS">
                            <div className="col-sm-4">                               
                                    Subject Status:
                                </div>
                            <div className="col-sm-8">
                                <div style={{ "justify-content": "space-between" }}>
                                    <SelectList id='ddlSubjectStatus' dataSource={arrSubjectStatus} style={{ "width": "90%" }} />
                                </div>
                            </div>
                        </div>
                    </Card >
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-md btn-success" variant="primary" onClick={UpdateSubjectStatus}> Save </Button>
                    <Button className="btn btn-md btn-danger" variant="secondary" onClick={handleClose}> Cancel </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};
export default SubjectStatusModal;