//Documentation: https://jpuri.github.io/react-draft-wysiwyg/#/docs
//https://draftjs.org/docs/getting-started
import React from 'react';
import { useNavigate } from 'react-router-dom'
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { Editor } from 'react-draft-wysiwyg'
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import styles from 'draft-js/dist/Draft.css';
import Button from '../UI/Button/Button'
import Card from '../UI/Card/Card';
import classes from '../TrialstatNoto.module.css';
import InputString from '../UI/Control/InputString';
import SelectList from '../UI/Control/SelectList';
const NotificationDetails = (props) => {  
    const navigate = useNavigate();
    const cancelClickHandler = () => {
        navigate('/NotificationTemp');
    }
    const saveClickHandler = () => {
        navigate('/NotificationTemp');
    }        
    const isDisabled = (props.action == 'view' ? true : false);
    return (
        <Card>
            <Row>
                <Col className="mb-2" md={2} style={{ "margin-left": "15px" }}><b>Template Name:</b>
                </Col>
                <Col className="mb-2" md={6}>
                    <InputString disabled={isDisabled} />
                </Col>
            </Row>
            <Row>
                <Col md={2} style={{ "margin-left": "15px" }}> <b>Applicable Study:</b>
                </Col>
                <Col className="mb-2" md={6}>
                    <SelectList disabled={isDisabled} />
                </Col>
            </Row>
            <Row>
                <Col md={2} style={{"margin-left":"15px"}}> <b>Subject Line:</b>
                </Col>
                <Col className="mb-2" md={6}>
                    <InputString disabled={isDisabled} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Editor
                        wrapperClassName={classes.TSWrapper01}
                        editorClassName={classes.TSEditor01}
                        toolbarClassName="toolbar-class"                       
                       />
                 </Col>
            </Row>
            <Row>
                <Col style={{ "textAlign": "center", paddingLeft: "5px" }}>
                    <Button id="btnSave" className="btn btn-success" onClick={saveClickHandler} style={{ "margin": "5px", display: props.action == 'view'? "none" : "" }}> Save Notification Template </Button>
                    <Button id="btnCancel" className="btn btn-secondary" onClick={props.onCloseHandler || cancelClickHandler }> Cancel </Button>                    
                </Col>
            </Row>
            </Card>
        )
}

export default NotificationDetails