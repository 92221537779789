//Documentation# https://bestofreactjs.com/repo/jbetancur-react-data-table-component-react-table-grid#demo-and-examples
//Documentation# https://jbetancur.github.io/react-data-table-component/?path=/story/getting-started-intro--page

import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import DataTable from "react-data-table-component";
import SelectList from '../UI/Control/SelectList';
import InputString from '../UI/Control/InputString';
import InputNumber from '../UI/Control/InputNumber';
import Card from '../UI/Card/Card';
import classes from '../TrialstatNoto.module.css';
import customStyles from '../customStyles';
import NoData from '../NoData';

const CRCurrentTask = (props) => {
    const [q, setQ] = useState('');
    const [inputVal, setInputVal] = useState('');
    const [rowData, setData] = useState([]);
    const [crUsersList, setcrUsersList] = useState([]);
    const [edcUserID, setEDCUserID] = useState('');
    const [crUserId, setCRUserId] = useState('-1');
    const [selUserName, setSelUser] = useState('');
    const [studyList, setStudyList] = useState([]);
    const [studyID, setStudyID] = useState('');

    const columns = [
        {
            name: "ReadAssignmentID",
            selector: row => row.ReadAssignmentID,
            cellExport: row => row.ReadAssignmentID,
            sortable: true,
            center: true,
            omit: true
        },
        {
            name: "TaskID",
            selector: row => row.TaskID,
            cellExport: row => row.TaskID,
            sortable: true,
            center: true,
            width: "7%"
        },
        {
            name: "StudyParadigmCRFID",
            selector: row => row.StudyParadigmCRFID,
            cellExport: row => row.StudyParadigmCRFID,
            sortable: true,
            center: true,
            omit: true
        },
        {
            name: "Study",
            selector: row => row.Study,
            cellExport: row => row.Study,
            sortable: true,
            center: true,
            width: "12%",
            cell: (row) => (
                <div className={classes.textWrap}>{row.Study}</div>
            )
        },
        {
            name: "Read Type",
            selector: row => row.ReadType,
            cellExport: row => row.ReadType,
            sortable: true,
            center: true,
            width: "6%"
        },
        {
            name: "Procedure ID",
            selector: row => row.ProcedureID,
            cellExport: row => row.ProcedureID,
            sortable: true,
            center: true,
            width: "9%"
        },
        {
            name: "Assigned Date",
            selector: row => row.AssignedDate,
            cellExport: row => row.AssignedDate,
            sortable: true,
            width: "10%",
            center: true,
            cell: (row) => (
                <div className={classes.textWrap}>{row.AssignedDate}</div>
            ),
            sortFunction: (a, b) => { return props.sortByDateTime(a.AssignedDate, b.AssignedDate); }
        },
        {
            name: "Due",
            selector: row => row.DueDate,
            cellExport: row => row.DueDate,
            sortable: true,
            width: "10%",
            center: true,
            cell: (row) => (
                <div className={classes.textWrap}>{row.DueDate}</div>
            ),
            sortFunction: (a, b) => { return props.sortByDateTime(a.DueDate, b.DueDate); }
        },
        {
            name: "Hours Remaining",
            selector: row => row.HrsRemaining,
            cellExport: row => row.HrsRemaining,
            sortable: true,
            width: "8%",
            center: true
        },
        {
            name: "Priority",
            selector: row => row.Priority,
            cellExport: row => row.Priority,
            center: true,
            width: "6%"
        },
        {
            name: "Disease",
            selector: row => row.Disease,
            cellExport: row => row.Disease,
            width: "9%",
            cell: (row) => (
                <div className={classes.textWrap}>{row.Disease}</div>
            )
        },
        {
            name: "Info",
            selector: row => row.Info,
            cellExport: row => row.Info,
            center: true,
            width: "14%",
            cell: (row) => (
                <div className={classes.textWrap}>{row.Info}</div>
            )
        },
        {
            name: "AssignedUserId",
            selector: row => row.AssignedUserId,
            cellExport: row => row.AssignedUserId,
            center: true,
            omit: true
        },
        {
            name: "Assigned User",
            selector: row => row.AssignedUser,
            cellExport: row => row.AssignedUser,
            center: true,            
            cell: (row) => (
                <div className={classes.textWrap}>{row.AssignedUser}</div>
            )
        }
    ];

    const getUserID = () => {
        fetch('api/Users/GetEDCUserID')
            .then(response => response.text())
            .then(data => {
                setSelUser("");
                setEDCUserID(data);
                fetchStudies();
                fetchCRCurrentTask(data, '');
                fetchCRUserList(data);
                setCRUserId(data);
            });
    };

    const fetchCRCurrentTask = (edcusr, studyid) => {
        fetch('api/ImageService/GetCRCurrentTask?UserID=' + edcusr + '&StudyId=' + studyid)
            .then(response => response.json())
            .then(data => {
                setData(data);
            });
    };

    const fetchCRUserList = (edcusr) => {
        fetch('api/ImageService/FillCRUserList?UserID=' + edcusr)
            .then(response => response.json())
            .then(data => {
                setcrUsersList(data);
            });
    };
    const studyOnChangeHandler = (e) => {
        setInputVal("");
        setStudyID(e.target.value);
        fetchCRCurrentTask(crUserId, e.target.value);
    }

    const fetchStudies = () => {
        fetch('api/ImageService/FillCRTaskStudyList')
            .then(response => response.json())
            .then(data => {

                setStudyList(data);
                var selStudyID = document.getElementById("ddlStudy").value;
                setStudyID(selStudyID);                
            });
    };
    useEffect(() => {
        getUserID();      
    }, []);

    const filteredColumns = ['AssignedUser'];

    function search(rows) {
       
        const columns = rows[0] && Object.keys(rows[0]);
        if (q != '' && q != 'undefined' && q != undefined) {
            let selString = q.toString();
            let searchText = selString.toLowerCase().split(",");
            return rows.filter((row) => {
                return columns.some((column) => {
                    
                    if (row[column] != null) {
                        if (selUserName != '') {
                            if (searchText == row["AssignedUser"].toString().toLowerCase()) {
                                return searchText.indexOf(row[column].toString().toLowerCase()) > -1;
                            }
                            else if (selUserName == row["AssignedUser"].toString().toLowerCase()) {
                                return row[column].toString().toLowerCase().indexOf(q.toLowerCase()) > -1;
                            }
                        }
                        else if (searchText.length > 1) {
                            return searchText.indexOf(row[column].toString().toLowerCase()) > -1;
                        }
                        else {
                            return row[column].toString().toLowerCase().indexOf(q.toLowerCase()) > -1;
                        }
                    }
                });
            });
        }
        else if (selUserName != '') {
            
            return rows.filter((row) => {
                return filteredColumns.some((column) => {
                    if (selUserName == row["AssignedUser"].toString().toLowerCase())
                        return selUserName.indexOf(row[column].toString().toLowerCase()) > -1;
                });
            });
        }
        else
            return rows;
    }

    const searchChangeHandler = (e) => {
        var ipVal = e.target.value;
        if (e.target.value == undefined || e.target.value == 'undefined')
            ipVal = '';
     
        setQ(ipVal);
        setInputVal(ipVal);        
    };

    const ddlCRUsersChangeHandler = (e) => {     
        setCRUserId(e.target.value);
        
        if (e.target.value == "") {
            setQ(e.target.value);
            setInputVal("");
            setSelUser("");
        }
        else {
            setInputVal("");
            setSelUser(e.target.options[e.target.selectedIndex].text.toLowerCase());
            setQ(e.target.options[e.target.selectedIndex].text);
        }
    };

    const conditionalRowStyles = [
        {
            when: row => row.Priority === 'Y',
            style: {
                color: 'Red'
            }
        }
    ];
    
    return (
        <Card>
            <Row className={classes.gridSearchPanel}>
                <Col md={3} className="form-inline" ><div className="input-group" >
                    <SelectList id='ddlCRUsers' dataSource={crUsersList} onChangeHandler={ddlCRUsersChangeHandler} value={crUserId} style={{ width: "200px" }} />
                </div>
                </Col>
                <Col md={3}>
                    <SelectList id='ddlStudy' dataSource={studyList} onChangeHandler={studyOnChangeHandler} />
                </Col>
                <Col md={3} />
                <Col md={3} className="form-inline" >
                    <div style={{ float: "right" }}>
                        <InputString id="txtSearch" value={inputVal} onChangeHandler={searchChangeHandler} style={{ width: "400px" }} placeholder="Search" />
                    </div>
                </Col>
            </Row>
            <div className="tableContainer" style={{ "border": "1px" }}>
                {search(rowData).length > 0 ? <DataTable
                    columns={columns}
                    data={search(rowData)}
                    className="table table-striped table-bordered table-hover"
                    conditionalRowStyles={conditionalRowStyles}
                    customStyles={customStyles}
                    striped
                    pagination
                    paginationPerPage={50}
                    paginationRowsPerPageOptions={[20, 50, 100]}
                /> : <NoData headers={columns} />}
            </div>
        </Card>
    );
};

export default CRCurrentTask;
