import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import classes from './../TrialstatNoto.module.css';

const OutcomeModal = (props) => {
    const [rowData, setRows] = useState([]);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        fetchOutcomeScoreList()
        setShow(true)
    };

    const PrintForm = (event) => {
         //window.print() 
        var content = document.getElementById('printarea');
        var pri = document.getElementById('ifmcontentstoprint').contentWindow;
        pri.document.open();
        pri.document.write(content.innerHTML);
        pri.document.close();
        pri.focus();
        pri.print();
    };   

    const fetchOutcomeScoreList = () => {
        fetch('api/Site/GetOutcomeScoreList?taskId=' + props.taskId)
            .then(response => response.json())
            .then(data => {
                setRows(data);
            });
    }

    return (
        <>
            <Button variant="primary" className="btn btn-primary" style={props.style} onClick={handleShow}
                title={props.title}> {props.text}</Button>
            <Modal centered show={show} onHide={handleClose} dialogClassName={classes.TSModalDailog_Seven} backdrop="static">

                <Modal.Header closeButton>
                    <Modal.Title style={{ "text-align": "center" }}><h5> {props.title}</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-sm-1" style={{ width: "4%" }}></div>
                        <div className="col-sm-1" style={{ width: "95%" }}>
                            <Button className={classes.noprintme} className="btn btn-md btn-success" variant="primary" onClick={PrintForm} style={{ marginBottom: "10px", float: "right" }}>Print</Button>
                        </div>
                        <div className="col-sm-1" style={{ width: "4%" }}></div>
                    </div>
                    <div id="printarea" className="row">
                        <div className="col-sm-1" style={{ width: "4%" }}></div>
                        <div className="col-sm-10" style={{ width: "95%" }}>
                            <table id="tblHeader" style={{ width: "100%", border: "solid", borderWidth: "thin", borderColor: "silver", marginBottom: "10px" }}>
                                <tr style={{ width: "100%", border: "solid", borderWidth: "thin", borderColor: "silver" }}>
                                    <td style={{ width: "20%", border: "solid", borderWidth: "thin", borderColor: "silver" }}>
                                        <b> Subject :</b>
                                    </td>
                                    <td style={{ width: "30%", border: "solid", borderWidth: "thin", borderColor: "silver" }}>
                                        {props.subjectid}
                                    </td>
                                    <td style={{ width: "20%", border: "solid", borderWidth: "thin", borderColor: "silver" }}>
                                        <b> Visit :</b>
                                    </td>
                                    <td style={{ width: "30%", border: "solid", borderWidth: "thin", borderColor: "silver" }}>
                                        {props.Visit}
                                    </td>
                                </tr>
                            </table>
                            <table id="tblList" style={{ width: "100%", border: "solid", borderWidth: "thin", borderColor: "silver"}}>
                                <tr className="headercaption" style={{ width: "100%", border: "solid", borderWidth: "thin", borderColor: "silver" }}>
                                    <td style={{ width: "50%", border: "solid", borderWidth: "thin", borderColor: "silver" }}><b>Question</b></td>
                                    <td style={{ width: "50%", border: "solid", borderWidth: "thin", borderColor: "silver" }}><b>Value</b></td>
                                </tr>
                                {
                                    rowData.map((item, index) => {
                                        return <tr className={classes.rowstriped} style={{ width: "100%", border: "solid", borderWidth: "thin", borderColor: "silver" }}>
                                            <td style={{ width: "50%", border: "solid", borderWidth: "thin", borderColor: "silver" }}>{item.QuesFriendlyName}</td>
                                            <td style={{ width: "50%", border: "solid", borderWidth: "thin", borderColor: "silver" }}>{item.AnswerValue}</td>
                                        </tr>
                                    })
                                }
                            </table>
                        </div>
                        <div className="col-sm-1" style={{ width: "4%" }}></div>
                        <iframe id="ifmcontentstoprint" style={{
                            height: '0px',
                            width: '0px',
                            position: 'absolute'
                        }}></iframe>  
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}
export default OutcomeModal;