import React, { useState, useEffect, useRef } from "react";
import { useSelector } from 'react-redux';
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import DataTable from "react-data-table-component";
import Card from '../UI/Card/Card';
import Button from '../UI/Button/Button';
import InputString from '../UI/Control/InputString';
import SponsorsAddEdit from './SponsorsAddEdit';
import classes from '../TrialstatNoto.module.css';
import customStyles from '../customStyles';
import NoData from '../NoData';
import Studies from "../Designer/Studies";



const SponsorsList = (props) => {
    const EDCUserID = useSelector(state => state.auth.gValue01);
    const [q, setQ] = useState('');
    const [inputVal, setInputVal] = useState('');
    const [rowData, setRows] = useState([]);
    const [userPermission, setUserPermisssion] = useState('RO'); //RO, RW, NA

    const columns = [
        {
            name: "SponsorID",
            selector: row => row.SponsorID,
            cellExport: row => row.SponsorID,
            omit: true
        },
        {
            name: "Sponsor Name",
            selector: row => row.SponsorName,
            cellExport: row => row.SponsorName,
            sortable: true,
            center: true,
        },
        {
            name: "Creation User",
            selector: row => row.CreationUser,
            cellExport: row => row.CreationUser,
            sortable: true,
            center: true
        },
        {
            name: "Creation Date",
            selector: row => row.CreationDate,
            cellExport: row => row.CreationDate,
            sortable: true,
            center: true,
            sortFunction: (a, b) => { return props.sortByDateTime(a.CreationDate, b.CreationDate); }
        },
        {
            name: "Action",
            button: true,
            className: 'headercaption',
            width: '14%',
            style: {
                'border-right-style': 'solid',
                'border-width': '1px',
                'border-color': 'rgba(0, 0, 0, 0.12)',
                'justify-content': 'center'
            }
            ,cell: (row) => (
                <div style={{ "padding": "5px" }}>
                    <SponsorsAddEdit action="edit" btn="true" disabled={false} text={"Edit"} header="Edit Sponsor Details" SponsorId={row.SponsorID} fetchSponsorsData={fetchSponsorsData} />
                    <Button id="btnDelete" variant="primary" className="btn btn-danger" style={{ "margin": "5px" }} onClick={() => DeleteSponsor(row, event)}>Delete</Button>
                </div>
            )
        }    
    ];

    const filteredColumns = ['SponsorName', 'CreationUser', 'CreationDate'];
    function search(rows) {
        
        let selString = q.toString();
        let searchText = selString.toLowerCase().split(",");
        return rows.filter((row) => {
            return filteredColumns.some((column) => {
                if (searchText.length > 1)
                    return searchText.indexOf(row[column].toString().toLowerCase()) > -1;
                else
                    return row[column].toString().toLowerCase().indexOf(q.toLowerCase()) > -1;
            });
        });
    }

    const searchChangeHandler = (e) => {
        setQ(e.target.value);
        setInputVal(e.target.value);
    };

    const fetchSponsorsData = () => {       
        fetch('api/Sponsors/GetSponsors')
            .then(response => response.json())
            .then(data => {
                setRows(data);
            });
    };


    useEffect(() => {
        fetchSponsorsData();
        getUserRole();
    }, []);

    const getUserRole = () => {
        fetch('api/Users/GetRoleByID?userID=' + EDCUserID)
            .then(response => response.text())
            .then(role => {
                setCompPermission(role);
            });
    };

    const setCompPermission = (usrRole) => {
        if (usrRole == "Read-Only")
            setUserPermisssion("RO");
        else if (usrRole == "Database Designer")
            setUserPermisssion("RW");
        else if (usrRole == "Tester")
            setUserPermisssion("RW");
        else if (usrRole == "Database Administrator")
            setUserPermisssion("RW");
        else if (usrRole == "Management, Image Services" || usrRole == "Image Services Coordinator")
            setUserPermisssion("RO");
        else if (usrRole == "Clinical Data Manager" || usrRole == "Manager, Clinical Data Management")
            setUserPermisssion("RW");
        else
            setUserPermisssion("NA");
    };

    const DeleteSponsor = (row, event) => {
        var con = confirm('Are you sure, you want to delete "' + row.SponsorName + '" sponsor?');
        if (con == true) {
            let objSponsorModel = {
                SponsorID: row.SponsorID
            };
            fetch('api/Sponsors/DeleteSponsor', {
                method: 'POST',
                headers: { 'Content-type': 'application/json' },
                body: JSON.stringify(objSponsorModel)
            }).then(r => r.text()).then(res => {
                if (res == "success") {
                    fetchSponsorsData();
                }
                else if (res == "notdeleted") {
                    alert('Sponsor not deleted, Something went wrong.');
                    return false;
                }
                else if (res == "exists") {
                    alert('The sponsor "' + row.SponsorName + '" has been referenced in studies and cannot be deleted.');
                    return false;
                }
                else if (res.startsWith("Error:")) {
                    alert(res);
                    return false;
                }
                
            });
            return true;
        }
    };

    return <Card>
        <Row style={{ marginBottom: "10px" }}>
            <Col md={6}>
                <div className="input-group">
                    <InputString id="txtSearch" value={inputVal} onChangeHandler={searchChangeHandler} placeholder="Search" />
                </div>
            </Col>
            <Col md={4} />
            <Col md={2}>
                <SponsorsAddEdit action="add" btn="true" disabled={false} text={"Add Sponsor"} header="Add Sponsor Details" SponsorId={0} fetchSponsorsData={fetchSponsorsData} />                
            </Col>
        </Row>
        <div className="tableContainer" style={{ width: "50%" }}>
            {search(rowData).length > 0 ? <DataTable
                columns={columns}
                data={search(rowData)}
                className="table table-striped table-bordered table-hover"
                customStyles={customStyles}
                striped
                pagination
                paginationPerPage={50}
                paginationRowsPerPageOptions={[20, 50, 100]}
            /> : <NoData headers={columns}></NoData>}
        </div>
    </Card>
}

export default SponsorsList;