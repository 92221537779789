import React, { useState } from 'react';
const passwordChangeHandler = (event) => {
   // setEnteredPassword(event.target.value);
};

const validatePasswordHandler = () => {
    //setPasswordIsValid(enteredPassword.trim().length > 6);
};

const InputPassword = (props) => {
    const [enteredPassword, setEnteredPassword] = useState('');
    const [passwordIsValid, setPasswordIsValid] = useState();
    let passwordId = typeof props.id == 'undefined' ? 'txtPassword' : props.id;
    return (
        <input id={passwordId} type="password"
            name="txtPassword"
            className="form-control"
            autoComplete="off"
            placeholder={props.placeholder}
            onChange={passwordChangeHandler}
            onBlur={validatePasswordHandler}
            style={props.style}/>
    );
};

export default InputPassword;
