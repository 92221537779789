import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Modal, Row, Col } from 'react-bootstrap';
import InputRadio from '../UI/Control/InputRadio';
import SelectList from '../UI/Control/SelectList';
import InputString from '../UI/Control/InputString';
import classes from './../TrialstatNoto.module.css';
import BorderedContainer from '../UI/Container/BorderedContainer';
import InputTextarea from '../UI/Control/InputTextarea';

const ManageAmendmentModal = (props) => {
    const EDCUserID = useSelector(state => state.auth.gValue01);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShowUATData("none");
        setShowPROD("none");
        setShowDelete("none");
        setShowNotes("none");
        setShowCNSection("none");
        fetchEnvironment();
        setShow(true);
    };
    const [showUATData, setShowUATData] = useState('none');
    const [showPROD, setShowPROD] = useState('none');
    const [showDelete, setShowDelete] = useState('none');
    const [amdOption, setAmdOption] = useState('');
    const [isProdcurion, setIsProdcurion] = useState(false);
    const [showNotes, setShowNotes] = useState('none');
    const [showCNSection, setShowCNSection] = useState('none');  
    const [arrSDHitory, setArrSDHitory] = useState([]);

    const fetchEnvironment = () => {
        fetch('api/Study/GetStudyEnvironmentByStudyId?studyId=' + props.studyId)
            .then(response => response.json())
            .then(data => {
                setIsProdcurion(data.Environment.toLowerCase().includes("prod"));
             
                if (data.Environment.toLowerCase().includes("dev") == true) {
                    document.getElementById("rdoDevToUAT").disabled = false;
                    document.getElementById("rdoDelAmd").disabled = false;
                }
                else if (data.Environment.toLowerCase().includes("delete") == true) {
                    document.getElementById("rdoDevToUAT").disabled = false;
                    document.getElementById("rdoDelAmd").disabled = false;
                }
                else if (data.Environment.toLowerCase().includes("uat") == true && data.Environment.toLowerCase().includes("prod") == false) {
                    document.getElementById("rdoDevToUAT").disabled = false;
                    document.getElementById("rdoUATtoProd").disabled = false;
                    document.getElementById("rdoDelAmd").disabled = false;
                }
                else if (data.Environment.toLowerCase().includes("prod") == true && data.Environment.toLowerCase().includes("uat") == true) {
                    document.getElementById("rdoDevToUAT").disabled = false;
                    document.getElementById("rdoUATtoProd").disabled = false;
                }
                else if (data.Environment.toLowerCase().includes("prod") == true) {
                    document.getElementById("rdoDevToUAT").disabled = false;
                }
                else {
                    document.getElementById("rdoUATtoProd").disabled = true;
                }
                setArrSDHitory(data.listStudyDeploymentsHistory);
            });
    }

    const UpdateEnvironment = (studyId, environment) => {
        let objAmendmentModel = {
            StudyID: studyId,
            Environment: environment,
            UpdatedBy: EDCUserID,
            HistoryNote: document.getElementById("txtDepNotes").value
        };

        fetch('api/Study/UpdateStudyEnvironment', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(objAmendmentModel)
        }).then(r => r.json()).then(res => {
            if (res == "-1" || res == "0") {
                alert('Amendment not created, Something went wrong.');
                return false;
            }
            else if (res == "1") {
                if (environment.toLowerCase() == 'uat') {
                    alert('Deployed study from development environment to user acceptance testing (UAT) environment successfully.');
                    props.fetchData();
                }
                if (environment.toLowerCase() == 'prod') {
                    alert('Deployed study from user acceptance testing (UAT) environment to production environment successfully. ');
                    props.fetchData();
                }
                if (environment.toLowerCase() == 'delete') {
                    alert('Canceled and deleted the study amendment successfully. ');
                    props.fetchData();
                }
                setShow(false);
            }
            else {
                alert(res)
                return false;
            }
        })

    };

    const SaveValue = (event) => {

        if (document.getElementById("txtUAT").value.toLowerCase() != "uat" && amdOption == "DevToUAT") {
            alert('Please enter "UAT" in the textbox to confirm.');
            return false;
        }

        if (document.getElementById("txtprod").value.toLowerCase() != "prod" && amdOption == "UATtoProd") {
            alert('Please enter "PROD" in the textbox to confirm.');
            return false;
        }

        if (document.getElementById("txtDelete").value.toLowerCase() != "delete" && amdOption == "DelAmd") {
            alert('Please enter "DELETE" in the textbox to confirm.');
            return false;
        }
        var stage = "";
        if (amdOption == "DevToUAT")
            stage = "UAT";
        else if (amdOption == "UATtoProd")
            stage = "PROD";
        else if (amdOption == "DelAmd")
            stage = "DELETE";
        else {
            alert('Please Select an Option.');
            return false;
        }
        let objDeployNote = document.getElementById('txtDepNotes');

        if (objDeployNote.value.trim() == '') {
            alert('Deployment notes should not be empty.');
            objDeployNote.focus();
            return false;
        }

        if (stage == "DELETE") {
            var con = confirm('Are you sure you want delete this study permanently?');
            if (con == false) {
                return false;
            }
        }

        if (stage != "")
            MigDesignStage(stage, props.studyId, EDCUserID);
    };
    const MigDesignStage = (stage, studyID, userID) => {
        document.getElementById('btnSubmit').disabled = true;
        fetch("api/ServiceCall/DesignMigration?Stage=" + stage + "&StudyID=" + studyID + "&UserID=" + userID, {
            method: "POST",
            mode: 'cors'
        })
        .then(response => response.text())
        .then(data => {
            if (data == "\"1\"") {
                UpdateEnvironment(studyID, stage);
                document.getElementById('btnSubmit').disabled = false;
            }
            else {
                alert("Error while project migration!")
                document.getElementById('btnSubmit').disabled = false;
            }
        })
    }

    const UATData = [];

    const handleOnchange = (e) => {
        setAmdOption(e.target.value);
        setShowCNSection("");
        if (e.target.value == "DevToUAT") {
            setShowUATData("flex");
            setShowPROD("none");
            setShowDelete("none");
            setShowNotes("flex");
        }
        else if (e.target.value == "UATtoProd") {
            setShowUATData("none");
            setShowPROD("flex");
            setShowDelete("none");
            setShowNotes("flex");
        }
        else if (e.target.value == "DelAmd") {
            setShowUATData("none");
            setShowPROD("none");
            setShowDelete("flex");
            setShowNotes("flex");
        }
    };

    return (
        <>
            
            {props.action === "edit" && <Button variant="primary" className="btn btn-warning" style={props.style} onClick={handleShow}> {props.text}  </Button>}
            {props.action === "createamendment" && <Button variant="primary" className="btn btn-danger" style={props.style} onClick={handleShow} title={props.title}> {props.text}  </Button>}

            <Modal centered
                show={show}
                onHide={handleClose}
                dialogClassName={classes.TSModalDailog_Thirteen} backdrop="static" style={{marginTop:"50px"}}>

                <Modal.Header closeButton>
                    <Modal.Title style={{ "text-align": "center" }}><h5> {props.studyName + " - " + props.protocolVersion}</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ maxHeight: "580px", overflowY: "scroll" }}>
                    <div className="row mb-3">
                        <div className="col-sm-1" style={{ width: "1%" }}>
                           
                        </div>
                        <div className="col-sm-10">
                            <h4> <strong>Please Select an Option</strong></h4>
                        </div>
                        <div className="col-sm-1">
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-sm-1" style={{width:"1%"}}>
                        </div>
                        <div className="col-sm-10" style={{ width: "98%" }}>
                            <div>
                                <InputRadio orientation='horizontal' disabled="true" value="DevToUAT" id="rdoDevToUAT" name="ManagementAmd" onChange={handleOnchange} text="Deploy study from development environment to user acceptance testing (UAT) environment." style={{ "margin-left": "10px", "margin-right": "5px" }}></InputRadio>
                            </div>
                            <div>
                                <InputRadio orientation='horizontal' disabled="true" value="UATtoProd" id="rdoUATtoProd" name="ManagementAmd" onChange={handleOnchange} text="Deploy study from user acceptance testing (UAT) environment to production environment." style={{ "margin-left": "10px", "margin-right": "5px" }}></InputRadio>
                            </div>
                            <div>
                                <InputRadio orientation='horizontal' disabled="true" value="DelAmd" id="rdoDelAmd" name="ManagementAmd" onChange={handleOnchange} text="Cancel and delete the study amendment." style={{ "margin-left": "10px", "margin-right": "5px" }}></InputRadio>
                            </div>
                        </div>
                        <div className="col-sm-1" style={{ width: "1%" }}>
                        </div>
                    </div>

                    <BorderedContainer style={{ width: "98%", height: "auto", padding: "15px", display: showCNSection }}>

                    <div id="divUATData" className="row mb-3" style={{ display: showUATData }}>
                        <div className="col-sm-10">
                                <div className="row" style={{"display":"none"}}>
                                <div className="col-sm-12">
                                    <label className="mb-1"><b> UAT Data </b></label>
                                    <SelectList dataSource={UATData} id="selectUATData" />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-sm-12">
                                        <label className="mb-1">Please enter "<b>UAT</b>" in the textbox to confirm</label>
                                        <InputString id="txtUAT" style={{ width: "50%", "text-transform":"uppercase"}} />
                                </div>
                                </div>
                        </div>
                    </div>

                    <div id="divPROD" className="row mb-3" style={{ display: showPROD }}>
                             <div className="col-sm-12">
                                <div className="row mt-3">
                                    <div className="col-sm-12">
                                        <label className="mb-1">Please enter "<b>PROD</b>" in the textbox to confirm</label>
                                        <InputString id="txtprod" style={{ width: "50%", "text-transform": "uppercase" }} />
                                    </div>
                                </div>
                            </div>
                    </div>
                    
                    <div id="divDelete" className="row mb-3" style={{ display: showDelete }}>

                            <div className="col-sm-12">
                                <div className="row mt-3">
                                    <div className="col-sm-12">                                        
                                        <div className="alert alert-danger" style={{ padding: "5px", width: "auto" }}><label><strong> Note:</strong> This will delete EDC project database permanently</label></div>
                                        <label className="mb-1">Please enter "<b>DELETE</b>" in the textbox to confirm</label>
                                        <InputString id="txtDelete" style={{ width: "50%", "text-transform": "uppercase" }} />
                                    </div>
                                </div>
                            </div>
                          
                        
                    </div>

                    <div id="divDepNotes" className="row mb-3" style={{ display: showNotes }}>
                       
                            <div className="col-sm-12">
                                <div className="row" >
                                    <div className="col-sm-12">
                                    <label className="mb-1"><b>Deployment Notes</b></label>
                                    <InputTextarea id="txtDepNotes" rows="2" cols="75" maxlength="200" />
                                    </div>
                                </div>
                            </div>
                       
                    </div>
                    </BorderedContainer>
                    <div>
                    </div>
                    <div style={{ "display": arrSDHitory.length > 0 ? " " : "none" }}>
                        <p> <strong>Study Deployments History</strong> </p>
                        <table>
                            <tr>
                                <th className={classes.tbHeaderCaptionDark} style={{ "text-align": "center", width:"50%" }} >Note</th>
                                <th className={classes.tbHeaderCaptionDark} style={{ "text-align": "center" }} >Environment</th>
                                <th className={classes.tbHeaderCaptionDark} style={{ "text-align": "center" }} >Creation User</th>
                                <th className={classes.tbHeaderCaptionDark} style={{ "text-align": "center" }} >Creation Date</th>
                            </tr>
                            <tbody>
                                {arrSDHitory.map((item) => {
                                    return (
                                        <tr>
                                            <td style={{ "text-align": "left", paddingLeft:"5px" }}>{item.Note}</td>
                                            <td>{item.Environment}</td>
                                            <td>{item.CreationUser}</td>
                                            <td>{item.CreationDate}</td>
                                        </tr>);
                                })
                                }
                            </tbody>
                        </table>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button id="btnSubmit" className="btn btn-md btn-success" variant="primary" onClick={SaveValue}>
                        Submit
                        </Button>
                    <Button className="btn btn-md btn-danger" variant="secondary" onClick={handleClose}>
                        Cancel
                        </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default ManageAmendmentModal;