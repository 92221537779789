import React from 'react';
import classes from '../../TrialstatNoto.module.css';

const BorderedContainer = (props) => {
    return (
        <div className={classes.BorderedContainer} style={props.style}>
            {props.children}
        </div>
    );
};
export default BorderedContainer;
