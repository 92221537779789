import React from 'react';
export const MenuData_SiteUser = [
    {
        name: "Home",
        level: "1",
        url: "/SiteDashboard",
        children: [
            {
                name: "Dashboard",
                level: "2",
                url: "/",
                children: [
                    {
                        name: "Site Dashboard",
                        level: "3",
                        url: "/SiteDashboard"
                    },
                    //{
                    //    name: "Study Dashboard",
                    //    level: "3",
                    //    url: "/StudyDashboard"
                    //},
                    {
                        name: "Subject Status",
                        level: "3",
                        url: "/SubjectStatusReport"
                    },
                    {
                        name: "Query Dashboard",
                        level: "3",
                        url: "/SiteQueryDashboard"
                    },
                    {
                        name: "Dashboard Viewer",
                        level: "3",
                        url: "/SiteDashboardViewer"
                    },
                    {
                        name: "Documents",
                        level: "3",
                        url: "/DocumentMgmtList"
                    }
                ]
            }                       
        ]
    },
    {
        name: "Help",
        level: "1",
        url: "/",
        children: [
            {
                name: " ",
                level: "2",
                url: ""
            }]
    },
    {
        name: "Contact Support",
        level: "1",
        url: "/",
        children: [
            {
                name: " ",
                level: "2",
                url: ""
            }]
    },
    {
        name: "About",
        level: "1",
        url: "/AboutUs",
        children: [
            {
                name: " ",
                level: "2",
                url: ""
            }]
    }
]