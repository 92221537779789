import React, { useState , useEffect  } from 'react';
import InputContainer from '../UI/Container/InputContainer';
import { Button } from 'react-bootstrap';
import classes from './../TrialstatNoto.module.css';
import MandatoryLabel from '../UI/Control/MandatoryLabel';
import InputString from '../UI/Control/InputString';

const AssignReadByUser = (props) => {
    const [assignReaderDetails, setAssignReaderDetails] = useState({});

    const fetchAssignReaderDetails = () => {
        fetch('api/ImageService/GetAssignReaderDetails?taskId=' + props.taskId + '&readStage=' + props.readStage)
            .then(response => response.json())
            .then(data => {
                setAssignReaderDetails(data);
            });
    }

    useEffect(() => {
        fetchAssignReaderDetails();
    }, []);

    function isURL(str) {
        var a = document.createElement('a');
        a.href = str;
        return (a.host && a.host != window.location.host);
    }

    const updateReadAssURL = (readAssignmentId) => {
        let objURL = document.getElementById('txtURL_' + readAssignmentId);
        if (props.isURLEnabled) {
            //if (objURL.value.trim() == '') {
            //    alert('URL should not be empty.');
            //    objURL.focus();
            //    return false;
            //}
            if (objURL.value.trim() != '') {
                if (!isURL(objURL.value)) {
                    alert('Please enter valid url.');
                    objURL.focus();
                    return false;
                }
            }
        }

        let objReadAssignmentsModel = {
            ReadAssignmentID: readAssignmentId,
            URL: props.isURLEnabled ? objURL.value : null
        };

        fetch('api/ImageService/updateReadAssURL', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(objReadAssignmentsModel)
        }).then(r => r.json()).then(res => {
            if (res == -1) {
                alert('URL update not done, Something went wrong.');
            }
            if (res > 0) {
                alert('URL updated successfully.');
            }
        })
    }

    const urlChangeHandler = (e) => {
        var readAssId = e.target.id.split('_')[1];
        var assignedReader = document.getElementById('hdn_' + readAssId).getAttribute('assignedReader');
        var assignReaderTemp = { ReadAssignmentID: readAssId, AssignedReader: assignedReader,  URL: e.target.value};
        setAssignReaderDetails(assignReaderTemp);
    }

    return (
        <div style={{ marginTop: "15px" }}>
            <InputContainer border='true' header={"Central Reader " + props.readStage} style={{ "text-align": "center" }} Expended="true" >
                <div className="row">
                    <div className="col-sm-12">
                        <div className="row" style={{ padding: "15px" }}>
                            <div className="col-sm-2">
                                <strong> Assigned Reader:</strong><MandatoryLabel />
                            </div>
                            <div className="col-sm-3" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                {assignReaderDetails.AssignedReader}
                            </div>
                            <div className="col-sm-3">
                                <Button variant="primary" className="btn btn-success" onClick={() => props.onChangeReader(event)} readAssignmentID={props.readAssignmentID} style={{ marginRight:"6px" }}>Change Reader</Button>
                                <Button variant="primary" className="btn btn-warning" onClick={() => props.OnRemoveAssignedReader(event)} readAssignmentID={props.readAssignmentID}>Remove Reader</Button>
                            </div>
                            {props.isURLEnabled && <div className="col-sm-3">
                                <input type="hidden" id={"hdn_" + assignReaderDetails.ReadAssignmentID} assignedReader={assignReaderDetails.AssignedReader} />
                                <InputString id={"txtURL_" + assignReaderDetails.ReadAssignmentID} readassignmentid="ff" assignedReader={assignReaderDetails.AssignedReader}
                                    value={assignReaderDetails.URL} onChangeHandler={urlChangeHandler} maxlength="499" placeholder="URL" />
                            </div>}
                            {props.isURLEnabled && <div className="col-sm-1">
                                <Button id="btnUpdateURL" className="btn btn-success" onClick={() => updateReadAssURL(assignReaderDetails.ReadAssignmentID)}>Update</Button>
                            </div>}
                        </div>
                    </div>

                </div>

            </InputContainer>
        </div>
    );
}
export default AssignReadByUser;
