import React from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import TextArea from '../UI/Control/InputTextarea';
import InputString from '../UI/Control/InputString';
import { useSelector } from 'react-redux'
import MandatoryLabel from '../UI/Control/MandatoryLabel';
const SiteQuery = (props) => {

    let taskId = props.taskId;
    const EDCUserID = useSelector(state => state.auth.gValue01);

    const SaveSiteQuery = (event) => {

        let objNotes = document.getElementById('txtNotes');
        if (objNotes.value.trim() == '') {
            alert('Notes should not be empty.');
            objNotes.focus();
            return false;
        }

        let happyFoxID = '';
        if(props.Type.toLowerCase() == 'cims')
        {
            happyFoxID = document.getElementById('txtHappyFoxID').value;
        }

        let objSiteQueryModel = {
            TaskID: taskId,
            Notes: objNotes.value,
            HappyFoxID: happyFoxID,
            CreationUser: EDCUserID
        };

        fetch('api/ImageService/SaveSiteQuery', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(objSiteQueryModel)
        }).then(r => r.json()).then(res => {
            if (res == -1) {
                alert('Notes not saved, Something went wrong.');
            }
            if (res > 0) {
                props.relaodAction();
            }
        })

    };

    return (
        <div>
            {props.Type.toLowerCase() == 'cims' && <Row className="mt-2 mb-2">
                <Col md={1}> Notes<MandatoryLabel />: </Col>
                <Col md={6}>
                    <TextArea id="txtNotes" rows='5' cols='100' maxlength="2000" />
                </Col>
                <Col md={5}>
                    <Row className="mt-2 mb-2">
                        <Col md={3}>Happy Fox ID:</Col>
                        <Col md={7}><InputString id="txtHappyFoxID" width="100px" maxlength="249" /></Col>
                        <Col md={2}><Button style={{ marginTop: "90px" }} className="btn-success" onClick={SaveSiteQuery} > Submit </Button></Col>
                    </Row>
                </Col>
            </Row>}

            {props.Type.toLowerCase() == 'lucidity' && <Row className="mt-2 mb-2">
                <Col md={1}> Notes<MandatoryLabel />: </Col>
                <Col md={9}>
                    <TextArea id="txtNotes" rows='5' cols='100' maxlength="2000" />
                </Col>
                <Col md={2}>
                    <Button style={{ marginTop: "90px" }} className="btn-success" onClick={SaveSiteQuery} > Submit </Button>
                </Col>
            </Row>}

        </div>
    );
}
export default SiteQuery;
