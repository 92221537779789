import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import DataTable from "react-data-table-component";
import Button from '../UI/Button/Button';
import Card from '../UI/Card/Card';
import InputString from '../UI/Control/InputString';
import AddStudySiteModal from './AddStudySiteModal';
import ProtocolVersionModal from './ProtocolVersionModal';
import customStyles from '../customStyles';
import NoData from '../NoData';
import EmailNotificationModal from './EmailNotificationModal';

const ManageSites = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [userPermission, setUserPermisssion] = useState('RO'); //RO, RW, NA
    let studyId = null;
    let studyProtocolId = 0;
    if (location.state != null) {
        studyId = location.state.studyId;
        studyProtocolId = location.state.studyProtocolId;
    }
    const HandleClose = (event) => {
        navigate('/Designer');
    };

    const columns = [
        {
            name: "StudySiteID",
            selector: row => row.StudySiteID,
            cellExport: row => row.StudySiteID,
            omit: true
        },
        {
            name: "Site Code",
            selector: row => row.SiteCode,
            cellExport: row => row.SiteCode,
            sortable: true,
            center: true
        },
        {
            name: "Site Name",
            selector: row => row.SiteName,
            cellExport: row => row.SiteName,
            sortable: true,
            center: true
        },
        {
            name: "Principle Investigator",
            selector: row => row.InvestigatorName,
            cellExport: row => row.InvestigatorName,
            sortable: true,
            center: true
        },
        {
            name: "Status",
            selector: row => row.Status,
            cellExport: row => row.Status,
            omit: true
        },
        {
            name: "Protocol Version",
            selector: row => row.ProtocolVersion,
            cellExport: row => row.ProtocolVersion,
            sortable: true,
            center: true
        },
        {
            name: "Address",
            selector: row => row.PhysAddress,
            cellExport: row => row.PhysAddress,
            sortable: true,
            center: true,
        },
        {
            name: "Action",
            button: true,
            width: '350px',
            style: {
                'border-right-style': 'solid',
                'border-width': '1px',
                'border-color': 'rgba(0, 0, 0, 0.12)'
            },
            cell: (row) => (
                <div style={{ "padding": "5px" }}>
                    {userPermission == "RW" && <ProtocolVersionModal style={{ "margin": "5px" }} text="VERSION" header="Update Protocol Version" studyId={studyId} studySiteID={row.StudySiteID} studyProtocolID={row.StudyProtocolID} fetchData={fetchData}></ProtocolVersionModal>}
                    <AddStudySiteModal style={{ "margin": "5px" }} action="view" text="VIEW" header="View Site" studySiteID={row.StudySiteID} studyId={studyId} fetchData={fetchData} ></AddStudySiteModal>
                    {userPermission == "RW" && <AddStudySiteModal style={{ "margin": "5px" }} action="edit" text="EDIT" header="Edit Site" studySiteID={row.StudySiteID} studyId={studyId} fetchData={fetchData} ></AddStudySiteModal>}
                    {userPermission == "RW" && <EmailNotificationModal style={{ "margin": "5px" }} action="edit" text="Email" studySiteID={row.StudySiteID} header={row.SiteName}></EmailNotificationModal>}
                </div >
            )
        }
    ];

    const [q, setQ] = useState('');
    const [rowData, setData] = useState([]);
    const [isSaved, setIsSaved] = useState(false);

    const getUserRole = () => {
        fetch('api/Users/GetRoleByID')
            .then(response => response.text())
            .then(role => {
                setCompPermission(role);
            });
    };

    const fetchData = () => {
        fetch('api/StudySites/GetStudySites?studyId=' + studyId)
            .then(response => response.json())
            .then(data => {
                setData(data);
            });
        setIsSaved(false);
    }

    useEffect(() => {
        getUserRole();
        fetchData();
    }, [])

    const setCompPermission = (usrRole) => {
        if (usrRole == "Read-Only")
            setUserPermisssion("RO");
        else if (usrRole == "Database Designer" || usrRole == "Super User")
            setUserPermisssion("RW");
        else if (usrRole == "Tester")
            setUserPermisssion("RO");
        else if (usrRole == "Clinical Data Manager" || usrRole == "Manager, Clinical Data Management") {
            setUserPermisssion("RW");
            setmanageSitePermission("RW");
        }
        else
            setUserPermisssion("NA");
    }
    function search(rows) {
        const columns = rows[0] && Object.keys(rows[0]);
        if (q != '') {
            let selString = q.toString();
            let searchText = selString.toLowerCase().split(",");
            return rows.filter((row) => {
                return columns.some((column) => {
                    if (searchText.length > 1)
                        return searchText.indexOf(row[column].toString().toLowerCase()) > -1;
                    else
                        return row[column].toString().toLowerCase().indexOf(q.toLowerCase()) > -1;
                })
            })
        }
        else
            return rows;
    }
    const searchChangeHandler = (e) => {
        setQ(e.target.value);
    }


    return <Card>
        <Row style={{ marginBottom:"10px" }}>
            <Col md={4} className="form-inline mb-2">
                <InputString id="txtSearch" onChangeHandler={searchChangeHandler} placeholder="Search" style={{display:"none"}} />
            </Col>
            <Col md={6}>
            </Col>
            <Col md={2}>
                {userPermission == "RW" && <AddStudySiteModal action="add" text="ADD SITE" header="Add New Site" setIsSaved={setIsSaved} style={{ "float": "right" }} studySiteID="0" studyId={studyId} studyProtocolId={studyProtocolId} fetchData={fetchData}></AddStudySiteModal>}
            </Col>
        </Row>
        <div className="tableContainer">
            {search(rowData).length > 0 ? <DataTable
                    columns={columns}
                data={search(rowData)}
                    className="table table-striped table-bordered table-hover"
                    customStyles={customStyles}
                    striped
                    pagination
                    paginationPerPage={50}
                    paginationRowsPerPageOptions={[20, 50, 100]}
                /> : <NoData headers={columns}></NoData> }
        </div>

        <div className="row">
            <div className="col-sm-12" style={{ textAlign: "center", marginTop: "10px" }}>
                <Button className="btn btn-danger" onClick={HandleClose}>
                    Close
                </Button>
            </div>
        </div>

    </Card>
}


export default ManageSites;
