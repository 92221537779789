import React, { useState, useEffect, useRef } from "react";
import { useSelector } from 'react-redux'
import { Button } from 'react-bootstrap';
import DataTable from "react-data-table-component";
import { Row, Col, FormGroup, Label, Input } from "reactstrap";
import InputString from '../UI/Control/InputString';
import AssignedReadByUserModal from './AssignedReadByUserModal';
import customStyles from '../customStyles';
import NoData from '../NoData';
import { useNavigate } from 'react-router-dom';
import InputTextArea from '../UI/Control/InputTextarea';

const Readers = (props) => {
    const EDCUserID = useSelector(state => state.auth.gValue01);
    const navigate = useNavigate();
    const [q, setQ] = useState('');
    const [inputVal, setInputVal] = useState('');    
    const [readers, setReaders] = useState([]);
    const taskId = props.taskId;

    useEffect(() => {
        fetchReaders();
    }, [])
    const columns = [        
        {
            name: "Central Reader",
            selector: row => row.Reader,
            cellExport: row => row.Reader,
            sortable: true,
            center: true
        },
        {
            name: "Reader Type",
            selector: row => row.ReaderType,
            cellExport: row => row.ReaderType,
            sortable: true,
            center: true,
        },
        {
            name: "Global CR Queue",
            selector: row => row.CurrReadQueue,
            cellExport: row => row.CurrReadQueue,
            sortable: true,
            center: true
        },
        {
            name: "Total Study Reads",
            selector: row => row.TotalStudyReads,
            cellExport: row => row.TotalStudyReads,
            sortable: true,
            center: true
        },
        {
            name: "Action",
            button: true,
            className: 'headercaption',
            width: '150px',
            cell: (row) => (
                <div style={{ "padding": "5px" }}>
                    <AssignedReadByUserModal btn="true" text="View" row={row} ></AssignedReadByUserModal>
                    <Button className="btn btn-sm btn-success" style={{ marginLeft: "10px" }} onClick={() => onClickHandler(row)}> Assign </Button>
                </div>
            )
        }
    ];

    const fetchReaders = () => {
        fetch('api/ImageService/GetReaders?taskId=' + taskId)
            .then(response => response.json())
            .then(data => {
                setReaders(data);
            });
    }

    function search(rows) {
        const columns = ['Reader', 'ReaderType', 'CurrReadQueue', 'TotalStudyReads']; //rows[0] && Object.keys(rows[0]);
        if (q != '') {
            let selString = q.toString();
            let searchText = selString.toLowerCase().split(",");
            return rows.filter((row) => {
                return columns.some((column) => {
                    if (searchText.length > 1)
                        return searchText.indexOf(row[column].toString().toLowerCase()) > -1
                    else
                        return row[column].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
                })
            })
        }
        else
            return rows;
    }
    const searchChangeHandler = (e) => {
        setQ(e.target.value);
        setInputVal(e.target.value);
        selectRef.current.resetSelectedValues();
    }

    const Close = (e) => {
        props.setActionType("Assign Read");
    }

    const assignLucReader = (row) => {
        fetch('api/LucidityServicesCall/UserAccess?UserID=' + row.EDCUserID + '&TaskID=' + taskId)
            .then(response => response.text())
            .then(data => {
                if (data == "cr")
                    assignReader(row)
                else {
                    alert("The user is currently not assigned to that project in Lucidity");
                    return false;
                }
            });
    }
    const assignCIMSReader = (row) => {
        fetch('api/CIMSServicesCall/UserAccess?UserID=' + row.EDCUserID + '&TaskID=' + taskId)
            .then(response => response.text())
            .then(data => {
                if (data == "cr")
                    assignReader(row)
                else {
                    alert("The user is currently not assigned to that project in CIMS");
                    return false;
                }
            });
    }

    const onClickHandler = (row) => {
        let readType = row.ReaderType.toString().toLowerCase();
        if (readType == 'histo')
            assignLucReader(row);
        else if (readType == 'endo')
            assignCIMSReader(row);
        else
            assignReader(row);
    }

    function isURL(str) {
        var a = document.createElement('a');
        a.href = str;
        return (a.host && a.host != window.location.host);
    }

    const assignReader = (row) => {
        let objURL = document.getElementById('txtURL');
        if (props.isURLEnabled) {

            //if (objURL.value.trim() == '') {
            //    alert('URL should not be empty.');
            //    objURL.focus();
            //    return false;
            //}
            if (objURL.value.trim() != '') {
                if (!isURL(objURL.value)) {
                    alert('Please enter valid url.');
                    objURL.focus();
                    return false;
                }
            }
        }

        let readType = row.ReaderType.toString().toLowerCase();
        let objReaderNote = document.getElementById('txtReaderNote');
        if (props.readAssignmentID == 0) {
            let objReadAssignmentsModel = {
                TaskID: taskId,
                ReadStage: props.readStage,
                AssignedReader: row.EDCUserID,
                CreationUser: EDCUserID,
                ReaderNote: objReaderNote.value,
                URL: props.isURLEnabled ? objURL.value : null
            };
            fetch('api/ImageService/SaveReadAssignments', {
                method: 'POST',
                headers: { 'Content-type': 'application/json' },
                body: JSON.stringify(objReadAssignmentsModel)
            }).then(r => r.json()).then(res => {
                if (res == -1) {
                    alert('Read assignment not done, Something went wrong.');
                }
                if (res > 0) {  
                    
                    if (readType == 'histo') {
                        //lucidity data. Create subject and post tast details to EDC
                        createSubject(row.EDCUserID)
                    }
                    else if (readType == 'endo') {
                        //CIMS data. subject create on QC stage, so just post data to EDC
                        postAdjDataToEDC(row.EDCUserID)
                    }
                    else {
                        //Custome viewer: Create subject and post tast details to EDC
                        createSubject(row.EDCUserID)
                    }
                    props.relaodAction();
                }
            })
        }
        else {
            let objReadAssignmentsModel = {
                ReadAssignmentID: props.readAssignmentID,
                AssignedReader: row.EDCUserID,
                CreationUser: EDCUserID,
                ReaderNote: objReaderNote.value,
                TaskID: taskId
            };

            fetch('api/ImageService/UpdateReadAssignments', {
                method: 'POST',
                headers: { 'Content-type': 'application/json' },
                body: JSON.stringify(objReadAssignmentsModel)
            }).then(r => r.json()).then(res => {
                if (res == -1) {
                    alert('Changing reader not done, Something went wrong.');
                }
                if (res > 0) {                    
                    props.relaodAction();
                }
            })
        }
    }
    const createSubject = async (assignedUserID) => {
        const response = await fetch('api/ServiceCall/CreateSubject?TaskId=' + taskId + '&CreationUser=' + EDCUserID,
            {
                method: "POST",
                mode: 'cors'
            }
        )
        let retData = await response.text();
        if (retData.trim() != "\"SubjectCreated\"") {
            alert("Error while creating subject: " + retData);
        }
        else {
            postAdjDataToEDC(assignedUserID)
        }
    }
    const postAdjDataToEDC = async (assignedUserID) => {
        const response = await fetch('api/ServiceCall/PostReadersData?TaskId=' + taskId + '&AssignedUserId=' + assignedUserID ,
            {
                method: "POST",
                mode: 'cors'
            }
        )
        let retData = await response.text();
        if (retData.trim() != "\"ReadersAdded\"") {
            //alert("Error while posting reader data: " + retData);
        }
    }

    return (
        <div>
            <Row className="mt-2 mb-2" >
                <Col md={6} className="form-inline">
                    <InputTextArea rows='3' cols='60' id="txtReaderNote" placeholder="Reader Note" maxlength="250" />
                </Col>
                <Col md={5}>
                    {props.isURLEnabled && props.readAssignmentID == 0 && <div className="input-group" style={{ marginBottom: "7px" }}>
                        <InputString id="txtURL" maxlength="499" placeholder="URL" style={{ marginRight: "3px" }} />
                    </div>}
                    <div className="input-group">
                        <InputString id="txtSearch" value={inputVal} onChangeHandler={searchChangeHandler} placeholder="Search" />
                    </div>
                </Col>
                <Col md={1}>
                    <Button className="btn btn-danger" style={{ float: "right", marginTop:"45px" }} onClick={Close}> Close </Button>
                </Col>
            </Row>
            <div className="tableContainer ">
                {search(readers).length > 0 ? <DataTable
                        columns={columns}
                        data={search(readers)}
                        className="table table-striped table-bordered table-hover"
                        customStyles={customStyles}
                        striped
                        pagination
                        paginationPerPage={50}
                        paginationRowsPerPageOptions={[10, 20, 50]}
                    /> : <NoData headers={columns}></NoData> }
            </div>
        </div>
    );
};

export default Readers;
