import React from 'react';

//import classes from './Card.module.css';

const customStyles = {
    noData: {
        style: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
    },
    rows: {
        style: {
            zIndex: 2,
            //minHeight: '40px !important', // override the row height
            fontSize: '15px',
            whiteSpace: 'pre',
        },
    },
    table: {
        style: {
            zIndex: 1,
        },
    },
    cells: {
        style: {
            'border-left-style': 'solid',
            'border-left-width': '1px',
            'border-bottom-style': 'solid',
            'border-bottom-width': '1px',
            'border-top-width': '0',
            'border-color': 'rgba(0, 0, 0, 0.12)'
        },
    },
    headRow: {
        //style: {
        //    minHeight: '45px',
        //    borderTopWidth: '1px',
        //    borderTopStyle: 'solid',
        //    borderBottomWidth: '2px',
        //},
    },
    headCells: {
        style: {
            'border-left-style': 'solid',
            'border-width': '1px',
            'border-color': 'rgba(0, 0, 0, 0.12)'
        },
    },
    subHeader: {
        style: {
            minHeight: '40px',
        },
    },
    pagination: {
        style: {
            minHeight: '40px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        pageButtonsStyle: {
            borderRadius: '50%',
            height: '40px',
            width: '40px',
            padding: '8px',
            'margin-top': '5px',
            cursor: 'pointer',
        },
    },
};

export default customStyles;
