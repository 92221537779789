import React, { useState, useEffect, useRef } from "react";
import { Button, Row, Col } from "reactstrap";
import { useNavigate } from 'react-router-dom';
import Card from '../UI/Card/Card';
import InputContainer from '../UI/Container/InputContainer';
import Notice from './Notice';
import NoticeModal from './NoticeModal';
import InprogressIcon from '../../Images/Icons/InProgress.svg';
import LockIcon from '../../Images/Icons/Lock.svg';
import DataTable from "react-data-table-component";
import InputString from '../UI/Control/InputString';
import SelectList from '../UI/Control/SelectList';
import classes from './../TrialstatNoto.module.css';
import customStyles from '../customStyles';
import NoData from '../NoData';
import DataTableExtensions from "../ExportTable/index";
import "../ExportTable/index.css";
import './../CustomDataTableExtensions.css';
import InputTextModal from './InputTextModal';
import AddManualTaskModal from './AddManualTaskModal';

const Dashboard = (props) => {
    const navigate = useNavigate();
    const [edcUserID, setEDCUserID] = useState('');
    const [readType, setReadType] = useState('');
    const [rowData, setData] = useState([]);
    const [notice, setNotice] = useState([]);
    const [userPermission, setUserPermisssion] = useState('RO'); //RO, RW, NA
    const [arrTaskStage, setTaskStage] = useState([]);
    const [arrSourceType, setSourceType] = useState([]);
    const [username, setUsername] = useState('');
    const [studyId, setStudyId] = useState('-1');
    const [taskStageId, setTaskStageId] = useState('-1');
    const [filterId, setFilterId] = useState('-1');
    const [arrFilterOptions, setFilterOptions] = useState([]);
    const [filterName, setFilterName] = useState('');
    const [sourceTypeId, setSourceTypeId] = useState('-1');
    const [noticeStudyId, setNoticeStudyId] = useState('-1');
    const [arrUserStudies, setUserStudies] = useState([]);
    //const [isDisabled, setIsDisabled] = useState(false);

    useEffect(() => {
        getUserID();
        getUserNameAndRoleByID();
        FillFilterOptionList();
        FillTaskStageList();
        FillSourceTypesList();
        fillStudyListByEDCUserId();
    }, []);

    const getUserNameAndRoleByID = () => {
        fetch('api/Users/getUserNameAndRoleByID')
            .then(response => response.json())
            .then(data => {
                if (localStorage.getItem('searchedVal') != '' && localStorage.getItem('searchedVal') != null) {
                    let objSerachedVal = JSON.parse(localStorage.getItem('searchedVal'));
                    if (objSerachedVal != null) {
                        setQ(objSerachedVal.text);
                        setInputVal(objSerachedVal.text);
                        setQ(objSerachedVal.text);
                        setFilterId(objSerachedVal.filterId);
                        setStudyId(objSerachedVal.study);
                        setTaskStageId(objSerachedVal.taskStageId);
                        setSourceTypeId(objSerachedVal.sourceTypeId);
                        document.getElementById('ddlFilterOptions').value = objSerachedVal.filterId;
                        document.getElementById('ddlStudy').value = objSerachedVal.study;
                        document.getElementById('ddlStage').value = objSerachedVal.taskStageId;
                        document.getElementById('ddlSourceType').value = objSerachedVal.sourceTypeId;
                        if (objSerachedVal.filterId != -1) {
                            //setIsDisabled(true);
                        }
                        fetchData(data.UserName, objSerachedVal.taskStageId, objSerachedVal.study, objSerachedVal.sourceTypeId);
                        localStorage.setItem('searchedVal', '');
                    }
                }
                else {
                    fetchData(data.UserName, -1, '-1', -1);
                }
                fetchNotices();
                setUsername(data.UserName);
                setCompPermission(data.RoleLabel);
                setReadType(data.ReadType);
                ShowHideDeleteButton();
            });
    };
    const getUserID = () => {
        fetch('api/Users/GetEDCUserID')
            .then(response => response.text())
            .then(data => {
                setEDCUserID(data);
            });
    };
    const setCompPermission = (usrRole) => {
        usrRole = usrRole.trim();
        if (usrRole == "Read-Only")
            setUserPermisssion("RO")
        else if (usrRole == "Database Administrator")
            setUserPermisssion("RO")
        else if (usrRole == "Clinical Project Manager")
            setUserPermisssion("RO")
        else if (usrRole == "Auditor")
            setUserPermisssion("RO")
        else if (usrRole == "Tester")
            setUserPermisssion("RW")
        else if (usrRole == "Management, Image Services" || usrRole == "Image Services Coordinator")
            setUserPermisssion("RW")
        else if (usrRole == "Clinical Data Manager" || usrRole == "Manager, Clinical Data Management") 
            setUserPermisssion("RO")
        else 
            setUserPermisssion("RO")
    }
    const fetchData = (UserName, taskStageId, studyId, sourceTypeId) => {
        fetch('api/ImageService/GetTasks?UserName=' + UserName + '&taskStageId=' + taskStageId + '&studyId=' + studyId + '&sourceTypeId=' + sourceTypeId)
            .then(response => response.json())
            .then(data => {
                setData(data);
            });
    }
    //const UnLockHandler = (taskID) => {       
    //    RemoveTaskLock(taskID);
    //}
    const selectRef = useRef('');
    const [q, setQ] = useState('');
    const [inputVal, setInputVal] = useState('');    
    
    const columns = [
        {
            name: "",
            selector: row => row.IST,
            cellExport: row => row.IST,
            width: "2%",
            style: { "color": "red" },
            export:false

        },
        {
            name: "Action",
            button: true,
            className: 'headercaption',
            width: '4%',
            style: {
                'border-right-style': 'solid',
                'border-width': '1px',
                'border-color': 'rgba(0, 0, 0, 0.12)',
            },
            cell: (row) => (
                <div style={{ "padding": "5px" }}>
                    {row.Status.toLowerCase() === "open" && <img className={classes.act_icon}
                        onClick={e => TaskDetailsHandler(row.TaskID)} src={InprogressIcon} alt="Inprogress " />}  
                    {(row.Status.toLowerCase() === "locked" && row.LockedBy == edcUserID) && <img className={classes.act_icon} src={LockIcon} alt="Locked" onClick={e => TaskDetailsHandler(row.TaskID)} />}
                    {(row.Status.toLowerCase() === "locked" && row.LockedBy != edcUserID) && <img className={classes.act_icon} src={LockIcon} alt="Locked" onClick={e => TaskDetailsHandler_RO(row.TaskID, false)} />}
                </div>
            ),
            export: false
        },
        {
            name: "Task Id",
            selector: row => row.TaskID,
            cellExport: row => row.TaskID,
            sortable: true,
            center: true,
            width:"5%"
        },
        {
            name: "Stage",
            selector: row => row.Stage,
            cellExport: row => row.Stage,
            sortable: true,
            center: true,
            width: "10%",
            cell: (row) => (
                <div className={classes.textWrap}>{row.Stage}</div>
            )
        },
        {
            name: "Source",
            selector: row => row.SourceType,
            cellExport: row => row.SourceType,
            sortable: true,
            center: true,
            width: "7%"
        },
        {
            name: "Type",
            selector: row => row.Type,
            cellExport: row => row.Type,
            sortable: true,
            center: true,
            width: "5%",
            omit: true
        },
        {
            name: "Status",
            selector: row => row.Status,
            cellExport: row => row.Status,
            sortable: true,
            center: true,
            width: "6%",
            omit : true
        },
        {
            name: "Study",
            selector: row => row.Study,
            cellExport: row => row.Study,
            sortable: true,
            center: true,
            width: "17%",
            cell: (row) => (
                <div className={classes.textWrap}>{row.Study}</div>
            )
        },
        {
            name: "Protocol",
            selector: row => row.Protocol,
            cellExport: row => row.Protocol,
            sortable: true,
            center: true,
            omit: true
        },
        {
            name: "Site",
            selector: row => row.Site,
            cellExport: row => row.Site,
            sortable: true,
            center: true,
            width: "7%"
        },
        {
            name: "Subject Id",
            selector: row => row.SubjectID,
            cellExport: row => row.SubjectID,
            sortable: true,
            center: true,
            width: "11%",
            cell: (row) => (
                <div className={classes.textWrap}>{row.SubjectID}</div>
            )
        },
        {
            name: "Procedure Id",
            selector: row => row.ProcedureID,
            cellExport: row => row.ProcedureID,
            sortable: true,
            center: true,
            width:  '12%'
        },
        {
            name: "Visit",
            selector: row => row.Visit,
            cellExport: row => row.Visit,
            sortable: true,
            center: true,
            width: '7%'
        },        
        {
            name: "Upload Date",
            selector: row => row.ProcedureDate,
            cellExport: row => row.ProcedureDate,
            sortable: true,
            center: true,
            width: '13%',
            sortFunction: (a, b) => { return props.sortByDateTime(a.ProcedureDate, b.ProcedureDate); }
        },
        {
            name: "Due",
            selector: row => row.Due,
            cellExport: row => row.Due,
            sortable: true,
            center: true,
            omit: true
        },
        {
            name: "Hrs Remaining",
            selector: row => row.HrsRemaining,
            cellExport: row => row.HrsRemaining,
            sortable: true,
            center: true,
            width: '8%',
            omit : true
        }, 
        {
            name: "LockedBy",
            selector: row => row.LockedBy,
            cellExport: row => row.LockedBy,
            omit : true
        }
    ]; 

    function search(rows) {
        const columns = rows[0] && Object.keys(rows[0]);
        if (q != '' && q != null) {
            let selString = q.toString();
            let searchText = selString.toLowerCase().split(",");
            return rows.filter((row) => {
                return columns.some((column) => {
                    if (row[column] != null) {
                        if (searchText.length > 1)
                            return searchText.indexOf(row[column].toString().toLowerCase()) > -1
                        else
                            return row[column].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
                    }
                })
            })
        }
        else
            return rows;
    }
    const searchChangeHandler = (e) => {      
        localStorage.setItem('searchedVal', JSON.stringify({ filterId: document.getElementById('ddlFilterOptions').value, text: e.target.value, study: document.getElementById('ddlStudy').value, taskStageId: document.getElementById('ddlStage').value, sourceTypeId: document.getElementById('ddlSourceType').value }));
        setQ(e.target.value);
        setInputVal(e.target.value);
        selectRef.current.resetSelectedValues();
    }

    const conditionalRowStyles = [
        {
            when: row => row.HrsRemaining < 0,
            style: {
                color: 'Red'
            },
        }
    ];

    const status = ["OPEN", "LOCKED"];
    const PreventFilters = () => {
        localStorage.setItem('searchedVal', JSON.stringify({ filterId: document.getElementById('ddlFilterOptions').value, text: inputVal, study: document.getElementById('ddlStudy').value, taskStageId: document.getElementById('ddlStage').value, sourceTypeId: document.getElementById('ddlSourceType').value }));
    };

    const TaskDetailsHandler = (taskId) => {
        PreventFilters();
        if (userPermission != 'RO')
            AddTaskLock(taskId);
        else {            
            navigate('/ImgTaskDetails',
                {
                    state: {
                        UserPermission: userPermission,
                        canUnlok: true,
                        taskId: taskId
                    }
                });
        }
    }
    const TaskDetailsHandler_RO = (taskId, flag) => {
        PreventFilters();
        navigate('/ImgTaskDetails',
            {
                state: {
                    UserPermission: 'RO',
                    canUnlok: flag,
                    taskId: taskId
                }
            });
    }
    const DeleteNotice = (NoticeID, event) => {
    //    var delConfirm = confirm('Are you sure, you want to delete the item?');
    //    if (delConfirm) {
    //        let arr = [];
    //        arr = notice1.filter((row) => {
    //            return row.NoticeID != NoticeID
    //        });
    //        setRows(arr);
    //        readData.current = arr;
    //    }
    };
    function getDateTime() {
        var now = new Date();
        var year = now.getFullYear();
        var month = now.getMonth() + 1;
        var day = now.getDate();
        var hour = now.getHours();
        var minute = now.getMinutes();
        var second = now.getSeconds();
        if (month.toString().length == 1) {
            month = '0' + month;
        }
        if (day.toString().length == 1) {
            day = '0' + day;
        }
        if (hour.toString().length == 1) {
            hour = '0' + hour;
        }
        if (minute.toString().length == 1) {
            minute = '0' + minute;
        }
        if (second.toString().length == 1) {
            second = '0' + second;
        }
        var dateTime = year + '/' + month + '/' + day + ' ' + hour + ':' + minute + ':' + second;
        return dateTime;
    }
    const AddTaskLock = (taskId) => {
        var currentTime = getDateTime();       
        fetch('api/ImageService/AddTaskLock?TaskID=' + taskId + '&LockedDate=' + currentTime, { method: "POST"})
            .then(response => response.json())
            .then(data => {
                navigate('/ImgTaskDetails',
                    {
                        state: {
                            UserPermission: userPermission,
                            canUnlok: true,
                            taskId: taskId
                        }
                    });
            });
    }
    //const RemoveTaskLock = (taskId) => {
    //    fetch('api/ImageService/RemoveTaskLock?TaskID=' + taskId, { method: "POST" })
    //        .then(response => response.json())
    //        .then(data => {
    //            fetchData();
    //        });
    //}
    const fetchNotices = (studyId) => {
        if (typeof studyId == 'undefined') {
            studyId = -1;
        }
        fetch('api/ImageService/GetNotices?studyId=' + studyId)
            .then(response => response.json())
            .then(data => {
                setNotice(data);
            });
    }
   
    const readTypes = [
        {
            Name: "Select Type",
            Value: ""
        },
        {
            Name: "Endo",
            Value: "Endo"
        },
        {
            Name: "Histo",
            Value: "Histo"
        }]

    const FillTaskStageList = () => {
        fetch('api/ImageService/FillTaskStageList')
            .then(response => response.json())
            .then(data => {
                setTaskStage(data);
            });
    }

    const FillSourceTypesList = () => {
        fetch('api/ImageService/FillSourceTypesList')
            .then(response => response.json())
            .then(data => {
                setSourceType(data);
            });
    }

    const FillFilterOptionList = (filterId) => {
        fetch('api/ImageService/FillFilterOptionList?FilterFor=IMGDashboard')
            .then(response => response.json())
            .then(data => {
                setFilterOptions(data);
                if (typeof filterId != 'undefined') {
                    localStorage.setItem('searchedVal', JSON.stringify({ filterId: filterId, text: inputVal, study: document.getElementById('ddlStudy').value, taskStageId: document.getElementById('ddlStage').value, sourceTypeId: document.getElementById('ddlSourceType').value }));
                    setFilterId(filterId);
                    var e = document.getElementById("ddlFilterOptions");
                    setFilterName(e.options[e.selectedIndex].text);
                }
                ShowHideDeleteButton();
            });
    }

    const taskStageChangeHandler = (e) => {
        var e = document.getElementById("ddlFilterOptions");
        setFilterName(e.options[e.selectedIndex].text);
        setTaskStageId(document.getElementById('ddlStage').value);
        localStorage.setItem('searchedVal', JSON.stringify({ filterId: document.getElementById('ddlFilterOptions').value, text: inputVal, study: document.getElementById('ddlStudy').value, taskStageId: document.getElementById('ddlStage').value, sourceTypeId: document.getElementById('ddlSourceType').value  }));
        fetchData(username, document.getElementById('ddlStage').value, document.getElementById('ddlStudy').value, document.getElementById('ddlSourceType').value);
    }

    const sourceTypeChangeHandler = (e) => {
        var e = document.getElementById("ddlFilterOptions");
        setFilterName(e.options[e.selectedIndex].text);
        setSourceTypeId(document.getElementById('ddlSourceType').value);
        localStorage.setItem('searchedVal', JSON.stringify({ filterId: document.getElementById('ddlFilterOptions').value, text: inputVal, study: document.getElementById('ddlStudy').value, taskStageId: document.getElementById('ddlStage').value, sourceTypeId: document.getElementById('ddlSourceType').value }));
        fetchData(username, document.getElementById('ddlStage').value, document.getElementById('ddlStudy').value, document.getElementById('ddlSourceType').value);
    }

    const studyChangeHandler = (e) => {
        var e = document.getElementById("ddlFilterOptions");
        setFilterName(e.options[e.selectedIndex].text);
        setStudyId(document.getElementById('ddlStudy').value);
        localStorage.setItem('searchedVal', JSON.stringify({ filterId: document.getElementById('ddlFilterOptions').value, text: inputVal, study: document.getElementById('ddlStudy').value, taskStageId: document.getElementById('ddlStage').value, sourceTypeId: document.getElementById('ddlSourceType').value  }));
        fetchData(username, document.getElementById('ddlStage').value, document.getElementById('ddlStudy').value, document.getElementById('ddlSourceType').value);
    }

    const filterChangeHandler = (e) => {
        if (e.target.value == -1) {
            setFilterId(-1);
            setStudyId('-1');
            setTaskStageId(-1);
            setSourceTypeId(-1);
            setInputVal('');
            setQ('');
            localStorage.setItem('searchedVal', JSON.stringify({ filterId: -1, text: '', study: '-1', taskStageId: -1, sourceTypeId: -1 }));
            fetchData(username, -1, '-1', -1);
            setFilterName('');
           // setIsDisabled(false);
        }
        else {
            setFilterName(e.target.options[e.target.selectedIndex].text);
            setFilterId(e.target.value);
            FetchFilterOptionsDetails(e.target.value);
            //setIsDisabled(true);
        }
        ShowHideDeleteButton();
    }

    const FetchFilterOptionsDetails = (filterId) => {
        fetch('api/ImageService/GetFilterOptionsDetails?filterId=' + filterId)
            .then(response => response.json())
            .then(data => {
                SetFilterOptionsDetail(data);
            });
    }

    function SetFilterOptionsDetail(data) {
        localStorage.setItem('searchedVal', JSON.stringify({ filterId: document.getElementById('ddlFilterOptions').value, text: data.SearchText, study: data.StudyId, taskStageId: data.StageId, sourceTypeId: data.SourceTypeId }));
        setStudyId(data.StudyId);
        setTaskStageId(data.StageId);
        setSourceTypeId(data.SourceTypeId);
        setInputVal(data.SearchText);
        setQ(data.SearchText);
        fetchData(username, data.StageId, data.StudyId, data.SourceTypeId);
    }

    const studyFilterChangeHandler = (e) => {
        setNoticeStudyId(e.target.value);
        fetchNotices(e.target.value);
    }

    const fillStudyListByEDCUserId = () => {
        fetch('api/ImageService/FillDashboardStudyList')
            .then(response => response.json())
            .then(data => {
                setUserStudies(data);
            });
    }

    const DeleteFilterHandler = (event) => {
        var fiDDL = document.getElementById("ddlFilterOptions");
        var fiId = fiDDL.value;
        var fiName = fiDDL.options[fiDDL.selectedIndex].text;

        var con = confirm('Are you sure, you want to delete "' + fiName + '" filter?');
        if (con == true) {
            if (fiId == -1) {
                alert('Please check the filter selection.');
                fiDDL.focus();
                return false;
            }

            let objFilterOptionsModel = {
                FilterId: filterId,
                Name: filterName,
                StudyId: studyId,
                SourceTypeId: sourceTypeId,
                SearchText: inputVal,
                FilterFor: "delete"
            };

            fetch('api/ImageService/SaveFilterOptions', {
                method: 'POST',
                headers: { 'Content-type': 'application/json' },
                body: JSON.stringify(objFilterOptionsModel)
            }).then(r => r.json()).then(res => {
                if (res == -1 || res == -2) {
                    alert('Filter options not deleted, Something went wrong.');
                }
                if (res > 0) {
                    setFilterId(-1);
                    setStudyId('-1');
                    setSourceTypeId(-1);
                    setInputVal('');
                    setQ('');

                    localStorage.setItem('searchedVal', JSON.stringify({ filterId: -1, study: -1, sourceTypeId: -1 }));
                    FillFilterOptionList();
                    setFilterName('');
                }
            })
        }
    }

    function ShowHideDeleteButton() {
        let selFilterVal = document.getElementById('ddlFilterOptions').value;
        if (selFilterVal == -1 || selFilterVal == '') {
            document.getElementById('btnDelete').style.display = 'none';
        }
        else {
            document.getElementById('btnDelete').style.display = '';
        }
    }

    return (
        <Card>
            {
                <div>
                    <SelectList id='ddlStudyFilter' dataSource={arrUserStudies} onChangeHandler={studyFilterChangeHandler} style={{marginBottom:"10px", width:"20%"}} />
                <InputContainer border='true' header="Image Services Notices" Expended="true">
                {
                            notice.map((item, index) => {
                                return <div key={index}>
                                    <Notice noticeId={item.NoticeID} notice={item.NoticeText} postedBy={item.PostedBy} createdDate={item.CreationDate} fetchNotices={fetchNotices} DeleteNotice={DeleteNotice} UserPermission={userPermission} noticeStudyId={noticeStudyId} studyName={item.StudyName} />
                                </div>
                    })}
                    </InputContainer>
                    </div>
            }
            <div style={{ textAlign: "right", marginTop: "10px" }}>
                {userPermission == "RW" && <NoticeModal btn="true" text="ADD ALERTS" noticeId="0" fetchNotices={fetchNotices} noticeStudyId={noticeStudyId} /> }
            </div>

            <Row className="mt-2 mb-2" >
                <Col md={2}>
                    <SelectList id='ddlFilterOptions' dataSource={arrFilterOptions} onChangeHandler={filterChangeHandler} value={filterId} />
                </Col>
                <Col md={2}>
                    <SelectList id='ddlStudy' dataSource={arrUserStudies} onChangeHandler={studyChangeHandler} value={studyId}/>
                </Col>
                <Col md={2}>
                    <SelectList id='ddlStage' dataSource={arrTaskStage} onChangeHandler={taskStageChangeHandler} value={taskStageId} />
                </Col>
                <Col md={1}>
                    <SelectList id='ddlSourceType' dataSource={arrSourceType} onChangeHandler={sourceTypeChangeHandler} value={sourceTypeId} />
                </Col>
                <Col md={2}>
                    <div className="input-group" style={{ "float": "right" }}>
                        <InputString id="txtSearch" value={inputVal} onChangeHandler={searchChangeHandler} placeholder="Search" />
                    </div>
                </Col>
                <Col md={3}>
                    <div style={{ "float": "right" }}>
                        <InputTextModal btn="true" text="Save" studyId={studyId} taskStageId={taskStageId} sourceTypeId={sourceTypeId} searchText={inputVal} filterId={filterId} filterName={filterName} fillFilterOptionList={FillFilterOptionList} filterFor="IMGDashboard" />
                        <Button id="btnDelete" className="btn btn-md btn-danger" style={{ marginLeft: "2px" }} onClick={DeleteFilterHandler}>Delete</Button>
                        &nbsp;<AddManualTaskModal btn="true" text="Save" studyId={studyId} userName={username} sourceTypeId={sourceTypeId} taskStageId={taskStageId} fetchData={fetchData} navigateFrom="dashboard" />
                    </div> 
                </Col>
            </Row>

            
            <div className="tableContainer ">
                {search(rowData).length > 0 ?
                    <DataTableExtensions columns={columns} data={search(rowData)} filter={false} fileName="ImageServiceDashboard">
                        <DataTable
                            className="table table-striped table-bordered table-hover" 
                            conditionalRowStyles={conditionalRowStyles}
                            customStyles={customStyles}
                            striped
                            pagination
                            paginationPerPage={50}
                            paginationRowsPerPageOptions={[20, 50, 100]}
                        />
                    </DataTableExtensions>: <NoData headers={columns}/> }
            </div>
        </Card>
    );

};

export default Dashboard;
