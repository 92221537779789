import React from 'react'
import { Row } from 'react-bootstrap'
import { Col } from 'react-bootstrap'
import InputNumber from '../../components/UI/Control/InputNumber'
import InputContainer from '../../components/UI/Container/InputContainer'
import MandatoryLabel from '../UI/Control/MandatoryLabel';

const ParadigmInitialStage = (props) => {
    return (
        <InputContainer Expended='true' border='true' header={props.title}>          
            <Row style={{ "margin-bottom": "25px", "margin-top": "25px"  }}>
                <Col md={5} style={{ "paddingLeft": "35px" }}># of LR<MandatoryLabel></MandatoryLabel>:</Col>
                <Col md={6}><InputNumber id="txtNoOfLR" disabled={props.disabled} /></Col>
            </Row>
            <Row style={{ "margin-bottom": "25px" }}>
                <Col md={5} style={{ "paddingLeft": "35px" }}># of CR<MandatoryLabel></MandatoryLabel>:</Col>
                <Col md={6}><InputNumber id="txtNoOfCR" disabled={props.disabled} /></Col>
            </Row>
        </InputContainer>
    );
}

export default ParadigmInitialStage;
