import React from 'react';
import { Row, Col } from 'react-bootstrap';
import classes from './../TrialstatNoto.module.css';
import EmailDetailsModal from './EmailDetailsModal';
const TaskHistory = (props) => {
    return (
        <div style={{ marginLeft: "12px", marginRight: "12px", marginTop: "0px" }}>
            {props.historyData.map((item, index) => {
                return (<div key={index}>
                    <Row style={{ "height": "40px" }}>
                        <Col className={classes.cellBorder} style={{ "display": "inline-flex", borderRight: "none" }} md={2}>
                            <div style={{marginRight: "50px" }}><b>User ID:</b></div>
                            {item.UserID}
                        </Col>
                        <Col className={classes.cellBorder} style={{ "display": "inline-flex", borderLeft: "none" }}>
                            <div style={{ marginLeft: "150px", marginRight: "50px" }}><b>Date:</b></div>
                            {item.Date}
                        </Col>
                    </Row>
                    <Row style={{ minHeight: "40px", "height": "auto" }}>
                        <Col className={classes.cellRLBBorder} md={2}>Task Audit</Col>
                        {item.IsEmailLogAvailable == 'yes' && <Col className={classes.cellRightBorder}>{item.TaskAudit}
                            <br />
                            <EmailDetailsModal style={{ "float": "right", "display": "inline-block", marginLeft: "10px" }} text="Email Details" taskId={item.TaskID} taskActionLogID={item.TaskActionLogID} /> </Col>}
                        {item.IsEmailLogAvailable == 'no' && <Col className={classes.cellRightBorder}>{item.TaskAudit}</Col>}
                    </Row>
                    <Row style={{ marginBottom: "12px", minHeight:"40px", "height":"auto"}}>
                        <Col className={classes.cellRLBBorder} md={2}>Task Comments</Col>
                        <Col className={classes.cellRightBorder}>{item.Comment}</Col>
                    </Row> 
                </div>);
            })}
        </div>
    );
}

export default TaskHistory;