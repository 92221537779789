import React, { useState, useEffect, useRef } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import NoReadAssigned from './NoReadAssigned';
import AssignReadByUser from './AssignedReadByUser';
import TextArea from '../UI/Control/InputTextarea';
import { useSelector } from 'react-redux'
import MandatoryLabel from '../UI/Control/MandatoryLabel';
import { useNavigate } from 'react-router-dom';
import SelectList from '../UI/Control/SelectList';

const AssignRead = (props) => {
    const navigate = useNavigate();
    const [username, setUername] = useState('');
    const EDCUserID = useSelector(state => state.auth.gValue01);
    const [arrNoOfRead, setNoofRead] = useState([]);
    const [arrSiteCode, setArrSiteCode] = useState([]);
    const [arrVisit, setArrVisit] = useState([]);
    const [siteCode, setSiteCode] = useState('');
    const [apiLink, setAPILink] = useState(''); //Visit short name

    const AssignReader = (e) => {
        
        if (props.Type.toLowerCase() == 'lucidity') {
            const objSite = document.getElementById('selectSite');
            if (objSite.value == '') {
                alert('Please select center.');
                objSite.focus();
                return false;
            }
        }

        if (props.Type.toLowerCase() == 'lucidity') {
            const objVisit = document.getElementById('selectVisit');
            if (objVisit.value == '') {
                alert('Please select visit.');
                objVisit.focus();
                return false;
            }
        }

        props.setActionType("AssignReader");
        props.setReadStage(e.target.getAttribute("readStage"));
    }

    const ChangeReader = (e) => {
        props.setActionType("ChangeReader");
        props.setReadAssignmentID(e.target.getAttribute("readAssignmentID"));
    }

    const OnRemoveAssignedReader = (e) => {
        let objReadAssignmentsModel = {
            TaskID: props.taskId,
            ReadAssignmentID: e.target.getAttribute("readAssignmentID"),
        };

        fetch('api/ImageService/RemoveAssignedReader', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(objReadAssignmentsModel)
        }).then(r => r.json()).then(res => {
            if (res == -1 || res == 0) {
                alert('Assigned reader not removed, Something went wrong.');
            }
            if (res == 1) {
                props.relaodAction();
                //props.setActionType("Assign Read");
            }
        })
    };

    const fetchNoOfReades = () => {
        fetch('api/ImageService/GetNoOfReads?taskId=' + props.taskId)
            .then(response => response.json())
            .then(data => {
                let arr = [];
                let readCount = 0;
                let NoofLocalRead = 0;
                for (var iCount = 0; iCount < NoofLocalRead; iCount++) {
                    readCount = readCount + 1;
                    arr.push({
                        ReadID: readCount,
                        ReadHeader: "Local Reader " + readCount,
                        Label: "Assigned Reader:",
                        ReaderStatus: "No Reader Assigned",
                        Assigned: false,
                    });
                }
                for (var iCount = 0; iCount < data.listCentralReaders.length; iCount++) {
                    readCount = readCount + 1;
                    arr.push({
                        ReadID: readCount,
                        ReadHeader: "Central Reader " + readCount,
                        Label: "Assigned Reader:",
                        ReaderStatus: "No Reader Assigned",
                        Assigned: data.listCentralReaders[iCount].Status.toLowerCase() == 'assigned' ? true : false,
                        ReadAssignmentID: data.listCentralReaders[iCount].ReadAssignmentID
                    });
                }
                setNoofRead(arr);
            });
    }

    const completeAssignRead = () => {
        let objNote = document.getElementById('AssignReadNote');
        //if (objNote.value.trim() == '') {
        //    alert('Notes should not be empty.');
        //    objNote.focus();
        //    return false;
        //}

        let objTaskActionLogModel = {
            TaskID: props.taskId,
            TaskComments: objNote.value,
            Username: EDCUserID
        };

        fetch('api/ImageService/CompleteAssignRead', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(objTaskActionLogModel)
        }).then(r => r.text()).then(res => {           
            var arrData = res.split('_');
            if (arrData[0] == "-1") {
                alert('Read assignment not completed, Something went wrong.');
            }
            else if (arrData[1] == "SUCCESS") {
                //postAdjDataToEDC();
                props.relaodAction();
            }
            else if (arrData[1] != "SUCCESS") {
                alert("Read assignment completed but there was an error while submitting the CRF information form.")
                props.relaodAction();
            }
        })
    }
    //const postAdjDataToEDC = async () => {
    //    const response = await fetch('api/ServiceCall/PostReadersData?TaskId=' + props.taskId + '&UserId=' + UserID,
    //        {
    //            method: "POST",
    //            mode: 'cors'
    //        }
    //    )
    //    if (!response.ok) {
    //        throw new Error("Something went wrong, message :" + response.message + " \nHttp Error:" + response.httpError)
    //    }
    //}
    function getUserName() {
        fetch('api/Users/GetUserNameByID?userID=' + EDCUserID)
            .then(response => response.text())
            .then(username => {
                setUername(username);
            });
    }

    const FillSiteCodeList = () => {
        fetch('api/ImageService/FillSiteCodeList?taskId=' + props.taskId)
            .then(response => response.json())
            .then(data => {
                let arr = [{ Value: '', Name: '--Select Center--' }]
                for (var iCount = 0; iCount < data.length; iCount++) {
                    arr.push({ Value: data[iCount].Value, Name: data[iCount].Name });
                }
                setArrSiteCode(arr);
            });
    }

    const FillVisitList = () => {
        fetch('api/ImageService/FillVisitList?taskId=' + props.taskId)
            .then(response => response.json())
            .then(data => {
                let arr = [{ Value: '', Name: '--Select Visit--' }]
                for (var iCount = 0; iCount < data.length; iCount++) {
                    arr.push({ Value: data[iCount].Value, Name: data[iCount].Name });
                }
                setArrVisit(arr);
            });
    }

    const GetExternalSiteAndVisit = () => {
        fetch('api/ImageService/GetExternalSiteAndVisit?taskId=' + props.taskId)
            .then(response => response.json())
            .then(data => {
                setSiteCode(data.SiteCode);
                setAPILink(data.APILink);
            });
    }

    const slectCenterChangeHandler = (e) => {
        let siteCodeName = '';
        const objSite = document.getElementById('selectSite');

        siteCodeName = objSite.value;
        if (siteCodeName == '') {
            alert('Invalid selection.');
            objSite.value = siteCode;
            return false;
        }

        let objTaskActionLogModel = {
            TaskID: props.taskId,
            SiteCode: siteCodeName,
            APILink: ''
        };

        fetch('api/ImageService/updateExternalSiteAndVisit', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(objTaskActionLogModel)
        }).then(r => r.text()).then(res => {
            var arrData = res.split('_');
            if (res != "1") {
                alert("External center not saved successfully, Something went wrong.")
                return false;
            }
            fetchNoOfReades();
        });
    };

    const slectVisitChangeHandler = (e) => {        
        let selAPILink = '';
        const objVisit = document.getElementById('selectVisit');

        selAPILink = objVisit.value;
        if (selAPILink == '') {
            alert('Invalid selection.');
            objVisit.value = apiLink;
            return false;
        }

        let objTaskActionLogModel = {
            TaskID: props.taskId,
            SiteCode: '',
            APILink: selAPILink
        };

        fetch('api/ImageService/updateExternalSiteAndVisit', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(objTaskActionLogModel)
        }).then(r => r.text()).then(res => {
            var arrData = res.split('_');
            if (res != "1") {
                alert("External visit not saved successfully, Something went wrong.")
                return false;
            }
            fetchNoOfReades();
        });
    };

    useEffect(() => {
        GetExternalSiteAndVisit();
        getUserName();
        fetchNoOfReades();
        FillSiteCodeList();
        FillVisitList();
    }, []);

    return (
        <div style={{ marginTop: "15px", position: "relative" }}>
            {arrNoOfRead.length > 0 && props.Type.toLowerCase() != "cims" && <Row style={{ marginBottom: "10px" }}>
                <Col md={1}>
                    Center<MandatoryLabel></MandatoryLabel>:
                </Col>
                <Col md={3}>
                    <SelectList id="selectSite" dataSource={arrSiteCode} onChangeHandler={slectCenterChangeHandler} selectedData={siteCode} ></SelectList>
                </Col>
                <Col md={6}>
                    {arrNoOfRead.length > 0 && props.Type.toLowerCase() != "cims" && <Row>
                        <Col md={1}>
                            Visit<MandatoryLabel></MandatoryLabel>:
                        </Col>
                        <Col md={5}>
                            <SelectList id="selectVisit" dataSource={arrVisit} onChangeHandler={slectVisitChangeHandler} selectedData={apiLink}  ></SelectList>
                        </Col>
                    </Row>}
                </Col>
            </Row>
            }
            {
                arrNoOfRead.map((item, index) => {
                    {
                        return item.Assigned ?
                            <AssignReadByUser ReadByUserData={props.ReadByUserData} onChangeReader={ChangeReader} taskId={props.taskId} readStage={(index + 1)}
                                readAssignmentID={item.ReadAssignmentID} OnRemoveAssignedReader={OnRemoveAssignedReader} isURLEnabled={props.isURLEnabled} /> :
                            <NoReadAssigned dataSource={item} ID={item.ReadID} onAssignReader={AssignReader} AssinToReaderHandler={false} readStage={(index +1)} />
                    }
                })
            }          
            
            {arrNoOfRead.length > 0 && <Row style={{margin:"5px"}}>
                <Col md={1}>Notes:</Col>
                <Col md={10}>
                    <TextArea rows='5' cols='100' id="AssignReadNote" />
                </Col>
                <Col md={1}>
                    <Button style={{ marginTop: "90px", position: "relative" }} className="btn-success" onClick={completeAssignRead}
                        disabled={arrNoOfRead.length == arrNoOfRead.filter(function (obj) { return obj.Assigned == true }).length ? '' : 'disabled'}>
                        Submit
                        </Button>
                </Col>
            </Row>}
        </div>
    );
}
export default AssignRead;
