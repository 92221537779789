import React, { useState, useEffect, useRef } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import NoReviewAssigned from './NoReviewAssigned';
import AssignedReviewByUser from './AssignedReviewByUser';
import TextArea from '../UI/Control/InputTextarea';
import { useSelector } from 'react-redux'

const AssignReview = (props) => {
    const [username, setUername] = useState('');
    const EDCUserID = useSelector(state => state.auth.gValue01);
    const [arrNoOfReview, setNoofReview] = useState([]);

    const AssignReviewer = (e) => {
        props.setActionType("AssignReviewer");
        props.setReviewStage(e.target.getAttribute("reviewStage"));
    }

    const ChangeReviewer = (EDCUserID, reviewStage, e) => {
        let objReviewerNote = document.getElementById('AssignReviewNote');
        let objReadAssignmentsModel = {
            ReadAssignmentID: e.target.getAttribute("reviewAssignmentID"),
            AssignedReader: EDCUserID,
            ReaderNote: objReviewerNote.value,
            TaskID: props.taskId
        };

        fetch('api/ImageService/UpdateReviewAssignments', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(objReadAssignmentsModel)
        }).then(r => r.json()).then(res => {
            if (res == -1) {
                alert('Changing reviewer not done, Something went wrong.');
            }
            if (res > 0) {
                props.setReviewerNote(objReviewerNote.value);
                props.relaodAction();
            }
        })
        props.setActionType("ChangeReviewer");
        props.setReviewAssignmentID(e.target.getAttribute("reviewAssignmentID"));
    }

    const OnRemoveAssignedReviewer = (e) => {
        let objReadAssignmentsModel = {
            TaskID: props.taskId,
            ReadAssignmentID: e.target.getAttribute("reviewAssignmentID"),
        };

        fetch('api/ImageService/RemoveAssignedReviewer', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(objReadAssignmentsModel)
        }).then(r => r.json()).then(res => {
            if (res == '-1' || res == '0') {
                alert('Assigned reviewer not removed, Something went wrong.');
            }
            if (res == '1') {
                props.relaodAction();
                props.setActionType("Assign Review");
            }
        })
    };

    const fetchNoOfReviews = () => {
        fetch('api/ImageService/GetNoOfReviews?taskId=' + props.taskId)
            .then(response => response.json())
            .then(data => {
                let arr = [];
                let reviewCount = 0;
                let NoofLocalReview = 0;
                for (var iCount = 0; iCount < NoofLocalReview; iCount++) {
                    reviewCount = reviewCount + 1;
                    arr.push({
                        ReviewID: reviewCount,
                        ReviewHeader: "Local Reviewer " + reviewCount,
                        Label: "Assigned Reviewer:",
                        ReviewerStatus: "No Reviewer Assigned",
                        Assigned: false,
                        ReviewerAssigned : false
                    });
                }
                for (var iCount = 0; iCount < data.listCentralReviewers.length; iCount++) {
                    reviewCount = reviewCount + 1;
                    arr.push({
                        ReaderType: data.listCentralReviewers[iCount].ReaderType,
                        ReviewID: reviewCount,
                        ReviewHeader: "Central Reviewer " + reviewCount,
                        Label: "Assigned Reviewer:",
                        ReviewerStatus: "No Reviewer Assigned",
                        Assigned: data.listCentralReviewers[iCount].Status.toLowerCase() == 'assigned' ? true : false,
                        ReviewAssignmentID: data.listCentralReviewers[iCount].ReviewAssignmentID,
                        ReviewerAssigned: (data.listCentralReviewers[iCount].ReadStatusID == '7' || data.listCentralReviewers[iCount].ReadStatusID == '9') ? true : false
                    });
                }
                setNoofReview(arr);
            });
    }

    const completeAssignReview = () => {
        let objNote = document.getElementById('AssignReviewNote');
        let objTaskActionLogModel = {
            TaskID: props.taskId,
            TaskComments: objNote.value,
            Username: EDCUserID
        };

        fetch('api/ImageService/CompleteAssignReview', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(objTaskActionLogModel)
        }).then(r => r.text()).then(res => {
            if (res == "-1") {
                alert('Review assignment not completed, Something went wrong.');
            }
            else if (res == "1") {
                props.relaodAction('yes');
            }
        })
    }
    
    function getUserName() {
        fetch('api/Users/GetUserNameByID?userID=' + EDCUserID)
            .then(response => response.text())
            .then(username => {
                setUername(username);
            });
    }

    useEffect(() => {
        getUserName();
        fetchNoOfReviews();
    }, []);

    const onChangeNote = (e) => {
        props.setReviewerNote(e.target.value);
    };

    return (
        <div style={{ marginTop: "15px", position: "relative" }}>
            {
                arrNoOfReview.map((item, index) => {
                    {
                        return <AssignedReviewByUser ReviewByUserData={props.ReviewByUserData} onChangeReviewer={ChangeReviewer} taskId={props.taskId} reviewStage={(index + 1)}
                            reviewAssignmentID={item.ReviewAssignmentID} OnRemoveAssignedReviewer={OnRemoveAssignedReviewer} assigned={item.Assigned} readerType={item.ReaderType}/>
                    }
                })
            }

            {arrNoOfReview.length > 0 && <Row style={{ margin: "5px" }}>
                <Col md={1}>Reader Notes:</Col>
                <Col md={10}>
                    <textarea className="form-control" rows="5" cols="100" id="AssignReviewNote" onChange={onChangeNote} style={{ "resize": "none" }} value={props.reviewerNote} />
                </Col>
                <Col md={1}>
                <Button style={{ marginTop: "90px", position: "relative" }} className="btn-success" onClick={completeAssignReview}
                        disabled={arrNoOfReview.filter(function (obj) { return obj.ReviewerAssigned == true  }).length != 0  ? '' : 'disabled'}>
                    Submit
                    </Button>
                </Col>
            </Row>}
        </div>
    );
}
export default AssignReview;
