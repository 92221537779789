import React, { useState, useEffect, useRef } from "react";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import DataTable from "react-data-table-component";
import Card from '../UI/Card/Card';
import InputString from '../UI/Control/InputString';
import customStyles from '../customStyles';
import NoData from '../NoData';
import SelectList from '../UI/Control/SelectList';
import DataTableExtensions from "../ExportTable/index";
import "../ExportTable/index.css";
import './../CustomDataTableExtensions.css';
import SubjectStatusModal from './SubjectStatusModal.js';

const SubjectStatusReport = (props) => {
    const [inputVal, setInputVal] = useState('');
    const [rowData, setRows] = useState([]);
    const [columns, setColumns] = useState([]); 
    const [fileName, setFileName] = useState('Site_SubjectStatusReport');
    const colum = new Array();
    const [filteredColumns, setfilteredColumns] = useState([]);
    const [studyID, setStudyID] = useState('');
    const [studyList, setStudyList] = useState([]);
        
    function search(rows) {
        let selString = inputVal.toString();
        let searchText = selString.toLowerCase().split(",");
        return rows.filter((row) => {
            return filteredColumns.some((column) => {
                if (searchText.length > 1) {
                    if (row[column] != null) {
                        return searchText.indexOf(row[column].toString().toLowerCase()) > -1
                    }
                }
                else {
                    if (row[column] != null) {
                        return row[column].toString().toLowerCase().indexOf(inputVal.toLowerCase()) > -1
                    }
                }
            })
        })
    }

    const searchChangeHandler = (e) => {       
        setInputVal(e.target.value);
    }
   
    const fetchSubjectStatusData = () => {        
        let searchColumn = new Array();
        fetch('api/Site/GetSubjectStatusData?studyID='+ studyID)
            .then(response => response.json())
            .then(data => {
                for (let column in data[0]) {
                    colum.push({ name: column, selector: row => row[column], cellExport: row => row[column], sortable: true, center: true });
                        searchColumn.push(column);
                }
                colum.push({
                    name: "Action",
                    button: true,
                    className: 'headercaption',
                    width: '150px',
                    style: {
                        'border-right-style': 'solid',
                        'border-width': '1px',
                        'border-color': 'rgba(0, 0, 0, 0.12)'
                    },
                   
                    cell: (row) => (
                        <div style={{ "padding": "5px" }}>
                            <SubjectStatusModal study={row.Study} subjectid={row.SubjectID} substatus={row.Status} header="Subject Status" btnText="Action" refreshsata={fetchSubjectStatusData} />
                        </div>
                    )
                });

                setColumns(colum);
                setRows(data);
                setfilteredColumns(searchColumn);
            });
    }

    const onStudyChangeHandler = (e) => {
        setInputVal("");
        setStudyID(e.target.value);
        fetchSubjectStatusData();
    }
    const fetchStudies = async () => {
        fetch('api/Site/GetStudiesByUser')
            .then(response => response.json())
            .then(data => {
                setStudyList(data);
                var selStudyID = document.getElementById("ddlStudies").value;
                setStudyID(selStudyID);
                fetchSubjectStatusData();
            });
    }

    useEffect(() => {
        fetchStudies();       
    }, [studyID]);

    return <Card>
        <Row style={{ marginBottom: "10px" }}>
            <Col md={2}>
                <SelectList id='ddlStudies' dataSource={studyList} onChangeHandler={onStudyChangeHandler} style={{ width: "300px" }} />
            </Col>
            <Col md={4}>
            </Col>
            <Col md={6}>
                <div className="input-group">
                    <InputString id="txtSearch" value={inputVal} onChangeHandler={searchChangeHandler} placeholder="Search"/>
                </div>
            </Col>
        </Row>
        <div className="tableContainer">
            {search(rowData).length > 0 ?
                <DataTableExtensions columns={columns} data={search(rowData)} filter={false} exportHeaders={true} fileName={fileName}>
                <DataTable
                columns={columns}
                data={search(rowData)}
                className="table table-striped table-bordered table-hover"
                customStyles={customStyles}
                striped
                pagination
                paginationPerPage={50}
                paginationRowsPerPageOptions={[20, 50, 100]}
                />
                </DataTableExtensions> : <NoData headers={columns}></NoData>}
        </div>
    </Card>
};

export default SubjectStatusReport;
