import React, { useState } from "react";
import Card from '../UI/Card/Card';
import VisitAdjudication from './VisitAdjudication';
import InputContainer from '../UI/Container/InputContainer';
import { Button, Modal} from 'react-bootstrap';
import classes from './../TrialstatNoto.module.css';

const AdjudicationSettings = (props) => {
    const [adjDetails, setAdjDetails] = useState([]);
    const [arrEDCeCRFs, setEDCeCRFsTemp] = useState([]);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        fetchAdjSettingDetail();
        fetchEDCFormsLib
        setShow(true);
    };

    const fetchAdjSettingDetail = () => {
        fetch('api/AdjudicationSettings/GetAdjSettingDetail?studyProtocolId=' + props.studyProtocolId)
            .then(response => response.json())
            .then(data => {
                setData(data);
            });
    }

    function setData(data) {
        if (data.listAdjGroup != null) {
            setAdjDetails(data.listAdjGroup);
        }
    }

    const fetchEDCFormsLib = async () => {
        const response = await fetch('api/ServiceCall/GetEDCFormLib')
        if (!response.ok) {
            throw new Error("Something went wrong, message :" + response.message + " \nHttp Error:" + response.httpError)
        }
        const edcForms = await response.text();
        let listForms = JSON.parse(edcForms);
        const arrEDCForms = new Array();
        for (var iCount = 0; iCount < listForms.length; iCount++) {
            arrEDCForms.push({ Name: listForms[iCount].FormName, Value: listForms[iCount].FormID })
        }
        setEDCeCRFsTemp(arrEDCForms);
    }

    return (  
    <>
            {props.action === "newAdjudication" && <Button variant="primary" className="btn btn-secondary" style={props.style} onClick={handleShow}> {props.text}  </Button>}
            <Modal centered show={show} onHide={handleClose} dialogClassName={classes.TSModalDailog_Twelve} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title style={{ textAlign: "center" }}><h5> {props.studyName + " - " + props.protocolVersion}</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card>
           
                        {adjDetails.map((gItem, index) => {
                            return <InputContainer key={index} Expended='true' border='false' header={gItem.GroupName} style={{ "background": "#f15e25"}}>
                                {
                                    gItem.listAdjVisits.map((vItem, idx) => {
                                        return <span key={index +'_'+idx} style={{ "marginBottom": "0px" }}>
                                            <VisitAdjudication fetchAdjSettingDetail={fetchAdjSettingDetail} arrEDCeCRFs={arrEDCeCRFs} disabled={props.disabled} dataSource={vItem.listAdjReadDetails} Visit={vItem.VisitShort} />
                                        </span>
                                    })}                
                </InputContainer>
            })
            }
                    </Card > 
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-md btn-danger" variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
       );
};

export default AdjudicationSettings;
