import React, { useState } from 'react';
import { Button, Modal, Row, Col } from 'react-bootstrap';
import InputString from '../UI/Control/InputString';
import SelectList from '../UI/Control/SelectList';
import MandatoryLabel from '../UI/Control/MandatoryLabel';
import classes from './../TrialstatNoto.module.css';
import { useNavigate } from 'react-router-dom';

const AddManualTaskModal = (props) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const [arrManualViewer, setManualViewer] = useState([]);
    const [arrMVStudy, setMVStudy] = useState([]);
    const [arrSite, setSite] = useState([]);
    const [arrVisit, setVisit] = useState([]);
    const [selMStudyId, setSelMStudyId] = useState([]);
    const [studyId, setStudyId] = useState('');
    const [sourceTypeId, setSourceTypeId] = useState(-1);
    const [visitId, setVisitId] = useState(-1);
    const [siteId, setSiteId] = useState(-1);
    const navigate = useNavigate();

    const handleShow = () => {
        
        ClearData();
        FillMVStudies();
        if (props.navigateFrom != 'dashboard') {
            fetchGetManualTaskDetails();
        }
        setShow(true);
    }

    const fetchGetManualTaskDetails = () => {
        fetch('api/ImageService/GetManualTaskDetails?taskId=' + props.taskId)
            .then(response => response.json())
            .then(data => {
                FillManualViewer(data.StudyId);
                FillSites(data.StudyId);
                FillVisits(data.StudyId, data.SourceTypeId);
                setStudyId(data.StudyId);
                setSourceTypeId(data.SourceTypeId);
                setSiteId(data.SiteId);
                setVisitId(data.VisitId);
                document.getElementById("txtSubject").value = data.SubjectId;
                //document.getElementById("txtURL").value = data.TaskURL;

                document.getElementById("ddlManualStudy").disabled = true;
                document.getElementById("ddlManualViewer").disabled = true;
            });
    }

  

    const FillMVStudies = () => {
        fetch('api/ImageService/GetManualViewerStudies')
            .then(response => response.json())
            .then(data => {
                setMVStudy(data);
            });
    }
    const studyChangeHandler = (e) => {
        ClearData();
        FillManualViewer(e.target.value);
        FillSites(e.target.value);
       
    }

    const viewerChangeHandler = (e) => {
        setVisit(""); 
        FillVisits(document.getElementById("ddlManualStudy").value, e.target.value);
    }
    

    const FillManualViewer = (studyId) => {        
        fetch('api/ImageService/GetManualViewer?StudyId=' + studyId)
            .then(response => response.json())
            .then(data => {
                setManualViewer(data);
            });
    }
    
    const FillSites = (studyId) => {
        fetch('api/ImageService/FillSiteList?StudyId=' + studyId)
            .then(response => response.json())
            .then(data => {
                setSite(data);
            });
    }
    const FillVisits = (studyId, sourceTypeId) => {
        fetch('api/ImageService/FillMTVisitList?StudyId=' + studyId + '&SourceTypeID=' + sourceTypeId)
            .then(response => response.json())
            .then(data => {
                setVisit(data);
            });
    }

    function ClearData() {
        setManualViewer(""); 
        setSite(""); 
        setVisit(""); 
    }

    const AddNewManualTask = (event) => {

        let objDDList = document.getElementById("ddlManualStudy");
        if (objDDList.value == '-1') {
            alert('Please select the study.');
            objDDList.focus();
            return false;
        }

        objDDList = document.getElementById("ddlManualViewer");
        if (objDDList.value == '-1') {
            alert('Please select the Viewer.');
            objDDList.focus();
            return false;
        }

        let objManualTask = {
            TaskId: props.navigateFrom == 'dashboard'? 0 : props.taskId,
            StudyId: document.getElementById("ddlManualStudy").value,
            SiteId: document.getElementById("ddlSite").value,
            SubjectId: document.getElementById('txtSubject').value.trim(),
            VisitId: document.getElementById("ddlVisit").value,
            SourceTypeId: document.getElementById("ddlManualViewer").value,
            TaskURL: '' //document.getElementById('txtURL').value.trim() 
        };

        fetch('api/ImageService/SaveManualTask', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(objManualTask)
        }).then(r => r.text()).then(res => {

            if (res == "success") {
                if (props.navigateFrom == 'dashboard') {
                    setShow(false);
                    props.fetchData(props.userName, props.taskStageId, props.studyId, props.sourceTypeId);
                }
                else {
                    setShow(false);
                    props.reloadActionQCEditTask();
                }
            }
            else if (res == "TASK ALREADY EXISTS" || res == "exists") {
                if (props.navigateFrom == 'dashboard') {
                    alert('Task already exists.');
                }
                else {
                    alert('Subject already exists.');
                }
                return false;
            }
            else if (res == "notsaved") {
                alert('Task not added, Something went wrong.');
                return false;
            }
            else if (res.startsWith("Error:")) {
                alert(res);
                return false;
            }
        })

    };

    return (
        <>
            <Button variant="primary" className="btn btn-success" onClick={handleShow}> {props.navigateFrom == 'dashboard' ? 'Add Manual Task' : 'Edit Task Details' }</Button>

            <Modal centered
                show={show}
                onHide={handleClose}
                dialogClassName={classes.TSModalDailog_Seven} backdrop="static">

                <Modal.Header closeButton>
                    <Modal.Title style={{ textAlign: "center" }}>Add New Manual Task</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row mt-2">
                        <div className="col-sm-1" />
                        <div className="col-sm-2">
                            <label>Study<MandatoryLabel />:</label>
                        </div>
                        <div className="col-sm-8">
                            <SelectList id='ddlManualStudy' dataSource={arrMVStudy} onChangeHandler={studyChangeHandler} selectedData={studyId} style={{ marginBottom: "10px" }} />
                        </div>
                        <div className="col-sm-1" />
                    </div>      
                    <div className="row mt-2">
                        <div className="col-sm-1" />
                        <div className="col-sm-2">
                            <label>Viewer<MandatoryLabel />:</label>
                        </div>
                        <div className="col-sm-8">
                            <SelectList id='ddlManualViewer' dataSource={arrManualViewer} onChangeHandler={viewerChangeHandler} selectedData={sourceTypeId} style={{ marginBottom: "10px" }} />
                        </div>
                        <div className="col-sm-1" />
                    </div>
                    <div className="row mt-2">
                        <div className="col-sm-1" />
                        <div className="col-sm-2">
                            <label>Visit:</label>
                        </div>
                        <div className="col-sm-8">
                            <SelectList id='ddlVisit' dataSource={arrVisit} selectedData={visitId} style={{ marginBottom: "10px" }} />
                        </div>
                        <div className="col-sm-1" />
                    </div>
                    <div className="row mt-2">
                        <div className="col-sm-1" />
                        <div className="col-sm-2">
                            <label>Site:</label>
                        </div>
                        <div className="col-sm-8">
                            <SelectList id='ddlSite' dataSource={arrSite} selectedData={siteId} style={{ marginBottom: "10px" }} />
                        </div>
                        <div className="col-sm-1" />
                    </div>
                    <div className="row mt-2">
                        <div className="col-sm-1" />
                        <div className="col-sm-2">
                            <label>Subject:</label>
                        </div>
                        <div className="col-sm-8">
                            <InputString id="txtSubject" name="txtSubject" />
                        </div>
                        <div className="col-sm-1" />
                    </div>                   
                    
                    <div className="row mt-2" style={{ display: "none" }}>
                        <div className="col-sm-1" />
                        <div className="col-sm-2">
                            <label>URL:</label>
                        </div>
                        <div className="col-sm-8">
                                <InputString id="txtURL" maxlength="999" />
                        </div>
                        <div className="col-sm-1" />
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-md btn-success" variant="primary" onClick={AddNewManualTask}>
                        Save
                        </Button>
                    <Button className="btn btn-md btn-danger" variant="secondary" onClick={handleClose}>
                        Cancel
                        </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default AddManualTaskModal;