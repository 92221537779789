import React from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import TextArea from '../UI/Control/InputTextarea';
import { useSelector } from 'react-redux'
import MandatoryLabel from '../UI/Control/MandatoryLabel';

const MarkUrgent = (props) => {

    let taskId = props.taskId;
    const EDCUserID = useSelector(state => state.auth.gValue01);

    const SaveMarkUrgent = (event) => {

        let objNotes = document.getElementById('txtNotes');
        if (objNotes.value.trim() == '') {
            alert('Notes should not be empty.');
            objNotes.focus();
            return false;
        }

        let objMarkUrgentModel = {
            TaskID: taskId,
            Notes: objNotes.value,
            CreationUser: EDCUserID
        };

        fetch('api/ImageService/SaveMarkUrgent', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(objMarkUrgentModel)
        }).then(r => r.json()).then(res => {
            if (res == -1) {
                alert('Notes not saved, Something went wrong.');
            }
            if (res > 0) {
                props.relaodAction();
            }
        })

    };

    return (
        <div>
            <Row className="mt-2 mb-2">
                <Col md={1}>Notes<MandatoryLabel/>: </Col>
                <Col md={10}>
                    <TextArea id="txtNotes" rows='5' cols='100' />
                </Col>
                <Col md={1}><Button style={{ marginTop: "90px" }} className="btn-success" onClick={SaveMarkUrgent} > Submit </Button></Col>
            </Row>
        </div>
    );
}
export default MarkUrgent;
