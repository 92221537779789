import React, { useState } from 'react';
import { Button } from "react-bootstrap";
import { Modal } from 'react-bootstrap';
import classes from './../TrialstatNoto.module.css';
import InputContainer from '../UI/Container/InputContainer';
import FirstLocalRead from '../CRFSelection/FirstLocalRead';
import FirstCentralRead from '../CRFSelection/FirstCentralRead';
import AdjudicationRead from '../CRFSelection/AdjudicationRead';
import { useSelector } from 'react-redux'

const ParadigmReads = (props) => {
    const [formSource, setFormSource] = useState([]);
    const [EDCeCRFs, setEDCeCRFs] = useState([{}]);
    const [show, setShow] = useState(false);
    const [noOfLocalRead, setNoofLocalRead] = useState([{}]);
    const [noOfCentralRead, setNoofCentralRead] = useState([{}]);
    const [noOfAdjRead, setNoofAdjRead] = useState([]);
    const EDCUserID = useSelector(state => state.auth.gValue01);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setNoofLocalRead([]);
        setNoofCentralRead([]);
        setNoofAdjRead([]);
        fetchEDCFormsLib();
        fetchGetStudyParadigmCRFsDetails();
        setShow(true);
    };

    const arrCRFVersion = [
        {
            Name: "1.1 - Description of Version",
            Value: "1.1-DescriptionofVersion"
        },
        {
            Name: "1.2 - Description of Version",
            Value: "1.2-DescriptionofVersion"
        }
    ]

    const fetchEDCFormsLib = async () => {
        const response = await fetch('api/ServiceCall/GetEDCFormLib')
        if (!response.ok) {
            throw new Error("Something went wrong, message :" + response.message + " \nHttp Error:" + response.httpError)
        }
        const edcForms = await response.text();
        let listForms = JSON.parse(edcForms);
        const arrEDCForms = new Array();
        for (var iCount = 0; iCount < listForms.length; iCount++) {
            arrEDCForms.push({ Name: listForms[iCount].FormName, Value: listForms[iCount].FormID })
        }
        setEDCeCRFs(arrEDCForms);
        setFormSource(arrEDCForms);
    }

    const searchChangeHandlerLocalRead = (e) => {
        let sequence = e.target.id.split('_')[1];
        let arrAvl = FilterAvailableList('multiSelectFLRSel_' + sequence);
        var selectElement = document.getElementById('multiSelectFLR_' + sequence);
        selectElement.innerHTML = "";
        var searchValue = document.getElementById('txtFLRSearch_' + sequence).value;
        let arrEDC = arrAvl.filter(item => item.Name.toLowerCase().includes(searchValue.toLowerCase()));
        var option = null;
        for (var iCount = 0; iCount < arrEDC.length; iCount++) {
            option = document.createElement("option");
            option.text = arrEDC[iCount].Name;
            option.value = arrEDC[iCount].Value;
            selectElement.add(option);
        }
    }

    const searchChangeHandlerLocalReadSelected = (e) => {
        let sequence = e.target.id.split('_')[1];
        let arrSel = FilterSelectedList('multiSelectFLR_' + sequence);
        var selectElement = document.getElementById('multiSelectFLRSel_' + sequence);
        selectElement.innerHTML = "";
        var searchValue = document.getElementById('txtFLRSearchSelected_' + sequence).value;
        let arrEDC = arrSel.filter(item => item.Name.toLowerCase().includes(searchValue.toLowerCase()));
        var option = null;
        for (var iCount = 0; iCount < arrEDC.length; iCount++) {
            option = document.createElement("option");
            option.text = arrEDC[iCount].Name;
            option.value = arrEDC[iCount].Value;
            selectElement.add(option);
        }
    }

    const searchChangeHandlerCentralRead = (e) => {
        let sequence = e.target.id.split('_')[1];
        let arrAvl = FilterAvailableList('multiSelectFCRSel_' + sequence);
        var selectElement = document.getElementById('multiSelectFCR_' + sequence);
        selectElement.innerHTML = "";
        var searchValue = document.getElementById('txtFCRSearch_' + sequence).value;
        let arrEDC = arrAvl.filter(item => item.Name.toLowerCase().includes(searchValue.toLowerCase()));
        var option = null;
        for (var iCount = 0; iCount < arrEDC.length; iCount++) {
            option = document.createElement("option");
            option.text = arrEDC[iCount].Name;
            option.value = arrEDC[iCount].Value;
            selectElement.add(option);
        }
    }

    const searchChangeHandlerCentralReadSelected = (e) => {
        let sequence = e.target.id.split('_')[1];
        let arrSel = FilterSelectedList('multiSelectFCR_' + sequence);
        var selectElement = document.getElementById('multiSelectFCRSel_' + sequence);
        selectElement.innerHTML = "";
        var searchValue = document.getElementById('txtFCRSearchSelected_' + sequence).value;
        let arrEDC = arrSel.filter(item => item.Name.toLowerCase().includes(searchValue.toLowerCase()));
        var option = null;
        for (var iCount = 0; iCount < arrEDC.length; iCount++) {
            option = document.createElement("option");
            option.text = arrEDC[iCount].Name;
            option.value = arrEDC[iCount].Value;
            selectElement.add(option);
        }
    }


    const searchChangeHandlerAdjRead = (e) => {
        let sequence = e.target.id.split('_')[1];
        let arrAvl = FilterAvailableList('multiSelectAdjSel_' + sequence);
        var selectElement = document.getElementById('multiSelectAdj_' + sequence);
        selectElement.innerHTML = "";
        var searchValue = document.getElementById('txtAdjSearch_' + sequence).value;
        let arrAdj = arrAvl.filter(item => item.Name.toLowerCase().includes(searchValue.toLowerCase()));
        var option = null;
        for (var iCount = 0; iCount < arrAdj.length; iCount++) {
            option = document.createElement("option");
            option.text = arrAdj[iCount].Name;
            option.value = arrAdj[iCount].Value;
            selectElement.add(option);
        }
    }

    const searchChangeHandlerAdjReadSelected = (e) => {
        let sequence = e.target.id.split('_')[1];
        let arrSel = FilterSelectedList('multiSelectAdj_' + sequence);
        var selectElement = document.getElementById('multiSelectAdjSel_' + sequence);
        selectElement.innerHTML = "";
        var searchValue = document.getElementById('txtAdjSearchSelected_' + sequence).value;
        let arrAdj = arrSel.filter(item => item.Name.toLowerCase().includes(searchValue.toLowerCase()));
        var option = null;
        for (var iCount = 0; iCount < arrAdj.length; iCount++) {
            option = document.createElement("option");
            option.text = arrAdj[iCount].Name;
            option.value = arrAdj[iCount].Value;
            selectElement.add(option);
        }
    }

    function FilterAvailableList(elementId) {
        let selList = document.getElementById(elementId);
        let options = selList.options;
        let arrSel = [];
        Array.from(options).map((option) => {
            arrSel.push({ Name: option.text, Value: option.value });
        });
        let arrAvl = [];
        arrAvl = formSource.filter(el => {
            return !arrSel.find(element => {
                return element.Name === el.Name && element.Value === el.Value;
            });
        });
        return arrAvl;
    }

    function FilterSelectedList(elementId) {
        let selList = document.getElementById(elementId);
        let options = selList.options;
        let arrAvl = [];
        Array.from(options).map((option) => {
            arrAvl.push({ Name: option.text, Value: option.value });
        });
        let arSel = [];
        arSel = formSource.filter(el => {
            return !arrAvl.find(element => {
                return element.Name === el.Name && element.Value === el.Value;
            });
        });
        return arSel;
    }

    const fetchGetStudyParadigmCRFsDetails = () => {
        fetch('api/ReadSettings/getStudyParadigmCRFsDetails?studyReadSettingsID=' + props.studyReadSettingsID + '&readerType=LR,CR,ADJ')
            .then(response => response.json())
            .then(data => {
                setData(data);
            });
    }

    function setData(data) {
        let arrLR = data.listStudyParadigmCRFModel.filter(item => item.ReaderType.toLowerCase() == 'lr');
        let arrCR = data.listStudyParadigmCRFModel.filter(item => item.ReaderType.toLowerCase() == 'cr');
        let arrADJ = data.listStudyParadigmCRFModel.filter(item => item.ReaderType.toLowerCase().includes('adj'));
       
        ReadDeatilsByID(arrLR, arrCR, arrADJ);
    }

    const ReadDeatilsByID = (arrLR, arrCR, arrADJ) => {
        let readCount = 0;
        let arr = [];
        let readIDs = "";
        let paradimId = 1;

        fetch('api/ReadSettings/GetNoOfRead?paradimId=' + props.paradigmId + '&studyProtocolID=' + props.studyProtocolID)
            .then(response => response.json())
            .then(data => {

                let readCount = 0;
                let arr = [];
                let noOfLR = data.NoofLocalRead;
                let noOfCR = data.NoofCentralRead;
                let noOfADJ = data.listAdjReadDetails.length;

                for (var iCount = 0; iCount < noOfLR; iCount++) {
                    if (arrLR.length > 0) {
                        readCount = readCount + 1;
                        arr.push({
                            StudyParadigmCRFID: arrLR[iCount].StudyParadigmCRFID,
                            ReadCount: readCount,
                            LocalReadCount: iCount,
                            selectedReads: GetSelectedReads(arrLR, (iCount + 1))
                        });
                    }
                    else {
                        readCount = readCount + 1;
                        arr.push({
                            StudyParadigmCRFID: 0,
                            ReadCount: readCount,
                            LocalReadCount: iCount,
                            selectedReads: new Array()
                        });
                    }
                }
                setNoofLocalRead(arr);

                arr = [];
                for (var iCount = 0; iCount < noOfCR; iCount++) {
                    if (arrCR.length > 0) {
                        readCount = readCount + 1;
                        arr.push({
                            StudyParadigmCRFID: arrCR[iCount].StudyParadigmCRFID,
                            ReadCount: readCount,
                            CentralReadCount: iCount,
                            selectedReads: GetSelectedReads(arrCR, (iCount + 1))
                        });
                    }
                    else {
                        readCount = readCount + 1;
                        arr.push({
                            StudyParadigmCRFID: 0,
                            ReadCount: readCount,
                            CentralReadCount: iCount,
                            selectedReads: new Array()
                        });
                    }
                }
                setNoofCentralRead(arr);
                readCount = 0;
                arr = [];
                var cnt = 0;

                for (var iCount = 0; iCount < noOfADJ; iCount++) {
                    if (arrADJ.length > 0) {
                        var readStage = (iCount + 2);
                        if (arr.filter(item => item.StageCount == readStage) == 0) {
                            arr.push({
                                StageCount: readStage,
                                arrNoOfAdj: new Array()
                            });

                            let ar = arrADJ.filter(item => item.ReaderType == 'ADJ_' + readStage);
                            for (var jCount = 0; jCount < data.listAdjReadDetails[iCount].Adjudications; jCount++) {
                                readCount = readCount + 1;
                                arr[cnt].arrNoOfAdj.push({
                                    StudyParadigmCRFID: ar[jCount].StudyParadigmCRFID,
                                    ParadigmStageID: readCount,
                                    StageNo: jCount,
                                    selectedReads: GetSelectedReads(ar, (jCount + 1))
                                });
                            }

                            cnt = cnt + 1;
                        }
                    }
                    else {
                        arr.push({
                            StageCount: data.listAdjReadDetails[iCount].StageNo,
                            arrNoOfAdj: new Array()
                        });

                        for (var jCount = 0; jCount < data.listAdjReadDetails[iCount].Adjudications; jCount++) {
                            readCount = readCount + 1;
                            arr[iCount].arrNoOfAdj.push({
                                StudyParadigmCRFID: 0,
                                ParadigmStageID: readCount,
                                StageNo: iCount,
                                selectedReads: new Array()
                            });
                        }
                    }
                }
                setNoofAdjRead(arr);
            });
    }

    function GetSelectedReads(arr, readCount) {

        var ar = arr.filter((item) => item.ReadStage == readCount);
        var arSelected = new Array();
        for (var iCount = 0; iCount < ar.length; iCount++) {
            let objSelectedData =
            {
                StudyParadigmCRFID: ar[iCount].StudyParadigmCRFID,
                FormID: ar[iCount].FormID

            }
            arSelected.push(objSelectedData);
        }
        return arSelected;
    }

    const updateStudyParadigmReads = (event) => {
        let readCount = 0;
        let AdjStage = 2;
        let readSettingsModel =
        {
            StudyReadSettingsID: props.studyReadSettingsID,
            StudyProtocolID: props.studyProtocolID,
            Username: EDCUserID,
            listStudyParadigmCRFModel: new Array()
        }

        let crfId = 0;
        var multiSelect = null;
        var multiSelected = null;
        for (var iCount = 0; iCount < noOfLocalRead.length; iCount++) {
            readCount++;
            crfId = document.getElementById('hdnLRCRFID_' + iCount).value == '' ? 0 : document.getElementById('hdnLRCRFID_' + iCount).value;
            multiSelect = document.getElementById('multiSelectFLRSel_' + iCount);
            multiSelected = [...multiSelect].map(option => option.value);
            if (multiSelected.length == 0) {
                let objMultiSelectFLR = document.getElementById('multiSelectFLR_' + iCount);
                alert('Please select CRF form for Read ' + readCount + ' - Local Reader.');
                objMultiSelectFLR.focus();
                return false;
            }
            else {
                for (var jCount = 0; jCount < multiSelected.length; jCount++) {
                    readSettingsModel.listStudyParadigmCRFModel.push({ StudyParadigmCRFID: crfId, StudyReadSettingsID: 0, ReadStage: (iCount + 1), ReaderType: 'LR', FormID: multiSelected[jCount] });
                }
            }
        }

        for (var iCount = 0; iCount < noOfCentralRead.length; iCount++) {
            readCount++;
            crfId = document.getElementById('hdnCRCRFID_' + iCount).value == '' ? 0 : document.getElementById('hdnCRCRFID_' + iCount).value;
            multiSelect = document.getElementById('multiSelectFCRSel_' + iCount);
            multiSelected = [...multiSelect].map(option => option.value);
            if (multiSelected.length == 0) {
                let objMultiSelectFCR = document.getElementById('multiSelectFCR_' + iCount);
                alert('Please select CRF form for Read ' + readCount + ' - Central Reader.');
                objMultiSelectFCR.focus();
                return false;
            }
            else {
                for (var jCount = 0; jCount < multiSelected.length; jCount++) {
                    readSettingsModel.listStudyParadigmCRFModel.push({ StudyParadigmCRFID: crfId, StudyReadSettingsID: 0, ReadStage: (iCount + 1), ReaderType: 'CR', FormID: multiSelected[jCount] });
                }
            }
        }

        for (var iCount1 = 0; iCount1 < noOfAdjRead.length; iCount1++) {
            AdjStage = AdjStage + iCount1;
            for (var iCount = 0; iCount < noOfAdjRead[iCount1].arrNoOfAdj.length; iCount++) {
                crfId = document.getElementById('hdnADJCRFID_' + iCount).value == '' ? 0 : document.getElementById('hdnADJCRFID_' + iCount).value;
                multiSelect = document.getElementById('multiSelectAdjSel_' + (iCount1 + 1) + (iCount + 1))
                multiSelected = [...multiSelect].map(option => option.value);
                if (multiSelected.length == 0) {
                    let objMultiSelectAdj = document.getElementById('multiSelectAdj_' + (iCount1 + 1) + (iCount + 1));
                    alert('Please select CRF form for Adjudication Stage ' + AdjStage + ' - Read(s) ' + (iCount + 1) + '.');
                    objMultiSelectAdj.focus();
                    return false;
                }
                else {
                    for (var jCount = 0; jCount < multiSelected.length; jCount++) {
                        readSettingsModel.listStudyParadigmCRFModel.push({ StudyParadigmCRFID: crfId, StudyReadSettingsID: 0, ReadStage: (iCount + 1), ReaderType: 'ADJ_' + (AdjStage), FormID: multiSelected[jCount] });
                    }
                }
            }
        }

        fetch('api/ReadSettings/updateStudyParadigmReads', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(readSettingsModel)
        }).then(r => r.json()).then(res => {
            if (res == -1) {
                alert('Read settings not updated, Something went wrong.');
                return false;
            }
            if (res == 0) {
                alert('Read settings already exist.');
                return false;
            }
            if (res == -2) {
                var objSelType = document.getElementById('selectType');
                var selectText = objSelType.options[objSelType.selectedIndex].innerHTML;
                alert('API link already exist for source type ' + selectText + '.');
                return false;
            }
            if (res > 0) {
                props.fetchReadSettingData();
                setShow(false);
            }
        })
        return true;
    };

    return (
        <>
            <a style={{ color: props.IsParadigmReads === "No" ? "red" : "green", "cursor": "pointer", textAlign: "center" }}
                onClick={handleShow}><u><b>{props.text}</b></u></a>
            {/*<button className={props.btnCss} type="button" onClick={handleShow}> {props.text} </button>*/}

            <Modal show={show} onHide={handleClose} dialogClassName={classes.TSModalDailog_Two} animation={false} backdrop="static">
                <Modal.Header className={classes.modalHeader}>
                    <div className="d-flex align-items-center justify-content-center" style={{ width: "100%" }}>
                        <Modal.Title>{props.header}</Modal.Title>
                        </div>
                </Modal.Header>
                <Modal.Body className={classes.TSModalDailog_Two_Body}>
                    <div className="row">
                        <div className="col-sm-12">
                            {
                                noOfLocalRead.map((item, index) =>
                                    <div key={index} style={{ marginTop: "20px" }}>
                                        <input type="hidden" id={"hdnLRCRFID_" + item.LocalReadCount} value={item.StudyParadigmCRFID} />
                                        <InputContainer stylePR={{ borderColor: 'orange' }} className={classes.label_One} style={{ marginTop: "0px", fontSize: "20px", backgroundColor: "orange" }} border='true' header={"Read " + item.ReadCount + " - Local Reader"}
                                            children={<FirstLocalRead
                                                sequence={item.LocalReadCount}
                                                disabled={props.disabled}
                                                multiSelectDataSource={EDCeCRFs}
                                                selectDataSource={arrCRFVersion}
                                                selectedData={[item.selectedReads]}
                                                searchChangeHandlerLocalRead={searchChangeHandlerLocalRead}
                                                searchChangeHandlerLocalReadSelected={searchChangeHandlerLocalReadSelected}
                                                isDisabled={props.isDisabled}
                                            />}
                                            Expended="true">
                                        </InputContainer>
                                    </div>
                                )
                            }
                            {
                                noOfCentralRead.map((item, index) =>
                                    <div key={index} style={{ marginTop: "20px" }}>
                                        <input type="hidden" id={"hdnCRCRFID_" + item.CentralReadCount} value={item.StudyParadigmCRFID} />
                                        <InputContainer className={classes.label_One} style={{ marginTop: "0px", fontSize: "20px", backgroundColor: "#83C0DC" }} border='true' header={"Read " + item.ReadCount + " - Central Reader"}
                                            stylePR={{ borderColor: '#83C0DC' }}
                                            children={<FirstCentralRead
                                                sequence={item.CentralReadCount}
                                                disabled={props.disabled}
                                                multiSelectDataSource={EDCeCRFs}
                                                selectDataSource={arrCRFVersion}
                                                selectedData={[item.selectedReads]}
                                                searchChangeHandlerCentralRead={searchChangeHandlerCentralRead}
                                                searchChangeHandlerCentralReadSelected={searchChangeHandlerCentralReadSelected}
                                                isDisabled={props.isDisabled}
                                            />}
                                            Expended="true" >
                                        </InputContainer>
                                    </div>
                                )
                            }
                        </div>
                        <div className="col-sm-12">
                            {
                                noOfAdjRead.map((item, index) =>
                                    <div key={index} style={{ marginTop: "20px" }}>
                                        <InputContainer className={classes.label_One} style={{ marginTop: "0px", fontSize: "20px", backgroundColor: "green" }} border='true' header={"Adjudication Stage " + item.StageCount}
                                            stylePR={{ borderColor: 'green' }}
                                            children={<AdjudicationRead
                                                sequence={index + 1}
                                                disabled={props.disabled}
                                                multiSelectDataSource={EDCeCRFs}
                                                selectDataSource={arrCRFVersion}
                                                searchChangeHandlerAdjRead={searchChangeHandlerAdjRead}
                                                searchChangeHandlerAdjReadSelected={searchChangeHandlerAdjReadSelected}
                                                noOfAdj={item.arrNoOfAdj}
                                                isDisabled={props.isDisabled}
                                            />}

                                            Expended="true" >
                                        </InputContainer>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex align-items-center justify-content-center" style={{ width: "100%" }}>
                        {!props.disabled && props.action == "copy" && <Button className="btn btn-success" onClick={CopyReadsetting} style={{ marginRight: "5px" }}> Copy </Button>}
                        {!props.disabled && (props.action == "add" || props.action == "edit") && <Button variant="secondary" className="btn btn-success" style={{ marginRight: "5px" }} onClick={updateStudyParadigmReads} disabled={props.isDisabled}> Submit </Button>}
                    <Button variant="primary" className="btn btn-danger" onClick={handleClose}>
                        Cancel
                            </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ParadigmReads;
