import React from 'react'

const InputTextarea = (props) => {
    return (
        <textarea
            id={props.id}
            rows={props.rows}
            cols={props.cols}
            className="form-control"
            style={{ "resize": "none" }}
            placeholder={props.placeholder}
            maxLength={props.maxlength}
            disabled={props.disabled}
        >
        </textarea>);      
}
export default InputTextarea;