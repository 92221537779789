class IdleTimer {
    constructor({ timeout, onTimeout, onExpired, setShow }) {
        this.timeout = timeout;
        this.onTimeout = onTimeout;
        this.secondTick = 0;
        this.timeInSecondsAfterSessionOut = (timeout - 50);
        this.extendSessionTimeout = false;
        this.setShow = setShow;
        this.eventHandler = this.updateExpiredTime.bind(this);
        this.tracker();
        this.startInterval();
    }

    startInterval() {
        this.updateExpiredTime();
        this.interval = setInterval(() => {
            const expiredTime = parseInt(
                localStorage.getItem("_expiredTime") || 0,
                10
            );
            if (expiredTime < Date.now()) {
                if (this.onTimeout) {
                    this.onTimeout();
                    this.cleanUp();
                }
            }
        }, 1000);
    }

    updateExpiredTime() {
        if (this.timeoutTracker) {
            clearTimeout(this.timeoutTracker);
        }
        this.timeoutTracker = setTimeout(() => {
            if (this.extendSessionTimeout == false) {
                this.ResetThisSession();
                localStorage.setItem("_expiredTime", Date.now() + this.timeout * 1000);
            }
        }, 300);
    }

    ExtendSessionTimeout = () => {
        if (this.extendSessionTimeout == true) {
            this.ResetThisSession();
            this.extendSessionTimeout = false;
            localStorage.setItem("_expiredTime", Date.now() + (this.timeout * 1000));
            this.setShow(false);
        }
    };

    Logout = () => {
        fetch('api/Users/ClearSession', {
            method: 'POST'
        })
            .then(response => response.text())
            .then(data => {
                this.onTimeout();
            });
    };
   
    ResetThisSession() {
        this.secondTick = 0;
    }

    StartThisSessionTimer = () => {
        var tick = 0;
        this.secondTick++;
        var timeLeft = 0;
        timeLeft = (this.timeInSecondsAfterSessionOut - 20) - this.secondTick; // override, we have 30 secs only
        if (timeLeft <= 30) {
            if (this.extendSessionTimeout == false) {
                this.extendSessionTimeout = true;
                this.setShow(true);
            }
            if (document.getElementById('spanTimeLeft') != null) {
                document.getElementById('spanTimeLeft').innerHTML = timeLeft;
            }
        }

        if (this.secondTick == (this.timeInSecondsAfterSessionOut - 20)) {
            clearTimeout(tick);
            this.Logout();
            return;
        }
        tick = setTimeout(() => this.StartThisSessionTimer(), 1000);
    };

    tracker() {
        window.addEventListener("mousemove", this.eventHandler);
        window.addEventListener("scroll", this.eventHandler);
        window.addEventListener("keydown", this.eventHandler);
    }

    cleanUp() {
        localStorage.removeItem("_expiredTime");
        clearInterval(this.interval);
        window.removeEventListener("mousemove", this.eventHandler);
        window.removeEventListener("scroll", this.eventHandler);
        window.removeEventListener("keydown", this.eventHandler);
    }
}
export default IdleTimer;