import React from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import TextArea from '../UI/Control/InputTextarea';
import MandatoryLabel from '../UI/Control/MandatoryLabel';
const ReOutcomeNotification = (props) => {

    let taskId = props.taskId;    

    const ReOutcomeNotify = (event) => {
        let objNotes = document.getElementById('txtNotes');
        if (objNotes.value.trim() == '') {
            alert('Notes should not be empty.');
            objNotes.focus();
            return false;
        }

        let objOutcomeNotifyModel = {
            TaskID: taskId,
            Notes: objNotes.value,
            CreationUser: ''
        };

        fetch('api/ImageService/ReOutcomeNotify', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(objOutcomeNotifyModel)
        }).then(r => r.json()).then(res => {
            if (res == -1) {
                alert('Outcome Notification not sent, Something went wrong.');
            }
            if (res > 0) {
                props.relaodAction();
            }
        })

    };

    return (
        <div>
            <Row className="mt-2 mb-2">
                <Col md={1}>Notes<MandatoryLabel></MandatoryLabel>: </Col>
                <Col md={8}>
                    <TextArea id="txtNotes" rows='5' cols='100' />
                </Col>
                <Col md={2}><Button style={{ marginTop: "90px" }} className="btn-success" onClick={ReOutcomeNotify} > Re-Send Notification </Button></Col>
            </Row>            
        </div>
    );
}
export default ReOutcomeNotification;
