import React from "react";
import classes from "./ToggleButton.css";

const ToggleButton = (props) => {
    return (
        <label className="switch">
            <input type="checkbox" onChange={props.onToggleChange} name={props.name} id={props.id} className={props.className} checked={props.checked} />
            <span className="slider round" />
         </label>
    );
};

export default ToggleButton;
