import React, { useState, useEffect, useRef } from "react";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import DataTable from "react-data-table-component";
import Card from '../UI/Card/Card';
import InputString from '../UI/Control/InputString';
import customStyles from '../customStyles';
import NoData from '../NoData';
import SelectList from '../UI/Control/SelectList';
import DataTableExtensions from "../ExportTable/index";
import "../ExportTable/index.css";
import './../CustomDataTableExtensions.css';

const DMDashboardViewer = (props) => {


    const [inputVal, setInputVal] = useState('');
    const [rowData, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [dmVarName, setDMVarName] = useState('DMGlobal');
    const [dashboardList, setDashboardList] = useState([]);
    const colum = new Array();
    const [filteredColumns, setfilteredColumns] = useState([]);
    const [fileName, setFileName] = useState('DM Global Dashsoard');
   
    function search(rows) {
        let selString = inputVal.toString();
        let searchText = selString.toLowerCase().split(",");
        return rows.filter((row) => {
            return filteredColumns.some((column) => {
                if (searchText.length > 1) {
                    if (row[column] != null) {
                        return searchText.indexOf(row[column].toString().toLowerCase()) > -1
                    }
                }
                else {
                    if (row[column] != null) {
                        return row[column].toString().toLowerCase().indexOf(inputVal.toLowerCase()) > -1
                    }
                }
            })
        })
    }

    const searchChangeHandler = (e) => {
        //setDMVarName(e.target.value);
        setInputVal(e.target.value);
    }
   
    const fetchDMDashboardData = () => {
        let searchColumn = new Array();
        fetch('api/Dashboard/GetDMDashboardData?dashboardName=' + dmVarName)
            .then(response => response.json())
            .then(data => {
                for (let column in data[0]) {
                    if (column.toLowerCase() == 'studyid')
                        colum.push({ name: column, selector: column, sortable: true, center: true, omit: true });
                    else {
                        colum.push({ name: column, selector: column, sortable: true, center: true });
                        searchColumn.push(column);
                    }

                    
                }
                setColumns(colum);
                setRows(data);
                setfilteredColumns(searchColumn);
            });
    }

    const dahoboardNameChangeHandler = (e) => {
        setInputVal("");
        setDMVarName(e.target.value);
        setFileName(e.target.options[e.target.selectedIndex].text);
    }
    const fetchDashBoards = async () => {
        fetch('api/Dashboard/GetDashboards')
            .then(response => response.json())
            .then(data => {
                setDashboardList(data);
                fetchDMDashboardData(dmVarName);
            });
    }

    useEffect(() => {
        fetchDashBoards();
    }, [dmVarName]);

    return <Card>
        <Row style={{ marginBottom: "10px" }}>
            <Col md={6}>
                <div className="input-group">
                    <InputString id="txtSearch" value={inputVal} onChangeHandler={searchChangeHandler} placeholder="Search"/>
                </div>
            </Col>
            <Col md={4}>
            </Col>
            <Col md={2}>
                <SelectList id='ddlDashboardName' dataSource={dashboardList} onChangeHandler={dahoboardNameChangeHandler}/>
            </Col>
        </Row>
        <div className="tableContainer">
            {search(rowData).length > 0 ?
                <DataTableExtensions columns={columns} data={search(rowData)} filter={false} exportHeaders={true} fileName={fileName}>
                <DataTable
                columns={columns}
                data={search(rowData)}
                className="table table-striped table-bordered table-hover"
                customStyles={customStyles}
                striped
                pagination
                paginationPerPage={50}
                paginationRowsPerPageOptions={[20, 50, 100]}
                />
                </DataTableExtensions> : <NoData headers={columns}></NoData>}
        </div>
    </Card>
};

export default DMDashboardViewer;
