import React, { useState, useRef } from 'react';
import { Button } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import classes from './../TrialstatNoto.module.css';
import NotificationDetails from './NotificationDetails';

const NotificationDetailsModel = (props) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            {props.action.toLowerCase() == 'view' && <button style={{ "margin": "5px" }} onClick={handleShow} className="btn btn-sm btn-warning"> View </button>}
            {props.action.toLowerCase() == 'edit' && <button style={{ "margin": "5px" }} onClick={handleShow} className="btn btn-sm btn-warning"> Edit </button>}
            <Modal show={show} onHide={handleClose} dialogClassName={classes.TSModalDailog_Two} animation={false} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>{props.header}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <NotificationDetails action={props.action.toLowerCase()} onCloseHandler={handleClose}/>
                </Modal.Body>                
            </Modal>
        </>
    );
}
export default NotificationDetailsModel;