import React from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import InputRadio from '../UI/Control/InputRadio';
import TextArea from '../UI/Control/InputTextarea';
import { useSelector } from 'react-redux'

const VaribilityRead = (props) => {

    let taskId = props.taskId;
    const EDCUserID = useSelector(state => state.auth.gValue01);

    const SaveVariabilityRead = (event) => {

        let objNotes = document.getElementById('txtNotes');
        if (objNotes.value.trim() == '') {
            alert('Notes should not be empty.');
            objNotes.focus();
            return false;
        }

        let objPerVarRead = document.querySelector("input[name=PerVarRead]:checked");
        if (objPerVarRead == null) {
            alert('Please select performance variability read.');
            objPerVarRead.focus();
            return false;
        }

        let objVariabilityReadModel = {
            TaskID: taskId,
            Notes: objNotes.value,
            PerVariabilityRead: objPerVarRead.value,
            CreationUser: EDCUserID
        };

        fetch('api/ImageService/SaveVariabilityRead', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(objVariabilityReadModel)
        }).then(r => r.json()).then(res => {
            if (res == -1) {
                alert('Variability read not saved, Something went wrong.');
            }
            if (res > 0) {
                props.relaodAction();
            }
        })

    };

    return (
        <div>
            <Row className="mt-2 mb-2">
                <Col md={1}>
                    Notes:
             </Col>
                <Col md={10}>
                    <TextArea id="txtNotes" rows='5' cols='50' />
                </Col>
            </Row>
            <Row className="mt-2 mb-2">
                <Col md={2}>
                    Performance Variability Read:
             </Col>
                <Col md={4}>
                    <InputRadio orientation='horizontal' value="1" name="PerVarRead" text="Yes" value="Y" style={{ "margin-left": "10px", "margin-right": "5px" }}></InputRadio>
                    <InputRadio orientation='horizontal' value="0" name="PerVarRead" text="No" value="N" style={{ "margin-left": "10px", "margin-right": "5px" }}></InputRadio>
                </Col>
            </Row>            
            <Row className="mt-2 mb-2">
                <Col md={12} style={{ "text-align": "center" }}>
                    <Button className="btn-success" onClick={SaveVariabilityRead} > Submit </Button>
                </Col>
            </Row>
        </div>
    );
}
export default VaribilityRead;
