import React, { useState, useEffect } from 'react';
import InputContainer from '../UI/Container/InputContainer';
import { Button } from 'react-bootstrap';
import MandatoryLabel from '../UI/Control/MandatoryLabel';

const AssignedReviewByUser = (props) => {
    const [assignReviewerDetails, setAssignReviewerDetails] = useState({});

    const fetchAssignReviewerDetails = () => {
        fetch('api/ImageService/GetAssignReviewerDetails?taskId=' + props.taskId + '&reviewStage=' + props.reviewStage)
            .then(response => response.json())
            .then(data => {
                setAssignReviewerDetails(data);
            });
    }

    useEffect(() => {
       // alert(61);
        fetchAssignReviewerDetails();
    }, []);

    const onClickHandler = (EDCUserID,event) => {
        //let objReviewerNote = document.getElementById('txtReviewerNote');
       // if (props.reviewAssignmentID == 0) {
            let objReadAssignmentsModel = {
                TaskID: props.taskId,
                ReadStage: props.reviewStage,
                AssignedReader: EDCUserID,
                ReaderNote: '' //objReviewerNote.value
            };
            fetch('api/ImageService/SaveReviewAssignments', {
                method: 'POST',
                headers: { 'Content-type': 'application/json' },
                body: JSON.stringify(objReadAssignmentsModel)
            }).then(r => r.json()).then(res => {
                if (res == -1) {
                    alert('Review assignment not done, Something went wrong.');
                }
                if (res > 0) {
                    props.relaodAction();
                }
            })
       // }
    }

    return (
        <div style={{ marginTop: "15px" }}>
            <InputContainer border='true' header={"Re-Reviewer " + props.reviewStage} style={{ "text-align": "center" }} Expended="true" >
                <div className="row">
                    <div className="col-sm-9">
                        <div className="row" style={{ padding: "15px" }}>
                            <div className="col-sm-3">
                                {props.readerType == "CR" ? <p> <strong> Assigned Reviewer:</strong> <MandatoryLabel /> </p>
                                    : <p> <strong> Assigned Adjudication:</strong> <MandatoryLabel /> </p>}
                            </div>
                            <div className="col-sm-3" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                {assignReviewerDetails.AssignedReader}
                            </div>
                            <div className="col-sm-2">
                                {/*assignReviewerDetails.ReadStatusID != 7 && assignReviewerDetails.ReadStatusID != 9 && <Button variant="primary" 
                                 * className="btn btn-success" onClick={() => props.onChangeReviewer(assignReviewerDetails.EDCUserID, props.reviewStage, event)}
                                 * reviewAssignmentID={props.reviewAssignmentID}>Send For Review</Button>*/}
                                {(assignReviewerDetails.ReadStatusID == 7 || assignReviewerDetails.ReadStatusID == 9)
                                    ? <label reviewAssignmentID={props.reviewAssignmentID} style={{ color: "red" }}>Sent for Review</label>
                                    : <Button variant="primary" className="btn btn-success"
                                        onClick={() => props.onChangeReviewer(assignReviewerDetails.EDCUserID, props.reviewStage, event)}
                                        reviewAssignmentID={props.reviewAssignmentID}>Send For Review</Button>}
                            </div>
                            {
                                //<div className="col-sm-2">
                                //    <Button variant="primary" className="btn btn-warning" onClick={() => props.OnRemoveAssignedReviewer(event)} reviewAssignmentID={props.reviewAssignmentID}>Remove Reviewer</Button>
                                //</div>
                            }
                        </div>

                    </div>

                </div>

            </InputContainer>
        </div>
    );
}
export default AssignedReviewByUser;
