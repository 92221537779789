import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from "reactstrap";
import RateModal from "./RateModal";
import classes from '../TrialstatNoto.module.css'
import DeleteIcon from '../../Images/Icons/delete.svg';

const PaymentSettings = (props) => {
    const usrRole = "";
    var header = "";
    if (props.paymentType == "initial") {
        header = "Initial Read Payment";
    }
    else if (props.paymentType == "adjudication") {
        header = "Adjudication Read Payment";
    }
    else if (props.paymentType == "re-read") {
        header = "Re-Read Payment";
    }

    const DeleteHistoReadPaySettings = (readPayID, readPayType) => {
        var con = confirm('Are you sure, you want to delete selected payment?');
        if (con == true) {
            let objHRPSModel =
            {
                ReadPayID: readPayID,
                Alert: 'X'
            }

            fetch('api/GlobalSettings/DeleteHistoReadPaySettings', {
                method: 'POST',
                headers: { 'Content-type': 'application/json' },
                body: JSON.stringify(objHRPSModel)
            }).then(r => r.text()).then(res => {
                if (res == -1 || res == 0) {
                    alert('Payment is not deleted, Something went wrong.');
                    return false;
                }
                if (res == 1) {
                    props.fetchGetHistoReadPaySettingsDetails(readPayType);
                }
            })
            return true;
        }
    };

    return (
        <div style={{ textAlign: "center" }}>
            <div className="row">
                <div className="col-sm-8" style={{ textAlign: "center", padding:"0px" }}>
                    <h6>{header}</h6>
                </div>
                <div className="col-sm-4" style={{ textAlign:"right" }}>
                    {usrRole != "Read-Only" && <RateModal paymentType={props.paymentType} header={header} fetchGetHistoReadPaySettingsDetails={props.fetchGetHistoReadPaySettingsDetails}></RateModal>}
                </div>
            </div>
            <div style={{ overflowY: "auto", maxHeight:"290px" }}>
            <table className="table table-bordered" style={{ marginTop: "10px", width: "100%" }}>
                <thead>
                    <tr className={classes.tbHeaderCaptionDark}>
                        <td>Read Range</td>
                        <td>Total Payment</td>
                        <td>Action</td>
                    </tr>
                </thead>
                <tbody>
                    {
                        props.ReadPayment.map((item1, index1) => {
                                return <tr key={index1} >
                                <td style={{width:"40%"}}>{item1.ReadRange}</td>
                                <td style={{ width: "40%" }}>{item1.TotalPayment}</td>
                                <td style={{ width: "20%" }}>
                                    <img style={{ "border": "1px solid #dcdcdc", "cursor": "pointer", marginLeft: "5px" }}
                                        height="34px" width="34px" src={DeleteIcon} alt="Delete" onClick={e => DeleteHistoReadPaySettings(item1.ReadPayID, item1.ReadPayType)} />
                                </td>
                                
                            </tr>
                        })
                    }
                </tbody>
                </table>
                </div>
        </div>
    );
};
export default PaymentSettings;