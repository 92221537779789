import React from 'react';
export const menuData_ImgSrvTeam = [
    {
        name: "Home",
        level: "1",
        url: "/Dashboard",
        children: [
            {
                name: "Image Services",
                level: "2",
                url: "/",
                children: [
                    {
                        name: "Dashboard",
                        level: "3",
                        url: "/Dashboard"
                    },
                    {
                        name: "Closed Tasks",
                        level: "3",
                        url: "/ClosedTaskDashboard"
                    },
                    {
                        name: "CR Current Tasks",
                        level: "3",
                        url: "/CRCurrentTask"
                    },
                    {
                        name: "Query Dashboard",
                        level: "3",
                        url: "/ISCQueryDashboard"
                    }
                    //{
                    //    name: "Advanced Search",
                    //    level: "3",
                    //    url: "/AdvanceSearch"
                    //},
                    //{
                    //    name: "Lead Setting",
                    //    level: "3",
                    //    url: ""
                    //},
                    //{
                    //    name: "Manager Setting",
                    //    level: "3",
                    //    url: ""
                    //},
                    //{
                    //    name: "Report",
                    //    level: "3",
                    //    url: ""
                    //},
                    //{
                    //    name: "Information Log",
                    //    level: "3",
                    //    url: ""
                    //}
                ]
            },
            {
                name: "Admin",
                level: "2",
                url: "/",
                children: [
                    {
                        name: "Assign Role",
                        level: "3",
                        url: "/AssignUserRoleList"
                    },
                    {
                        name: "Assign User",
                        level: "3",
                        url: "/AssignUserByProject"
                    },
                    {
                        name: "Manage User",
                        level: "3",
                        url: "/ManageUserList"
                    },
                    {
                        name: "Documents",
                        level: "3",
                        url: "/DocumentMgmtList"
                    }
                ]
            },
            {
                name: "Central Reader Management",
                level: "2",
                url: "/",
                children: [
                    {
                        name: "CR Site Affiliation",
                        level: "3",
                        url: "/CRSiteAffiliation"
                    }
                ]
            },
            //{
            //    name: "Report",
            //    level: "2",
            //    url: ""
            //},
            //{
            //    name: "Central Reader Management",
            //    level: "2",
            //    url: ""
            //}
        ]
    },
    {
        name: "Help",
        level: "1",
        url: "/",
        children: [
            {
                name: " ",
                level: "2",
                url: ""
            }]
    },
    {
        name: "Contact Support",
        level: "1",
        url: "/",
        children: [
            {
                name: " ",
                level: "2",
                url: ""
            }]
    },
    {
        name: "About",
        level: "1",
        url: "/AboutUs",
        children: [
            {
                name: " ",
                level: "2",
                url: ""
            }]
    }
]