import React, { useState } from 'react';
import { Button, Modal, Row, Col } from 'react-bootstrap';
import classes from './../TrialstatNoto.module.css';
import InputContainer from '../UI/Container/InputContainer';

const ViewQueryDetails = (props) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        GetQueriesDetails();
        setShow(true);
    }
    const [arrQueryDetails, setArrQueryDetails] = useState([]);

    const GetQueriesDetails = () => {
        fetch('api/TaskQuery/GetQueriesByQueryId?QueryId=' + props.QueryID )
            .then(response => response.json())
            .then(data => {
                setArrQueryDetails(data.listQueryDetails);               
            });
    }  
    return (
        <>
            <Button variant="primary" className="btn btn-success" onClick={handleShow}>{props.btnText}</Button>
            <Modal centered
                show={show}
                onHide={handleClose}
                dialogClassName={classes.TSModalDailog_95pc} className="modal-dialog-top" style={{ "height": "auto"}} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title style={{ textAlign: "center" }}>{props.Title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>                                        
                    <Row>
                        <Col md={12}>
                            {arrQueryDetails.map((item, index) =>
                                <InputContainer Expended='false' header={`Query ${index + 1} Details`} style={{ textAlign: "center", marginTop: "15px" }}>
                                    <div style={{ marginLeft: "12px", marginRight: "12px", marginTop: "0px" }}>
                                        <Row>
                                            <Col md={1} className={classes.cellRLBBorder}><b>Query ID</b></Col>
                                            <Col md={1} className={classes.cellRightBorder}>{item.QueryID}</Col>
                                            <Col md={2} className={classes.cellRightBorder}><b>Task ID</b></Col>
                                            <Col md={1} className={classes.cellRightBorder}>{item.TaskID}</Col>
                                            <Col md={1} className={classes.cellRightBorder}><b>Study Name</b></Col>
                                            <Col md={2} className={classes.cellRightBorder}>{item.studyCode}</Col>
                                            <Col md={2} className={classes.cellRightBorder}><b>Status</b></Col>
                                            <Col md={2} className={classes.cellRightBorder}>{item.QueryStatus}</Col>
                                        </Row>
                                        <Row>
                                            <Col md={1} className={classes.cellRLBBorder}><b>Site Name</b></Col>
                                            <Col md={1} className={classes.cellRightBorder}>{item.SiteName}</Col>
                                            <Col md={2} className={classes.cellRightBorder}><b>Hide From Site</b></Col>
                                            <Col md={1} className={classes.cellRightBorder}>{item.HideFromSite}</Col>
                                            <Col md={1} className={classes.cellRightBorder}><b>Created By</b></Col>
                                            <Col md={2} className={classes.cellRightBorder}>{item.CreationUser}</Col>
                                            <Col md={2} className={classes.cellRightBorder}><b>Created Date</b></Col>
                                            <Col md={2} className={classes.cellRightBorder}>{item.CreationDate}</Col>
                                        </Row>
                                        <InputContainer Expended='true' header='Comment History'
                                            style={{ textAlign: "center", marginBottom: "0px", marginTop: "15px"}}>
                                            <Row style={{ marginLeft: "0px", marginRight: "0px", marginTop: "0px" }}>
                                                <Col md={2} className={classes.cellRLBBorder}><b>User ID</b></Col>
                                                <Col md={2} className={classes.cellRLBBorder}><b>Date</b></Col>
                                                <Col md={1} className={classes.cellRightBorder}><b>Note Type</b></Col>
                                                <Col md={7} className={classes.cellRLBBorder}><b>Comment</b></Col>
                                            </Row>
                                            {item.listQueryComments.map((item1, index1) =>
                                                <Row key={index1} style={{ marginLeft: "0px", marginRight: "0px", marginTop: "0px" }}>
                                                    <Col md={2} className={classes.cellRLBBorder}>{item1.CreationUser}</Col>
                                                    <Col md={2} className={classes.cellRightBorder}>{item1.CreationDate}</Col>
                                                    <Col md={1} className={classes.cellRightBorder}>{item1.CommentType}</Col>
                                                    <Col md={7} className={classes.cellRightBorder}>{item1.QueryComment}</Col>
                                                </Row>                                                    
                                            )}
                                            </InputContainer>    
                                    </div>
                                </InputContainer>
                            )}
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-md btn-danger" variant="secondary" onClick={handleClose}> Close </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default ViewQueryDetails;