import React, { useState, useEffect, useRef }  from 'react';
const MultiSelectListNew = (props) => {
    return (
        <select value={props.selectedData} id={props.id} onChange={props.onChangeHandler} className="form-select" style={props.style} size={props.size} multiple={props.isMultiSelect == "false" ? false : true} disabled={props.disabled}>
            {props.dataSource && props.dataSource.map((listItem, index) => {
                return <option key={index} value={listItem.Value}>{listItem.Name}</option>
            })}
        </select>
    );
};
export default MultiSelectListNew;

