import React, { useState } from 'react';
import { Button, Lable, Row, Col } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import classes from './../TrialstatNoto.module.css';
import SelectList from '../UI/Control/SelectList';

const EDCInfoMapping = (props) => {
    const [show, setShow] = useState(false);
    const [quesDetails, setQuesDetails] = useState([]);
    const [arrTaskInfoMap, seTaskInfoMap] = useState([]);
    
    const handleShow = () => {
        setQuesDetails([]);
        seTaskInfoMap([]);
        if (props.sourceType == 0) {
            alert('Please select source Type.');
            return false;
        }

        if (props.CFRFormID == 0) {
            alert('Please select Read info CRF.');
            return false;
        }

        if (props.CFRFormID != "0") {
            getLibQuesByID();
        }

        setShow(true);
    }

    const SaveCRFInfoMapping = () => {
        props.setFormId(document.getElementById('multiSelectReadDetails').value);
        let arr = new Array();
        let quesId = null;
        let infoMapId = null;
        for (var iCount = 0; iCount < quesDetails.length; iCount++) {
            infoMapId = document.getElementById('ddlTaskColumn_' + quesDetails[iCount].QuesID);
            if (infoMapId.value != 0) {
                quesId = document.getElementById('hdnQuesId_' + quesDetails[iCount].QuesID);
                infoMapId = document.getElementById('ddlTaskColumn_' + quesDetails[iCount].QuesID);
                arr.push({ SRSMapID: 0, EDCQuesID: quesId.value, TaskInfoMapID: infoMapId.value });
            }
            
        }

        props.setCRFMapping(arr);
        setShow(false);
    };

    const handleClose = () => {
        setShow(false)
    };

    const getLibQuesByID = () => {
        fetch("api/ReadSettings/GetQuesByLibFormID?LibFormID=" + props.CFRFormID)
            .then(response => response.json())
            .then(data => {
                setQuesDetails(data);                
                FillTaskInfoMapList();                
            })
    }

    const FillTaskInfoMapList = () => {
        fetch('api/ReadSettings/FillTaskInfoMapList?sourceTypeID=' + props.sourceType)
            .then(response => response.json())
            .then(data => {
                let arr = [{ Value: '0', Name: '' }]
                for (var iCount = 0; iCount < data.length; iCount++) {
                    arr.push({ Value: data[iCount].Value, Name: data[iCount].Name });
                }
                seTaskInfoMap(arr);

                if (props.action.toLowerCase() == "add" && props.CFRFormID == props.formId) {
                    if (props.arrCRFMapping.length > 0) {
                        for (var iCount = 0; iCount < props.arrCRFMapping.length; iCount++) {
                            document.getElementById('ddlTaskColumn_' + props.arrCRFMapping[iCount].EDCQuesID).value = props.arrCRFMapping[iCount].TaskInfoMapID;
                        }
                    }
                }
                else {
                    fetchCRFInfoMapping();
                }
            });
    }

    const fetchCRFInfoMapping = () => {
        let model = {
            StudyReadSettingsID: props.studyReadSettingId,
            ReadInfoCRFID: props.CFRFormID,
        };

        fetch('api/ReadSettings/GetCRFInfoMapping', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(model)
        }).then(res => res.json()).then(data => {
            if (data.listCRFMapping != null) {
                for (var iCount = 0; iCount < data.listCRFMapping.length; iCount++) {
                    document.getElementById('ddlTaskColumn_' + data.listCRFMapping[iCount].EDCQuesID).value = data.listCRFMapping[iCount].TaskInfoMapID;
                }
            }
        });
    }

    return (
        <>
            <Button alt="CFR info Mapping" onClick={handleShow} className="btn btn-success" style={{ marginLeft: "5px" }}> Map CRF </Button>
            <Modal show={show} onHide={handleClose} dialogClassName={classes.TSModalDailog_Five} animation={false} backdrop="static" style={{ marginTop: "250px" }}>
                <Modal.Header className={classes.modalHeader}>
                    <div className="d-flex align-items-center justify-content-center" style={{ width: "100%" }}>
                        <Modal.Title style={{ textAlign: "center" }}>CRF Mapping Details</Modal.Title>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    {quesDetails.map((item, index) =>
                        <Row key={index} style={{ "margin": "2px" }}>
                            <Col md={6} className={classes.label_One}>
                                {(index+1) + "."} {item.QuesText} {"(" + item.QuesType + "):"}
                                <input type="hidden" id={"hdnQuesId_" + item.QuesID} value={item.QuesID} />
                            </Col>
                            <Col md={6}>
                                <SelectList dataSource={arrTaskInfoMap} id={"ddlTaskColumn_" + item.QuesID} style={{ marginTop: "5px", lineHeight: "1.8rem", fontSize: "18px" }} disabled={props.isDisabled}></SelectList>
                            </Col>
                        </Row>
                    )}                   
                </Modal.Body>
                <Modal.Footer>
                    {!props.disabled && props.action != "view" && <Button className="btn btn-success" onClick={SaveCRFInfoMapping} disabled={props.isDisabled}> Save </Button>}
                    <Button variant="primary" className="btn btn-danger" onClick={handleClose}>
                        Close
                            </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default EDCInfoMapping