import React from 'react';
//import classes from './Card.module.css';
const Row = (props) => {
    return (
        <div className="row">
            {props.children}
        </div>
    );
};
export default Row;
