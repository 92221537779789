import React from 'react';
import { Row, Col } from "reactstrap";
import InputString from '../UI/Control/InputString';
import SelectList from '../UI/Control/SelectList';
import MultiSelectList from '../UI/Control/MultiSelectList';
import InputContainer from '../UI/Container/InputContainer';
import tsClasses from '../TrialstatNoto.module.css';
import Button from '../UI/Button/Button';
import AdjudicationPrograming from './AdjudicationPrograming';
const VisitAdjudication = (props) => {
    let arrFrm = new Array();;
    let formName = '';
    return (
        <InputContainer Expended='true' border='false' header={props.Visit} style={{"paddingLeft":"35px"}} > 
            <Row>
                <Col md={12} style={{ "width": "98%", "marginBottom": "5px" }}>
                    <table style={{ marginLeft: "15px", "marginBottom": "5px" }}>
                        <thead>
                            <tr>
                                <td className={tsClasses.tbHeaderCaptionDark} style={{ "width": "15%"}}>Read Paradigm</td>
                                <td className={tsClasses.tbHeaderCaptionDark} style={{ "width": "15%" }}>Type</td>
                                <td className={tsClasses.tbHeaderCaptionDark} style={{ "width": "25%" }}>CRF Name</td>
                                <td className={tsClasses.tbHeaderCaptionDark} style={{ "width": "25%" }}>API</td>
                                <td className={tsClasses.tbHeaderCaptionDark} style={{ "width": "20%" }}>Action</td>
                            </tr>
                        </thead>
                        {props.dataSource.map((item, index) => {
                            if (item.ReadInfoCRFID != 0) {
                                arrFrm = props.arrEDCeCRFs.filter(i => i.Value == item.ReadInfoCRFID);
                                if (arrFrm.length > 0) {
                                    formName = arrFrm[0].Name;
                                }
                            }
                            return (
                                <tbody key={index}>
                                <tr>
                                <td>{item.ReadParadigm}</td>
                                <td>{item.Type}</td>
                                <td>{formName}</td>
                                <td>{item.APILink}</td>
                                <td>
                                    <AdjudicationPrograming fetchAdjSettingDetail={props.fetchAdjSettingDetail} isAdjConfigSubmit={item.IsAdjConfigSubmit} disabled={props.disabled} header={"Visit : " + props.Visit + ", Paradigm : " + item.ReadParadigm + ", Form : " + formName} studyReadSettingsID={item.StudyReadSettingsID} text="Adjudication Configuration"/>
                                </td>
                                    </tr>
                                    </tbody>);
                        })}                   
                    </table>
                </Col>
            </Row>
            </InputContainer>      
        );
    };
    export default VisitAdjudication;
