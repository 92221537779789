import React, { useState, useEffect, useRef } from "react";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import DataTable from "react-data-table-component";
import Card from '../UI/Card/Card';
import InputString from '../UI/Control/InputString';
import customStyles from '../customStyles';
import NoData from '../NoData';
import SelectList from '../UI/Control/SelectList';
import DataTableExtensions from "../ExportTable/index";
import "../ExportTable/index.css";
import './../CustomDataTableExtensions.css';
import QueryAddEditModal from '../ImageServices/QueryAddEditModal.js';
import MultiSelectList from '../UI/Control/MultiSelectList';
import { useSearchParams } from "react-router-dom";

const SiteQueryDashboard = (props) => {
    const [inputVal, setInputVal] = useState('');
    const [rowData, setRows] = useState([]);
    const [columns, setColumns] = useState([]); 
    const [fileName, setFileName] = useState('Site_QueryDashboard');
    const colum = new Array();
    const [filteredColumns, setfilteredColumns] = useState([]);
    const [studyList, setStudyList] = useState([]);   
    const [siteList, setSiteList] = useState([]);   
    const [studyID, setStudyID] = useState('');
    const [arrQueryStatus, setQueryStatus] = useState([]);
    const [selectedQStusIds, setSelectedQStusIds] = useState(['1','2']);
    const [searchParams] = useSearchParams();

    let action = searchParams.get("Action")

    function search(rows) {
        let selString = inputVal.toString();
        let searchText = selString.toLowerCase().split(",");
        return rows.filter((row) => {
            return filteredColumns.some((column) => {
                if (searchText.length > 1) {
                    if (row[column] != null) {
                        return searchText.indexOf(row[column].toString().toLowerCase()) > -1
                    }
                }
                else {
                    if (row[column] != null) {
                        return row[column].toString().toLowerCase().indexOf(inputVal.toLowerCase()) > -1
                    }
                }
            })
        })
    }

    const searchChangeHandler = (e) => {
        setInputVal(e.target.value);
    }
   
    const fetchQueryDashboardData = (inQueryStatusId) => {
        colum.push({
            name: "Action",
            button: true,
            style: {
                'border-right-style': 'solid',
                'border-width': '1px',
                'border-color': 'rgba(0, 0, 0, 0.12)',
                'width': '7%'
            },
            cell: (row) => (
                <div>
                    <QueryAddEditModal UserRole='siteuser' queryid={row.QueryID} Title="Query Details" action={action} btnText="Action" refreshsata={fetchQueryDashboardData} TaskId="0" />
                </div>
            )
        });
        let searchColumn = new Array();
        fetch('api/Site/GetQueryDashboardData?studyid=' + studyID + '&QueryStatusId=' + inQueryStatusId)
            .then(response => response.json())
            .then(data => {
                for (let column in data[0]) {
                    colum.push({ name: column, selector: row => row[column], cellExport: row => row[column], sortable: true, center: true });
                    searchColumn.push(column);
                }
                setColumns(colum);
                setRows(data);
                setfilteredColumns(searchColumn);
            });
    }
    const studyOnChangeHandler = (e) => {
        setInputVal("");
        setStudyID(e.target.value);
        //fetchQueryDashboardData();
    }      
    const fetchStudies = async () => {
        fetch('api/Site/GetStudiesByUser')
            .then(response => response.json())
            .then(data => {
                setStudyList(data);
                var selStudyID = document.getElementById("ddlStudy").value;
                setStudyID(selStudyID);
                FillQueryStatus();
                fetchQueryDashboardData();                
            });
    }

    const FillQueryStatus = () => {
        fetch('api/ImageService/FillQueryStatus')
            .then(response => response.json())
            .then(data => {
                setQueryStatus(data);
            });
    };

    const iscQueryStatusOnChange = (e) => {
        let multiSelect = document.getElementById('lstISCQueryStatus');
        let multiSelected = [...multiSelect.selectedOptions].map(option => option.value);
        let selStatusIds = new Array();
        for (var jCount = 0; jCount < multiSelected.length; jCount++) {
            selStatusIds.push(multiSelected[jCount]);
        }
        setSelectedQStusIds(selStatusIds);
        fetchQueryDashboardData(selStatusIds);
    };

    const setTaskIdSearch = () => {
        var field = 'taskid';
        var url = window.location.href;
        if (url.indexOf('?' + field + '=') != -1) {
            setInputVal(searchParams.get('taskid'));
        }
    };

    useEffect(() => {
        fetchStudies();
        setTaskIdSearch();
    }, [studyID]);

    return <Card>
        <Row style={{ marginBottom: "10px" }}>
            <Col md={2}>
                <MultiSelectList id="lstISCQueryStatus" dataSource={arrQueryStatus} isMultiSelect="true" selectedData={selectedQStusIds}
                    onChangeHandler={iscQueryStatusOnChange} size="3" style={{ width: "85%" }} />
            </Col>
            <Col md={3}>
                <SelectList id='ddlStudy' dataSource={studyList} onChangeHandler={studyOnChangeHandler} />
            </Col>
            <Col md={2}></Col>
            <Col>
                {/* <SelectList id='ddlSite' dataSource={siteList} onChangeHandler={siteOnChangeHandler} style={{ width: "300px" }}  /> */}
                <div className="input-group">
                    <InputString id="txtSearch" value={inputVal} onChangeHandler={searchChangeHandler} placeholder="Search" />
                </div>
            </Col>
        </Row>
        <div className="tableContainer">
            {search(rowData).length > 0 ?
                <DataTableExtensions columns={columns} data={search(rowData)} filter={false} exportHeaders={true} fileName={fileName}>
                <DataTable
                columns={columns}
                data={search(rowData)}
                className="table table-striped table-bordered table-hover"
                customStyles={customStyles}
                striped
                pagination
                paginationPerPage={50}
                paginationRowsPerPageOptions={[20, 50, 100]}
                />
                </DataTableExtensions> : <NoData headers={columns}></NoData>}
        </div>
    </Card>
};

export default SiteQueryDashboard;
