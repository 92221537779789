import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import DataTable from "react-data-table-component";
import Card from '../UI/Card/Card';
import InputString from '../UI/Control/InputString';
import customStyles from '../customStyles';
import NoData from '../NoData';
import SelectList from '../UI/Control/SelectList';
import DataTableExtensions from "../ExportTable/index";
import "../ExportTable/index.css";
import './../CustomDataTableExtensions.css';
import Button from '..//UI/Button/Button';

const StudyDesignerReport = (props) => {
    const [inputVal, setInputVal] = useState('');
    const [rowData, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const colum = new Array();
    const [filteredColumns, setfilteredColumns] = useState([]);
    const [arrStudy, setArrStudy] = useState([]);
    const [arrReportType, setArrReportType] = useState([{ Value: '-1', Name: '--Select Report--' },{ Value : 'ReadSettings', Name : 'Read Settings' }, { Value : 'StudySettings', Name : 'Study Settings' }]);
    const [fileName, setFileName] = useState('StudyDesignerReport');

    function search(rows) {
        let selString = inputVal.toString();
        let searchText = selString.toLowerCase().split(",");
        return rows.filter((row) => {
            return filteredColumns.some((column) => {
                if (searchText.length > 1) {
                    if (row[column] != null) {
                        return searchText.indexOf(row[column].toString().toLowerCase()) > -1
                    }
                }
                else {
                    if (row[column] != null) {
                        return row[column].toString().toLowerCase().indexOf(inputVal.toLowerCase()) > -1
                    }
                }
            })
        })
    }

    const searchChangeHandler = (e) => {
        setInputVal(e.target.value);
    }

    const fetchFillStudyList = () => {
        fetch('api/Study/FillStudyList')
            .then(response => response.json())
            .then(data => {
                setArrStudy(data);
            });
    }

    const fetchStudyReportList = (studyId, reportType) => {
        let searchColumn = new Array();
        fetch('api/Study/GetStudyReportList?studyId=' + studyId + '&reportType=' + reportType)
            .then(response => response.json())
            .then(data => {
                if (data != "-1") {
                    for (let column in data[0]) {
                        if (column.toLowerCase() == 'studyid')
                            colum.push({ name: column, selector: row => row[column], cellExport: row => row[column], sortable: true, center: true, omit: true });
                        else {

                            if (reportType == "ReadSettings") {
                                if (column == 'Read CRFs') {
                                    colum.push({ name: column, selector: row => row[column], cellExport: row => row[column], sortable: true, center: true, width: "700px", wrap: true });
                                    searchColumn.push(column);
                                }
                                else {
                                    colum.push({ name: column, selector: row => row[column], cellExport: row => row[column], sortable: true, center: true, wrap: true });
                                    searchColumn.push(column);
                                }
                            }
                            else {
                                colum.push({ name: column, selector: row => row[column], cellExport: row => row[column], sortable: true, center: true });
                                searchColumn.push(column);
                            }
                        }
                    }
                    setColumns(colum);
                    setRows(data);
                    setfilteredColumns(searchColumn);
                }
                else
                    alert("Error while generating study report, contact system admin.")
            });
    }

    const onStudyReportChangeHandler = (e) => {
        let objStudy = document.getElementById("ddlStudies");
        document.getElementById("ddlReportType").value = -1;
        fetchStudyReportList(objStudy.value, '-1');
    }

    const LoadReport = (e) => {
        let objReportType = document.getElementById("ddlReportType");
        if (objReportType.value == '-1') {
            alert('Please select report type.');
            objReportType.focus();
            return false;
        }
        let objStudy = document.getElementById("ddlStudies");
        var selectedStudyText = objStudy.options[objStudy.selectedIndex].text;
        setFileName(selectedStudyText+'_'+objReportType.value);
        fetchStudyReportList(objStudy.value, objReportType.value);
    }

    useEffect(() => {
        fetchFillStudyList();
    }, []);

    return <Card>
        <Row style={{ marginBottom: "10px" }}>
            <Col md={2}>
                <div className="input-group">
                    <SelectList id='ddlStudies' dataSource={arrStudy} onChangeHandler={onStudyReportChangeHandler} />
                </div>
            </Col>
            <Col md={2}>
                <div className="input-group">
                    <SelectList id='ddlReportType' dataSource={arrReportType} />
                </div>
            </Col>
            <Col md={2}>
                <Button id="btnLoadReport" className="btn btn-success" style={{ marginRight: "10px" }} onClick={LoadReport}> Load Report </Button>
            </Col>
            <Col md={2}>
            </Col>
            <Col md={4}>
                <InputString id="txtSearch" value={inputVal} onChangeHandler={searchChangeHandler} placeholder="Search" />
            </Col>

        </Row>
        <div className="tableContainer">
            {search(rowData).length > 0 ?
                <DataTableExtensions columns={columns} data={search(rowData)} filter={false} exportHeaders={true} fileName={fileName}>
                    <DataTable
                        columns={columns}
                        data={search(rowData)}
                        className="table table-striped table-bordered table-hover"
                        customStyles={customStyles}
                        striped
                        pagination
                        paginationPerPage={50}
                        paginationRowsPerPageOptions={[20, 50, 100]}
                    />
                </DataTableExtensions> : <NoData headers={columns}></NoData>}
        </div>
    </Card>
}

export default StudyDesignerReport;