import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import classes from './../TrialstatNoto.module.css';
import AssignReadByUser from './AssignedReadByUser';

const AssignedReadByUserModal = (props) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    let arrRead = [
        {
            ReadID: props.row.ReaderID,
            ReadHeader: "Read " + props.row.ReaderID,
            AssignedReader: props.row.CentralReader,
            CRInstitution: props.row.Institution,
            ReaderType: props.row.ReaderType,
            DueInHours: props.row.ApproxAvailbility,
            CurrentReadQueue: props.row.CurrentReadQueue,
            ReadPosition: props.row.RecommendedPosition,
            AvgReadPerHour: 5,
            OverallProjectReadAvg: 41,
            CRProjectReadTotal: 38,
            CRProjectReadCompleted: 29,
            SystemRiskAssessment: "LOW",
            BackgroundColor: "green",
            UpcomingSchedule: [
                {
                    Date: "22-JAN-2022",
                    Hours: "5 Hours",
                    BackgroundColor: "green"
                },
                {
                    Date: "23-JAN-2022",
                    Hours: "",
                    BackgroundColor: "red"
                },
                {
                    Date: "24-JAN-2022",
                    Hours: "3 Hours",
                    BackgroundColor: "green"
                }
            ]
        }
    ];

    return (
        <>
            <Button variant="primary" className="btn btn-sm btn-warning" onClick={handleShow}> {props.text}  </Button>
            <Modal show={show} onHide={handleClose} dialogClassName={classes.TSModalDailog_Two} animation={false} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>{props.header}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AssignReadByUser ReadByUserData={arrRead}></AssignReadByUser>
                </Modal.Body>
                <Modal.Footer>

                <Button variant="primary" className="btn btn-danger" onClick={handleClose}>
                    Close
                </Button>

                </Modal.Footer>
            </Modal>
        </>
    );
}
export default AssignedReadByUserModal;