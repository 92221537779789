import React, { useState } from 'react';
import { Button, Modal, Row, Col } from 'react-bootstrap';
import SelectList from '../UI/Control/SelectList';
import InputTextarea from '../UI/Control/InputTextarea';
import classes from './../TrialstatNoto.module.css';
import MandatoryLabel from '../UI/Control/MandatoryLabel';

const StudyStatusModal = (props) => {
    const [show, setShow] = useState(false);
    const [currentStatus, setCurrentStatus] = useState('');
    const handleClose = () => setShow(false);
    const handleShow = () => {
        GetStudyStatusDetails();
        setShow(true)
    };

    const SaveValue = (event) => {
        SaveStudyStatus()
    };

    const GetStudyStatusDetails = () => {
        fetch('api/Study/GetStudyStatusDetails?StudyStatusId=' + props.studyStatusId)
            .then(response => response.json())
            .then(data => {
                setData(data);
            });
    }

    function setData(data) {
        document.getElementById('selectUATData').value = data.StatusId;
        document.getElementById('txtReason').value = data.Reason;
        setCurrentStatus(data.Status);
    }

    const SaveStudyStatus = (event) => {
        const objUATData = document.getElementById('selectUATData');
        if (objUATData.value == '0') {
            alert('Please select project status.');
            objUATData.focus();
            return false;
        }

        let objStudyStatusModel =
        {
            StudyID: props.studyId,
            StudyStatusId: props.studyStatusId,
            StatusId: document.getElementById('selectUATData').value,
            Reason: document.getElementById('txtReason').value,
        }

        fetch('api/Study/SaveStudyStatus', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(objStudyStatusModel)
        }).then(r => r.json()).then(res => {
            if (res == 0) {
                alert('Read settings already exist.');
                return false;
            }
            if (res > 0) {
                props.fetchData();
                setShow(false);
            }
        })
        return true;
    };

    return (
        <>
            <Button variant="primary" className="btn btn-primary" style={props.style} onClick={handleShow} title={props.title}> {props.text}  </Button>

            <Modal centered
                show={show}
                onHide={handleClose}
                dialogClassName={classes.TSModalDailog_Seven} backdrop="static">

                <Modal.Header closeButton>
                    <Modal.Title style={{ textAlign: "center" }}><h5> {props.studyName + " - " + props.protocolVersion}</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-sm-1" style={{width:"4%"}}>
                        </div>
                        <div className="col-sm-10" style={{ width: "95%" }}>
                            <div className="row">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <label className="mb-1"><b> Current Project Status :</b> {currentStatus} </label>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                <div className="col-sm-12">
                                        <label className="mb-1"><b> Project Status : </b></label><MandatoryLabel></MandatoryLabel>
                                        <SelectList dataSource={props.statusData} id="selectUATData"></SelectList>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-sm-12">
                                        <label className="mb-1"><b> Reason for Change : </b></label>
                                        <InputTextarea id="txtReason" rows="7" cols="75" maxlength="499"></InputTextarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-1" style={{ width: "4%" }}>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-md btn-success" variant="primary" onClick={SaveValue}>
                        Save
                        </Button>
                    <Button className="btn btn-md btn-danger" variant="secondary" onClick={handleClose}>
                        Cancel
                        </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default StudyStatusModal;