import React, { useState, useEffect } from 'react';
import InputString from '../UI/Control/InputString';
import MandatoryLabel from '../UI/Control/MandatoryLabel';
import InputTextarea from '../UI/Control/InputTextarea';
import SelectList from '../UI/Control/SelectList';
import Button from '../UI/Button/Button';
import { useNavigate } from 'react-router-dom';

const AddUser = (props) => {
    const navigate = useNavigate();
    const [arrLocLang, setArrLocLang] = useState([{ Value: 'en', Name: 'English'}]);
    const [arrAssignedRole, setAssignedRoleList] = useState([]);
    const [arrImgServRole, setImgServRole] = useState([]);

    useEffect(() => {
        FillAssignedRole();
        fetchImageServiceRoles();
    }, [])

    // Not in use
    const fetchLocalizationLanguages = async () => {
        const response = await fetch('api/ServiceCall/GetLocalizationLanguages')
        if (!response.ok) {
            throw new Error("Something went wrong, message :" + response.message + " \nHttp Error:" + response.httpError)
        }
        const locLang = await response.text();
        let listLocLang = JSON.parse(locLang);
        const arrLL = new Array();
        for (var iCount = 0; iCount < listLocLang.length; iCount++) {
            arrLL.push({ Name: listLocLang[iCount].FriendlyName, Value: listLocLang[iCount].LocalizationLanguageId })
        }
        setArrLocLang(arrLL);
    }

    const GenerateRandomPass = (e) => {
        var pass = GetNewPassword();
        document.getElementById('RandomPassword').innerText = pass;
        document.getElementById('Password').value = pass;
        document.getElementById('PasswordConfirm').value = pass;
        return pass;
    }

    function GetNewPassword() {
        var pass = '';
        var upperCase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        var lowerCase = 'abcdefghijklmnopqrstuvwxyz';
        var number = '0123456789';
        var splChars = '@#$-';
        var allChars = upperCase + lowerCase + number + splChars;

        pass = upperCase[Math.floor(Math.random() * upperCase.length)];
        pass += lowerCase[Math.floor(Math.random() * lowerCase.length)];
        pass += number[Math.floor(Math.random() * number.length)];
        pass += splChars[Math.floor(Math.random() * splChars.length)];

        while (10 > pass.length) {
            pass += allChars[Math.floor(Math.random() * allChars.length)];
        }
        return pass;
    }

    function IsPasswordSafe() {
        var objPassword = document.getElementById('Password');
        var isPwdSafe = false;

        var lowerCaseLetters = /[a-z]/g;
        var upperCaseLetters = /[A-Z]/g;
        var numbers = /[0-9]/g;
        var splchar = /[!#$%&()*+,-.:;<=>?@[\]^_`{|}~]/g;
        if (objPassword.value.trim().match(lowerCaseLetters)) {
            if (objPassword.value.trim().match(upperCaseLetters)) {
                if (objPassword.value.trim().match(numbers)) {
                    if (objPassword.value.trim().match(splchar)) {
                        isPwdSafe = true;
                    }
                }
            }
        }

        return isPwdSafe;
    }

    function ValidateEmail(email) {
        // email regular expression check
        var re_mail = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+.)+([a-zA-Z])+$/;

        if (!re_mail.test(email)) {
            alert("Invalid e-mail address");
            return false;
        }

        return true;
    }

    function jsDecodeHTMLEntities(text) {
        var entities = [
            ['apos', '\''],
            ['amp', '&'],
            ['lt', '<'],
            ['gt', '>']
        ];

        for (var i = 0, max = entities.length; i < max; ++i)
            text = text.replace(new RegExp('&' + entities[i][0] + ';', 'g'), entities[i][1]);

        return text;
    }

    function ContainsXSSElements(strContent) {
        //Decode the HTML Entities
        strContent = jsDecodeHTMLEntities(strContent);

        // short cct, this string is empty or undefined
        if ((strContent == null) || (strContent.length == 0)) {
            return false;
        }
        if (strContent.match(/[\"\'][\s]*javascript:(.*)[\"\']/g)) {
            return true;
        }

        if (strContent.indexOf('<script') > -1) {
            return true;
        }
        if (strContent.indexOf('eval(') > -1) {
            return true;
        }
        return false;
    }

    function ChkXSSElement(arrFormCtrl) {
        var resVal = false;
        var arrVal = '';
        for (var i = 0; i < arrFormCtrl.length; i++) {
            arrVal = arrFormCtrl[i];
            if (ContainsXSSElements(arrVal.toLowerCase())) {
                resVal = true;
                break;
            }
        }
        return resVal;
    }

    function isXSSElementsExist1(firstName, lastName, username, password, confirmPassword, email) {
        var arrCtrlValue = new Array();

        arrCtrlValue.push(firstName);
        arrCtrlValue.push(lastName);
        arrCtrlValue.push(username);
        arrCtrlValue.push(password);
        arrCtrlValue.push(confirmPassword);
        arrCtrlValue.push(email);

        var objTelephone = document.getElementById('Telephone');
        if (objTelephone.value.trim() != '')
            arrCtrlValue.push(objTelephone.value);

        var objComments = document.getElementById('Comments');
        if (objComments.value.trim() != '')
            arrCtrlValue.push(objComments.value);

        if (ChkXSSElement(arrCtrlValue))
            return true;
        else
            return false;
    }

    function clearControles() {
        document.getElementById('FirstName').value = ''; 
        document.getElementById('LastName').value = '';
        document.getElementById('Username').value = '';
        document.getElementById('Email').value = '';
        document.getElementById('Telephone').value = '';
        document.getElementById('Password').value = '';
        document.getElementById('PasswordConfirm').value = '';
        document.getElementById('RandomPassword').innerText = '';
        document.getElementById('Comments').value = '';
        document.getElementById('ApplicationLangId').value = 'en';
    }

    const CancelAddUser = (e) => {
        navigate('/AssignUserRoleList');
    };

    const CreateUser = (e) => {
        var objFirstName = document.getElementById('FirstName');
        if (objFirstName.value.trim() == '') {
            alert('First name should not be empty.')
            objFirstName.focus();
            return false;
        }

        var objLastName = document.getElementById('LastName');
        if (objLastName.value.trim() == '') {
            alert('Last name should not be empty.')
            objLastName.focus();
            return false;
        }

        var objUsername = document.getElementById('Username');
        if (objUsername.value.trim() == '') {
            alert('Username should not be empty.')
            objUsername.focus();
            return false;
        }

        var userName = objUsername.value;
        if (userName.match(" ") || userName.indexOf("'") >= 0 || userName.indexOf(";") >= 0 || userName.indexOf("&") >= 0 || userName.indexOf("<") >= 0 || userName.indexOf(">") >= 0 || userName.indexOf("/") >= 0 || userName.indexOf("*") >= 0 || userName.indexOf("%") >= 0) {
            alert("No special characters, including spaces and apostrophes are permitted in username.");
            objUsername.value = '';
            return false;
        }

        var objEmail = document.getElementById('Email');
        if (objEmail.value.trim() == '') {
            alert('Email should not be empty.')
            objEmail.focus();
            return false;
        }

        var emailID = objEmail.value;
        if ((emailID != "") && (ValidateEmail(emailID) == false)) {
            return false;
        }

        var objUserRole = document.getElementById('ddlUserRole');
        if (objUserRole.value.trim() == '') {
            alert('Please select user role.')
            objUserRole.focus();
            return false;
        }

        var readTypesID = 0;
        var corImgSerId = 5;
        var mgtImgSerId = 6;
        var roleLabel = '';
        var roleId = objUserRole.value;
        var objImgSerRole = document.getElementById('ddlImgSerRole');
        if (roleId == corImgSerId || roleId == mgtImgSerId) {
            if (objImgSerRole.value == '') {
                alert('Reader type should not be empty.');
                objImgSerRole.focus();
                return false;
            }
            roleLabel = objImgSerRole.options[objImgSerRole.selectedIndex].text;
            readTypesID = objImgSerRole.value;
        }
        else {
            roleLabel = objUserRole.options[objUserRole.selectedIndex].text;
        }

        var objPassword = document.getElementById('Password');
        var objPasswordConfirm = document.getElementById('PasswordConfirm');
        if (objPassword.value.trim() == '') {
            alert('Password should not be empty.')
            objPassword.focus();
            return false;
        }

        if (objPassword.value.trim() == "" || objPassword.value.trim().length < 8) {
            alert("Password must contain minimum 8 characters");
            objPassword.focus();
            return false;
        }

        if (!IsPasswordSafe()) {
            alert("The password must contain at least one uppercase letter, one lowercase letter, one number and one special character.");
            objPassword.focus();
            return false;
        }

        if (objPasswordConfirm.value.trim() == '') {
            alert('Confirm password should not be empty.')
            objPasswordConfirm.focus();
            return false;
        }

        // compare passwords
        if (objPassword.value !== objPasswordConfirm.value) {
            alert("Your passwords do not match. Please try again.")
            return false
        }
        
        if (isXSSElementsExist1(objFirstName.value, objLastName.value, objUsername.value, objPassword.value, objPasswordConfirm.value, objEmail.value)) {
            alert("CSS/XSS detected!  Please remove all html tags and control elements.");
            return false;
        }
        var objCaUsersModel = {
            userID: document.getElementById('hdnUserID').value,
            UserName: userName,
            FirstName: objFirstName.value,
            LastName: objLastName.value,
            Email: emailID,
            Telephone: document.getElementById('Telephone').value,
            Comments: document.getElementById('Comments').value,
            Password: objPassword.value,
            LocalizationLanguageId: document.getElementById('ApplicationLangId').value
        };

        fetch('api/ServiceCall/SaveUser', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(objCaUsersModel)
        }).then(r => r.text()).then(res => {
           
            let resTemp = res.replace(/["']/g, "");
            let retVal = resTemp.split('_')[0];
            let edcUserId = resTemp.split('_')[1];
            if (retVal == '1') {
                let objUsersModel = {
                    EDCUserID: edcUserId,
                    RoleID: roleId,
                    RoleLabel: roleLabel,
                    Name: userName,
                    FullName: objFirstName.value + ' ' + objLastName.value,
                    Email: emailID,
                    ReadTypes: { ReadTypesID: readTypesID }
                };
                fetch('api/Users/UpdateUserRole', {
                    method: 'POST',
                    headers: { 'Content-type': 'application/json' },
                    body: JSON.stringify(objUsersModel)
                }).then(r => r.json()).then(res => {
                    if (res == -1) {
                        alert('User role not added, Something went wrong.');
                        return false;
                    }

                    if (res > 0) {
                        navigate('/AssignUserRoleList');
                    }
                })
            }
            else if (retVal == '-2') {
                alert("Username already exist.");
            } else {
                alert(res);
            }
        })
    };

    const roleChangeHandler = (e) => {
        var corImgSerId = 5;
        var mgtImgSerId = 6;
        let roleId = e.target.value;

        if (roleId == corImgSerId || roleId == mgtImgSerId) {
            if (document.getElementById('ddlImgSerRole') != null) {
                document.getElementById('divCRRole').style.display = 'flex';
                document.getElementById('ddlImgSerRole').style.display = 'block';
            }
        }
        else {
            if (document.getElementById('ddlImgSerRole') != null) {
                document.getElementById('divCRRole').style.display = 'none';
                document.getElementById('ddlImgSerRole').style.display = 'none';
            }
        }
    };

    const FillAssignedRole = () => {
        fetch('api/Users/FillAssignedRole')
            .then(response => response.json())
            .then(data => {
                let arr = [{ Value: '', Name: '--Select Role--' }];
                for (var iCount = 0; iCount < data.length; iCount++) {
                    arr.push({ Value: data[iCount].Value, Name: data[iCount].Name });
                }
                setAssignedRoleList(arr);
            });
    };

    const fetchImageServiceRoles = () => {
        fetch('api/Users/GetImageServiceRoles')
            .then(response => response.json())
            .then(data => {
                let arr = [{ Value: '', Name: '' }];
                for (var iCount = 0; iCount < data.length; iCount++) {
                    arr.push({ Value: data[iCount].Value, Name: data[iCount].Name });
                }
                setImgServRole(arr);
            });
    };

    return (
        <div className="container-fluid" style={{ maxWidth: "100%", width: "96%", marginTop: "20px" }}>
            <div className="row">
                <div className="col-sm-1"></div>
                <div align="center" className="col-sm-10" style={{ verticalAlign: "middle", padding: "3px", border: "1px solid Silver", backgroundColor: "#CCDDEE" }}>
                    <b id="header">Create a New User</b>
                </div>
                <div className="col-sm-1"></div>
            </div>
            <div className="row">
                <div className="col-sm-1"></div>
                    
                <div className="col-sm-10" style={{ border: "1px solid Silver", padding: "30px" }}>
                    <div className="jumbotron" style={{ border: "1px solid Silver", padding: "30px", marginBottom: "7px", paddingTop: "5px", paddingBottom:"5px" }}>
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="row">
                                <div className="col-sm-4" style={{ verticalAlign: "middle", padding: "4px" }}>
                                    <input type="hidden" id="hdnUserID" value="" />
                                    <label>First Name:</label><MandatoryLabel></MandatoryLabel>
                                </div>
                                <div className="col-sm-8" style={{ verticalAlign: "middle", padding: "4px" }}>
                                    <InputString id="FirstName" maxlength="255"></InputString>
                                </div>
                            </div>

                        </div>
                        <div className="col-sm-6">
                            <div className="row">
                                <div className="col-sm-4" style={{ verticalAlign: "middle", padding: "4px" }}>
                                    <label>Last Name:</label><MandatoryLabel></MandatoryLabel>
                                </div>
                                <div className="col-sm-8" style={{ verticalAlign: "middle", padding: "4px" }}>
                                    <InputString id="LastName" maxlength="255"></InputString>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="row">
                                <div className="col-sm-4" style={{ verticalAlign: "middle", padding: "4px" }}>
                                    <label>Username:</label><MandatoryLabel></MandatoryLabel>
                                </div>
                                <div className="col-sm-8" style={{ verticalAlign: "middle", padding: "4px" }}>
                                    <InputString id="Username" maxlength="255"></InputString>
                                </div>
                            </div>

                        </div>
                        <div className="col-sm-6">
                            <div className="row">
                                <div className="col-sm-4" style={{ verticalAlign: "middle", padding: "4px" }}>
                                    <label>Email:</label><MandatoryLabel></MandatoryLabel>
                                </div>
                                <div className="col-sm-8" style={{ verticalAlign: "middle", padding: "4px" }}>
                                    <InputString id="Email" maxlength="255"></InputString>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="row">
                                <div className="col-sm-4" style={{ verticalAlign: "middle", padding: "4px" }}>
                                    <label>Role:</label><MandatoryLabel></MandatoryLabel>
                                </div>
                                <div className="col-sm-8" style={{ verticalAlign: "middle", padding: "4px" }}>
                                    <SelectList dataSource={arrAssignedRole} id="ddlUserRole" onChangeHandler={roleChangeHandler}></SelectList>
                                    
                                </div>
                            </div>

                        </div>
                            <div className="col-sm-6">
                                <div id="divCRRole" className="row" style={{ display:"none" }}>
                                    <div className="col-sm-4" style={{ verticalAlign: "middle", padding: "4px" }}>
                                        <label>CR Role:</label><MandatoryLabel></MandatoryLabel>
                                </div>
                                <div className="col-sm-8" style={{ verticalAlign: "middle", padding: "4px" }}>
                                    <SelectList dataSource={arrImgServRole} id="ddlImgSerRole" style={{ display: "none" }}></SelectList>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                    <div className="jumbotron" style={{ border: "1px solid Silver", padding: "30px", marginBottom: "7px", paddingTop: "5px", paddingBottom: "5px" }}>

                    <div className="row">
                        <div className="col-sm-6">
                            <div className="row">
                                <div className="col-sm-4" style={{ verticalAlign: "middle", padding: "4px" }}>
                                    <label>Password:</label><MandatoryLabel></MandatoryLabel>
                                </div>
                                <div className="col-sm-4" style={{ verticalAlign: "middle", padding: "4px" }}>
                                    <input type="password" id="Password" className="form-control" style={{ width: "100%" }} autocomplete="off" maxlength="255" />
                                    </div>
                                    <div className="col-sm-4" style={{ verticalAlign: "middle", padding: "4px" }}>
                                        <label id="RandomPassword" style={{ display: "inline-block", marginLeft: "2px" }}></label>
                                    </div>
                                </div>

                        </div>
                        <div className="col-sm-6">
                            <div className="row">
                                <div className="col-sm-4" style={{ verticalAlign: "middle", padding: "4px" }}>
                                    <label>Check Password :</label><MandatoryLabel></MandatoryLabel>
                                </div>
                                <div className="col-sm-6" style={{ verticalAlign: "middle", padding: "4px" }}>
                                    <input type="password" id="PasswordConfirm" className="form-control" style={{ width: "100%" }} autocomplete="off" maxlength="255" />
                                    </div>
                                    <div className="col-sm-2" style={{ verticalAlign: "middle", padding: "4px" }}>
                                        <Button id="btnLoadProc" className="btn btn-sm btn-success" onClick={GenerateRandomPass}>Generate</Button>
                                    </div>
                                </div>
                        </div>
                        </div>
                    </div>

                    <div className="jumbotron" style={{ border: "1px solid Silver", padding: "30px", paddingTop: "5px", paddingBottom: "5px" }}>
                    <div className="row">
                    <div className="col-sm-2" style={{ verticalAlign: "middle", padding: "4px" }}>
                        </div>
                        <div className="col-sm-4" style={{ verticalAlign: "middle", padding: "4px" }}>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="row">
                                <div className="col-sm-4" style={{ verticalAlign: "middle", padding: "4px" }}>
                                    <label>Tel:</label>
                                </div>
                                <div className="col-sm-8" style={{ verticalAlign: "middle", padding: "4px" }}>
                                    <InputString id="Telephone" maxlength="64"></InputString>
                                </div>
                            </div>

                        </div>
                        <div className="col-sm-6">
                            <div className="row">
                                <div className="col-sm-4" style={{ verticalAlign: "middle", padding: "4px" }}>
                                        <label>Language of
                                        the application :</label>
                                </div>
                                <div className="col-sm-8" style={{ verticalAlign: "middle", padding: "4px" }}>
                                        <SelectList dataSource={arrLocLang} id="ApplicationLangId" selectedData="en"></SelectList>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-2" style={{ verticalAlign: "middle", padding: "4px" }}>
                            <label>Comments :</label>
                        </div>
                        <div className="col-sm-10" style={{ verticalAlign: "middle", padding: "4px" }}>
                            <InputTextarea id="Comments" rows="3" rows="4" cols="60" maxlength="255"></InputTextarea>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-1"></div>
            </div>
            <div className="row" style={{ padding: "5px", "margin-bottom": "10px" }}>
                <div className="col-sm-1"></div>
                <div align="center" className="col-sm-10">
                    <Button id="btnCancel" className="btn btn-secondary" onClick={CancelAddUser} style={{ float: "right", marginRight: "10px" }}>Cancel</Button>
                    <Button id="btnUser" className="btn btn-success" onClick={CreateUser} style={{ float: "right", marginRight: "10px" }}>Create User</Button>
                </div>
                <div className="col-sm-1"></div>
            </div>
        </div>
    );
};

export default AddUser;
