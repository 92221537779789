import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Row, Col } from "react-bootstrap";
import DataTable from "react-data-table-component";
import Card from '../UI/Card/Card';
import SelectList from '../UI/Control/SelectList';
import InputString from '../UI/Control/InputString';
import customStyles from '../customStyles';
import NoData from '../NoData';
import DataTableExtensions from "../ExportTable/index";
import "../ExportTable/index.css";
import './../CustomDataTableExtensions.css';
import OutcomeModal from '../Site/OutcomeModal.js'

const Dashboard = (props) => {
    const navigate = useNavigate();
    const [inputVal, setInputVal] = useState('');
    const [rowData, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const colum = new Array();
    const [filteredColumns, setfilteredColumns] = useState([]);
    const [arrStudies, setStudies] = useState([]);

    function search(rows) {
        let selString = inputVal.toString();
        let searchText = selString.toLowerCase().split(",");
        return rows.filter((row) => {
            return filteredColumns.some((column) => {
                if (searchText.length > 1) {
                    if (row[column] != null) {
                        return searchText.indexOf(row[column].toString().toLowerCase()) > -1
                    }
                }
                else {
                    if (row[column] != null) {
                        return row[column].toString().toLowerCase().indexOf(inputVal.toLowerCase()) > -1
                    }
                }
            })
        })
    }

    const searchChangeHandler = (e) => {
        setInputVal(e.target.value);
    }

    const onQueryClick = (rowTaskId) => {
        navigate({
            pathname: '/SiteQueryDashboard',
            search: '?taskid=' + rowTaskId + '&Action=view'
        });
    };

    const FillStudyFilesList = () => {
        fetch('api/Site/GetStudiesByUser')
            .then(response => response.json())
            .then(data => {
                setStudies(data);
            });
    }

    const studyChangeHandler = (e) => {
        fetchSponsorList(e.target.value);
    };

    const fetchSponsorList = (sId) => {
        let searchColumn = new Array();
        fetch('api/Sponsor/GetSponsorList?StudyId=' + sId)
            .then(response => response.json())
            .then(data => {
                for (let column in data[0]) {
                    if (column.toLowerCase() == 'studyid')
                        colum.push({ name: column, selector: row => row[column], cellExport: row => row[column], sortable: true, center: true, omit: true });
                    else if (column.toLowerCase() == 'outcomescore') {
                        colum.push({
                            name: column, selector: row => row[column], cellExport: row => row[column], sortable: false, center: true, cell: (row) => (<div>
                                {row.OutcomeScore == '1' ? <OutcomeModal style={{ "margin": "5px" }} StudyID={row.StudyID}
                                    subjectid={row.SubjectID} Visit={row.Visit} taskId={row.TaskID}
                                    title="Outcome (Eligible/Score)" text="Outcome" /> : ''
                                }
                            </div>)
                        });
                    }
                    else if (column.toLowerCase() == 'query') {
                        colum.push({
                            name: column, selector: row => row[column], cellExport: row => row[column], sortable: false, center: true, cell: (row) => (<div>
                                {row.Query == '1' ? <button className="btn btn-sm btn-success" onClick={() => onQueryClick(row.TaskID)}>Query</button>
                                    : row.Query
                                }
                            </div>)
                        });
                    }
                    else {
                        colum.push({ name: column, selector: row => row[column], cellExport: row => row[column], sortable: true, center: true });
                        searchColumn.push(column);
                    }                    
                }
                setColumns(colum);
                setRows(data);
                setfilteredColumns(searchColumn);
            });
    }


    useEffect(() => {
        fetchSponsorList('');
        FillStudyFilesList();
    }, []);

    return <Card>
        <Row style={{ marginBottom: "10px" }}>
            <Col md={4}>
                <SelectList id='ddlStudies' dataSource={arrStudies} onChangeHandler={studyChangeHandler} />
            </Col>
            <Col md={4} />
            <Col md={4}>
                <InputString id="txtSearch" value={inputVal} onChangeHandler={searchChangeHandler} placeholder="Search" />
            </Col>
            
        </Row>
        <div className="tableContainer">
            {search(rowData).length > 0 ? <DataTableExtensions columns={columns} data={search(rowData)} filter={false} exportHeaders={true} fileName="SponsorDashboard">
                <DataTable
                columns={columns}
                data={search(rowData)}
                className="table table-striped table-bordered table-hover"
                customStyles={customStyles}
                striped
                pagination
                paginationPerPage={50}
                paginationRowsPerPageOptions={[20, 50, 100]}
                />
                </DataTableExtensions> : <NoData headers={columns}></NoData>}
        </div>
    </Card>
}

export default Dashboard;