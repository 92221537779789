import React from 'react';
const InputString = (props) => {
    return (
        <input id={props.id}
            type="text"
            value={props.value}
            name={props.name}
            size={props.inputSize}
            className="form-control"
            style={props.style}
            placeholder={props.placeholder}
            autoComplete="off"
            onChange={props.onChangeHandler}
            maxLength={props.maxlength}
            disabled={props.disabled}
        />
    );
};

export default InputString;
