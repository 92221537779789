import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import classes from './../TrialstatNoto.module.css';
import InputTextarea from '../UI/Control/InputTextarea';
import InputString from '../UI/Control/InputString';
import MandatoryLabel from '../UI/Control/MandatoryLabel';

const SponsorsAddEdit = (props) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        
        if (props.SponsorId > 0 && props.action == "edit") {
            fetchSponsorData();
        }
        setShow(true);
    };


    const fetchSponsorData = () => {
        fetch('api/Sponsors/GetSponsorDetails?SponsorId=' + props.SponsorId)
            .then(response => response.json())
            .then(data => {
                setData(data);
            });
    }

    function setData(data) {
        document.getElementById('txtSponsor').value = data.SponsorName;
    }
    
    function ValidateSponsor() {
        let objtxt = document.getElementById('txtSponsor');
        if (objtxt.value.trim() == '') {
            alert('Sponsor name should not be empty.');
            objtxt.focus();
            return false;
        }
        return true;
    }    

    const SaveSponsorData = (event) => {
        let retVal = ValidateSponsor();
        if (retVal == false)
            return false;

        let objSponsorModel =
        {
            SponsorID: props.SponsorId,
            SponsorName: document.getElementById('txtSponsor').value.trim(),
            ContactFirstName: '',
            ContactLastName: '',
            PhoneNo: '',
            Email: '',
            Address1: '',
            Address2: '',
            City: '',
            Region: '',
            ZipCode: '',
            Country: ''               
        }
        
        fetch('api/Sponsors/SaveSponsorsDetails', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(objSponsorModel)
        }).then(r => r.text()).then(res => {
            if (res == "success") {
                setShow(false);
                props.fetchSponsorsData();
            }
            else if (res == "duplicate") {
                alert('Duplicate found!');
                return false;
            }
            else if (res == "notsaved") {
                alert('Sponsor details not saved, Something went wrong.');
                return false;
            }
            else if (res.startsWith("Error:")) {
                alert(res);
                return false;
            }
            })

        return true;

    };

    return (
        <>
            {props.btn === "true" && props.action === "add" && <Button variant="primary" className="btn btn-success" onClick={handleShow} style={{ float: "right" }}> {props.text}  </Button>}
            {props.btn === "true" && props.action === "edit" && <button className="btn btn-success" type="button" onClick={handleShow}> {props.text} </button>}
           
            <Modal show={show} onHide={handleClose} dialogClassName={classes.TSModalDailog_Fifteen} animation={false} backdrop="static">
                <Modal.Header className={classes.modalHeader}>
                    <div style={{ width: "100%" }}>
                        <Modal.Title>{props.header}</Modal.Title>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="row mt-2">
                        <div className="col-sm-12 form-group">
                            <label>Sponsor Name<MandatoryLabel />:</label>
                            <InputString id="txtSponsor" maxlength="99" style={{marginTop:"7px"}} />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="" style={{ width: "100%" }}>
                        <Button variant="primary" className="btn btn-danger" onClick={handleClose} style={{ float: "right" }}>
                            Close
                            </Button>
                        {!props.disabled && (props.action == "add" || props.action == "edit") && <Button variant="secondary" className="btn btn-success" onClick={SaveSponsorData} style={{ marginRight: "10px", float: "right" }}> Save </Button>}
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default SponsorsAddEdit;