import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import InputString from '../UI/Control/InputString';
import classes from './../TrialstatNoto.module.css';
import MandatoryLabel from '../UI/Control/MandatoryLabel';
import DataTable from "react-data-table-component";
import customStyles from '../customStyles';
import NoData from '../NoData';
import ToggleButton from '../UI/Button/ToggleButton';

const EmailNotificationModal = (props) => {
    const [show, setShow] = useState(false);
    const [rowData, setData] = useState([]);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        fetchData();
        setShow(true);
    };

    const columns = [
        {
            name: "",
            width: "50px",
            cell: (row) => (
                    row.EDCUserID != '' && <input
                    type="checkbox"
                    id={"chk_" + row.Email}
                    className="chkClassName"
                    checked={row.IsSelected}
                    onClick={() => handleSelectedEmail(row.Email, event)}
                    style={{ height: '20px', width: '20px' }}
                />
            )
        },
        {
            name: "User name",
            selector: row => row.Username,
            cellExport: row => row.Username,
            sortable: true,
            center: true
        },
        {
            name: "Email",
            selector: row => row.Email,
            cellExport: row => row.Email,
            sortable: true,
            center: true,
            style: {
                'border-right-style': 'solid',
                'border-width': '1px',
                'border-color': 'rgba(0, 0, 0, 0.12)'
            },
        },
        {
            name: "Action",
            button: true,
            style: {
                'border-right-style': 'solid',
                'border-width': '1px',
                'border-color': 'rgba(0, 0, 0, 0.12)'
            },
            cell: (row) => (
                <div style={{ "padding": "5px" }}>
                    {row.EDCUserID == '' && <Button id="btnDelete" onClick={() => deleteRowHandler(row.Email, row.EmailID, event)}>Delete</Button>}
                </div >
            )
        }
    ];

    const addEmailHandler = (e) => {
        let objUsername = document.getElementById('txtUserName');
        if (objUsername.value.trim() == '') {
            alert('User name should not be empty.');
            objUsername.focus();
            return false;
        }

        let objEmail = document.getElementById('txtEmail');
        if (objEmail.value.trim() == '') {
            alert('Email should not be empty.');
            objEmail.focus();
            return false;
        }

        var re = /\S+@\S+\.\S+/;
        if (!re.test(objEmail.value)) {
            alert('Please provide valid email address.');
            objEmail.focus();
            return false;
        }

        var currentEmail = document.getElementById('txtEmail').value;
        var filtered = rowData.filter(c => c.Email == currentEmail);
        if (filtered.length == 0) {
            const b = [...rowData, { EmailID : 0 ,EDCUserID: '', Username: document.getElementById('txtUserName').value, Email: document.getElementById('txtEmail').value, IsSelected: true }];
            setData(b);
            document.getElementById('txtUserName').value = '';
            document.getElementById('txtEmail').value = '';
        }
        else {
            alert(currentEmail + ' email address already exist, Please provide different email address.');
            objEmail.focus();
            return false;
        }
        
    }

    const onToggleChange = (e) => {
        var clist = document.getElementsByClassName("chkClassName");
        if (e.target.checked == true) {
            for (var i = 0; i < clist.length; ++i) {
                clist[i].checked = "checked";
            }
        }
        else {
            for (var i = 0; i < clist.length; ++i) {
                clist[i].checked = "";
            }
        }
    }

    const deleteRowHandler = (email, emailID, e) => {
        if (emailID == 0) {
            let arr = rowData.filter(item => item.Email !== email);
            setData(arr);
        }
        else {
            DeleteCustomSiteUserEmail(email);
        }
       
        
    }

    const fetchData = () => {
        fetch('api/StudySites/GetStudySiteUsersEmail?studySiteID=' + props.studySiteID)
            .then(response => response.json())
            .then(data => {
                setData(data);
            });
    }

    const saveEmails = (event) => {
        let model = {
            StudySiteID: props.studySiteID,
            listEmailDetails : new Array()
        };
        for (var iCount = 0; iCount < rowData.length; iCount++) {
            let data = rowData[iCount];

            if (data.EDCUserID != '' && document.getElementById('chk_' + data.Email).checked == true) {
                model.listEmailDetails.push({ EmailID: 0, IsUser: data.EDCUserID == '' ? 'N' : 'Y', UserID: data.EDCUserID == '' ? data.Username : data.EDCUserID, EmailAddress: data.Email });
            }

            if (data.EDCUserID == '') {
                model.listEmailDetails.push({ EmailID: 0, IsUser: data.EDCUserID == '' ? 'N' : 'Y', UserID: data.EDCUserID == '' ? data.Username : data.EDCUserID, EmailAddress: data.Email });
            }
        }
        fetch('api/OutcomeNotification/saveEmails', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(model)
        }).then(r => r.json()).then(res => {
            if (res == -1) {
                alert('Email not saved, Something went wrong.');
            }
            else if (res = 1) {
                setShow(false);
            }
        })
    };

    const handleSelectedEmail = (email, event) => {
        let objIndex = rowData.findIndex(obj => obj.Email == email);
        if (rowData[objIndex].IsSelected == true) {
            rowData[objIndex].IsSelected = false;
        }
        else {
            rowData[objIndex].IsSelected = true;
        }
        let arr = new Array();
        for (var iCount = 0; iCount < rowData.length; iCount++) {
            arr.push(rowData[iCount]);
        }
        setData(arr);
    };

    const DeleteCustomSiteUserEmail = (emailAddress) => {
        let model = {
            StudySiteID: props.studySiteID,
            listEmailDetails: new Array()
        };
        
        model.listEmailDetails.push({ EmailAddress: emailAddress, Alert: 'X' });

        fetch('api/OutcomeNotification/DeleteCustomSiteUserEmail', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(model)
        }).then(r => r.json()).then(res => {
            if (res == 0) {
                alert('User not deleted, Something went wrong.');
            }
            if (res > 0) {
                let arr = rowData.filter(item => item.Email !== emailAddress);
                setData(arr);
                fetchData();
            }
        })
    };

    return (
        <>
            <Button variant="secondary" className="btn btn-sm btn-success" style={props.style} onClick={handleShow}> {props.text} </Button>

            <Modal centered
                show={show}
                onHide={handleClose}
                dialogClassName={classes.TSModalDailog_Thirteen} backdrop="static">

                <Modal.Header closeButton>
                    <Modal.Title style={{ textAlign: "center", display: "block" }}>Emails for {props.header}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-sm-1" style={{ width: "4%" }}>
                        </div>
                        <div className="col-sm-10" style={{ width: "95%" }}>
                            <div className="row">
                                <div className="col-sm-4">
                                    <label className="mb-1"><b>Name</b></label>
                                    <MandatoryLabel></MandatoryLabel>:
                                    <InputString id="txtUserName" maxlength="255"></InputString>
                                </div>
                                <div className="col-sm-5">
                                    <label className="mb-1"><b>Email</b></label>
                                    <MandatoryLabel></MandatoryLabel>:
                                    <InputString id="txtEmail" maxlength="255"></InputString>
                                </div>
                                <div className="col-sm-3">
                                    <div id="btnAddAllSite" style={{ marginLeft: "10px", marginTop: "27px", float: "right" }}>
                                        <ToggleButton onToggleChange={e => onToggleChange(e)} />
                                        <label style={{ marginLeft:"5px" }}>Add All Site User</label>
                                    </div>
                                    <Button id="btnAdd" className="btn btn-md btn-success" variant="primary" onClick={addEmailHandler} style={{ marginTop: "27px", float: "right" }}>
                                        Add
                                    </Button>
                                </div>
                            </div>
                            <div className="row" style={{ marginTop: "10px" }}>
                            <div className="tableContainer">
                                {rowData.length > 0 ? <DataTable
                                    columns={columns}
                                    data={rowData}
                                    className="table table-striped table-bordered table-hover"
                                    customStyles={customStyles}
                                    striped
                                    pagination
                                    paginationPerPage={50}
                                    paginationRowsPerPageOptions={[20, 50, 100]}
                                /> : <NoData headers={columns}></NoData>}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-1" style={{ width: "4%" }}>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button id="btnSave" className="btn btn-md btn-success" variant="primary" onClick={saveEmails}>
                        Save
                        </Button>
                    <Button className="btn btn-md btn-danger" variant="secondary" onClick={handleClose}>
                        Cancel
                        </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default EmailNotificationModal;