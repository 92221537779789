import React from 'react';
export const menuData_NotoAdmin = [
    {
        name: "Home",
        level: "1",
        url: "/GlobalSettings",
        children: [
            {
                name: "Designer",
                level: "2", 
                url: "/",
                children: [
                    {
                        name: "Global Settings",
                        level: "3",
                        url: "/GlobalSettings"
                    }
                    //{
                    //    name: "Distributions",
                    //    level: "3",
                    //    url: "/DistributionList"
                    //},
                    //{
                    //    name: "Notifications",
                    //    level: "3",
                    //    url: "/NotificationTemp"
                    //},
                    //{
                    //    name: "Notification Rule",
                    //    level: "3",
                    //    url: "/NotificationRuleList"
                    //} 
                ]
            },
            //{
            //    name: "Report",
            //    level: "2",
            //    url: ""
            //}, 
            {
                name: "Admin",
                level: "2",
                url: "/",
                children: [
                    {
                        name: "Assign Role",
                        level: "3",
                        url: "/AssignUserRoleList"
                    },
                    {
                        name: "Assign User",
                        level: "3",
                        url: "/AssignUserByProject"
                    },
                    {
                        name: "Manage User",
                        level: "3",
                        url: "/ManageUserList"
                    }]
            }
        ]
    },
    {
        name: "Help",
        level: "1",
        url: "/",
        children: [
            {
                name: " ",
                level: "2",
                url: ""
            }]
    },
    {
        name: "Contact Support",
        level: "1",
        url: "/",
        children: [
            {
                name: " ",
                level: "2",
                url: ""
            }]
    },
    {
        name: "About",
        level: "1",
        url: "/AboutUs",
        children: [
            {
                name: " ",
                level: "2",
                url: ""
            }]
    }
]