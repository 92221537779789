import { configureStore, combineReducers } from '@reduxjs/toolkit'
import globalDataReducer from './globaldata';
import authReducer from './auth';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

const persistConfig = {
    key: 'root',
    storage,
}

//const store = configureStore({
//    reducer: {
//        globaldata: globalDataReducer,
//        auth: authReducer
//    }
//})

const rootReducer = combineReducers({
    globaldata: globalDataReducer,
    auth: authReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer)

const notostore = configureStore({
    reducer: persistedReducer,
    middleware: [thunk]
});

export const persistor = persistStore(notostore);
export default notostore;