import React from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import TextArea from '../UI/Control/InputTextarea';
import SelectList from '../UI/Control/SelectList';
import { useSelector } from 'react-redux'
import MandatoryLabel from '../UI/Control/MandatoryLabel';

const IssueWithVideo = (props) => {

    const ActTaken = [
        {
            Name: "Deleted in CIMS",
            Value: "del"
        },
        {
            Name: "None",
            Value: "none"
        }
    ]
    const UpdStage = [
        {
            Name: "Closed with Issues",
            Value: "issue"
        }
    ]

    let taskId = props.taskId;
    const EDCUserID = useSelector(state => state.auth.gValue01);

    const SaveClosedIssueVideo = (event) => {

        let objNotes = document.getElementById('txtNotes');
        if (objNotes.value.trim() == '') {
            alert('Notes should not be empty.');
            objNotes.focus();
            return false;
        }

        let objClosedIssueVideoModel = {
            TaskID: taskId,
            Notes: objNotes.value,
            CreationUser: EDCUserID
        };

        fetch('api/ImageService/SaveClosedIssueVideo', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(objClosedIssueVideoModel)
        }).then(r => r.json()).then(res => {
            if (res == -1) {
                alert('Notes not saved, Something went wrong.');
            }
            if (res > 0) {
                props.relaodAction();
            }
        })

    };

    return (
        <div>
            <Row className="mt-2 mb-2">
                <Col md={1}>Notes<MandatoryLabel/>: </Col>
                <Col md={5}>
                    <TextArea id="txtNotes" rows='5' cols='70' />
                </Col>
                <Col md={5}>
                    <Row className="mt-2 mb-2">
                        <Col md={4}>
                            Action Taken:
                 </Col>
                        <Col md={8}>
                            <SelectList dataSource={ActTaken} />
                        </Col>
                    </Row>
                    <Row className="mt-2 mb-2">
                        <Col md={4}>
                            Update Stage:
                 </Col>
                        <Col md={8}>
                            <SelectList dataSource={UpdStage} />
                        </Col>
                    </Row>
                </Col>
                <Col md={1}> <Button style={{ marginTop: "90px" }} className="btn-success" onClick={SaveClosedIssueVideo} > Submit </Button></Col>
            </Row>
        </div>
    );
}
export default IssueWithVideo;
