import { createSlice } from '@reduxjs/toolkit';

const initialAuthState = {
    bValue01: false,   
    bValue02: true,
    bValue03: true,  
}

const authSlice = createSlice({
    name: 'Authentication',
    initialState: initialAuthState,
    reducers: {
        login(state, action) {
            state.bValue01 = action.payload.isUserAuth          
            state.bValue02 = action.payload.isSecQues;
            state.bValue03 = action.payload.isAcceptAggr; 
        },
        setUserAuth(state, action) {
            state.bValue01 = action.payload.isUserAuth
        },
        logout(state) {
            state.bValue01 = false;          
            state.bValue02 = true;
            state.bValue03 = true;        
        }
    }
})
export const authAction = authSlice.actions;

export default authSlice.reducer;