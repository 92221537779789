import React, { useState, useEffect, useRef } from "react";
import { useSelector } from 'react-redux';
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import DataTable from "react-data-table-component";
import Card from '../UI/Card/Card';
import InputString from '../UI/Control/InputString';
import customStyles from '../customStyles';
import NoData from '../NoData';
import AssignUserModal from "./AssignUserModal";

const AssignUserByProject = (props) => {
    const EDCUserID = useSelector(state => state.auth.gValue01);
    const [q, setQ] = useState('');
    const [inputVal, setInputVal] = useState('');
    const [rowData, setRows] = useState([]);
    const [arrAssignedRole, setAssignedRoleList] = useState([]);
    const [userPermission, setUserPermisssion] = useState('RO'); //RO, RW, NA
    const [arrImgServRole, setImgServRole] = useState([]);
    const getUserRole = () => {
        fetch('api/Users/GetRoleByID?userID=' + EDCUserID)
            .then(response => response.text())
            .then(role => {
                setCompPermission(role);
            });
    };

    useEffect(() => {
        FillAssignedRole();
        fetchImageServiceRoles();
        fetchStudyData();
        getUserRole();
    }, []);

    const setCompPermission = (usrRole) => {
        if (usrRole == "Read-Only")
            setUserPermisssion("RO");
        else if (usrRole == "Database Designer" || usrRole == "Super User")
            setUserPermisssion("RW");
        else if (usrRole == "Tester")
            setUserPermisssion("RW");
        else if (usrRole == "Database Administrator")
            setUserPermisssion("RW");
        else if (usrRole == "Management, Image Services" || usrRole == "Image Services Coordinator")
            setUserPermisssion("RO");
        else if (usrRole == "Clinical Data Manager" || usrRole == "Manager, Clinical Data Management")
            setUserPermisssion("RW");
        else
            setUserPermisssion("NA");
    }

    const columns = [
        {
            name: "StudyID",
            selector: row => row.StudyID,
            cellExport: row => row.StudyID,
            omit: true
        },
        {
            name: "Study Name",
            selector: row => row.StudyName,
            cellExport: row => row.StudyName,
            sortable: true,
            center: true
        },
        {
            name: "Description",
            selector: row => row.StudyDesc,
            cellExport: row => row.StudyDesc,
            sortable: true,
            center: true,
            omit: true
        },
        {
            name: "Protocol",
            selector: row => row.ProtocolCode,
            cellExport: row => row.ProtocolCode,
            sortable: true,
            center: true
        },
        {
            name: "Action",
            button: true,
            className: 'headercaption',
            width: userPermission == "RW" ? '550px' : '0px',
            style: {
                'border-right-style': 'solid',
                'border-width': '1px',
                'border-color': 'rgba(0, 0, 0, 0.12)'
            },
            cell: (row) => (
                <div style={{ "padding": "5px" }}>
                    {userPermission == "RW" && <AssignUserModal btn="icon" header="Assign User" studyName={row.StudyName} studyId={row.StudyID} arrAssignedRole={arrAssignedRole} userPermission={userPermission} arrImgServRole={arrImgServRole}></AssignUserModal>}
                </div>
            )
        }
    ];

    const filteredColumns = ['StudyName', 'StudyDesc', 'ProtocolCode'];
    function search(rows) {
        let selString = q.toString();
        let searchText = selString.toLowerCase().split(",");
        return rows.filter((row) => {
            return filteredColumns.some((column) => {
                if (searchText.length > 1)
                    return searchText.indexOf(row[column].toString().toLowerCase()) > -1;
                else
                    return row[column].toString().toLowerCase().indexOf(q.toLowerCase()) > -1;
            })
        })
    }

    const searchChangeHandler = (e) => {
        setQ(e.target.value);
        setInputVal(e.target.value);
    }

    const FillAssignedRole = () => {
        fetch('api/Users/FillAssignedRole')
            .then(response => response.json())
            .then(data => {
                let arr = [{ Value: '', Name: '' }];
                for (var iCount = 0; iCount < data.length; iCount++) {
                    arr.push({ Value: data[iCount].Value, Name: data[iCount].Name });
                }
                setAssignedRoleList(arr);
            });
    }

    const fetchImageServiceRoles = () => {
        fetch('api/Users/GetImageServiceRoles')
            .then(response => response.json())
            .then(data => {
                let arr = [{ Value: '0', Name: '' }];
                for (var iCount = 0; iCount < data.length; iCount++) {
                    arr.push({ Value: data[iCount].Value, Name: data[iCount].Name });
                }
                setImgServRole(arr);
            });
    }

    const fetchStudyData = () => {
        fetch('api/Users/GetStudies', {
            })
            .then(response => response.json())
            .then(data => {
                setRows(data);
            });
    }

    return <Card>
        <Row style={{ marginBottom: "10px" }}>
            <Col md={6}>
                <div className="input-group">
                    <InputString id="txtSearch" value={inputVal} onChangeHandler={searchChangeHandler} placeholder="Search" />
                </div>
            </Col>
            <Col md={4} />
        </Row>
        <div className="tableContainer">
            {search(rowData).length > 0 ? <DataTable
                columns={columns}
                data={search(rowData)}
                className="table table-striped table-bordered table-hover"
                customStyles={customStyles}
                striped
                pagination
                paginationPerPage={50}
                paginationRowsPerPageOptions={[20, 50, 100]}
            /> : <NoData headers={columns}/>}
        </div>
    </Card>
}

export default AssignUserByProject;