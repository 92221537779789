import React, { useState, useEffect } from 'react';
import classes from './../TrialstatNoto.module.css';
import { Row, Col } from 'react-bootstrap';
import InputContainer from '../UI/Container/InputContainer';
import ViewQueryDetails from './ViewQueryDetails';
import QueryAddEditModal from './QueryAddEditModal';

const QueryHeaderDetails = (props) => {
    const [arrQueryDetails, setArrQueryDetails] = useState([]);

    const GetQueriesByTaskID = () => {
        fetch('api/TaskQuery/GetQueriesByTaskID?taskId=' + props.taskId)
            .then(response => response.json())
            .then(data => {
                setArrQueryDetails(data.listQueryDetails);
            });
    }
    useEffect(() => {
        GetQueriesByTaskID();
    }, [props.isQuerySubmitted]);

    return (
    <div>
        {
            arrQueryDetails.length > 0 && (
                <Row>
                    <Col md={12} >
                        <InputContainer Expended='false' header="Query Details" style={{ textAlign: "center", marginTop: "15px" }}>
                            <Row style={{ marginLeft: "5px" }}>
                                <Col md={1} className={classes.cellRLBBorder}><b>Query ID</b></Col>
                                <Col md={2} className={classes.cellRightBorder}><b>Status</b></Col>
                                <Col md={2} className={classes.cellRightBorder}><b>Created By</b></Col>
                                <Col md={2} className={classes.cellRightBorder}><b>Created Date</b></Col>
                                <Col md={2} className={classes.cellRightBorder}><b>Link</b></Col>
                            </Row>
                                {arrQueryDetails.map((item, index) =>
                                    <Row key={index} style={{ marginLeft: "5px" }}>
                                    <Col md={1} className={classes.cellRLBBorder}>{item.QueryID}</Col>
                                    <Col md={2} className={classes.cellRightBorder}>{item.QueryStatus}</Col>
                                    <Col md={2} className={classes.cellRightBorder}>{item.CreationUser}</Col>
                                    <Col md={2} className={classes.cellRightBorder}>{item.CreationDate}</Col>
                                    <Col md={2} className={classes.cellRightBorder}>
                                            {props.UserPermission == "RO" && <ViewQueryDetails Title="Query Details" btnText="Query Details" QueryID={item.QueryID} />}
                                            {props.UserPermission == "RW" && <QueryAddEditModal UserRole="ImageService" queryid={item.QueryID} Title="Query Details" btnText="View Details" />}
                                    </Col>
                                </Row>
                            )}
                        </InputContainer>
                    </Col>
                </Row>)
            }
            </div>
    );
};

export default QueryHeaderDetails;
