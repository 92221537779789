import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useSearchParams } from 'react-router-dom';
import DataTable from "react-data-table-component";
import SelectList from '../UI/Control/SelectList';
import Button from '../UI/Button/Button';
import Card from '../UI/Card/Card';
import InputString from '../UI/Control/InputString';
import InputTextarea from '../UI/Control/InputTextarea';
import BorderedContainer from '../UI/Container/BorderedContainer';
import customStyles from '../customStyles';
import NoData from '../NoData';

const NotificationRuleDetails = (props) => {
    const navigate = useNavigate();
    const [q, setQ] = useState('');
    const [inputVal, setInputVal] = useState('');
    const [searchParams] = useSearchParams();
    const action = searchParams.get('action');
    const isDisabled = (action == 'view' ? true : false);
    
    const arrNotiTemplate = [
        {
            Name: "Template 1",
            Value: "Template1"
        },
        {
            Name: "Template 2",
            Value: "Template2"
        },
        {
            Name: "Template 3",
            Value: "Template3"
        },
        {
            Name: "Template 4",
            Value: "Template4"
        },
        {
            Name: "Template 5",
            Value: "Template5"
        }
    ]

    const arrStudyName = [
        {
            Name: "Study 1",
            Value: "Study1"
        },
        {
            Name: "Study 2",
            Value: "Study2"
        },
        {
            Name: "Study 3",
            Value: "Study3"
        },
        {
            Name: "Study 4",
            Value: "Study4"
        },
        {
            Name: "Study 5",
            Value: "Study5"
        }
    ]

    const arrRuleType = [
        {
            Name: "Type 1",
            Value: "Type1"
        },
        {
            Name: "Type 2",
            Value: "Type2"
        },
        {
            Name: "Type 3",
            Value: "Type3"
        },
        {
            Name: "Type 4",
            Value: "Type4"
        },
        {
            Name: "Type 5",
            Value: "Type5"
        }
    ]
    const arrEDCQuery = [
        {
            Name: "Query 1",
            Value: "Query1"
        },
        {
            Name: "Query 2",
            Value: "Query2"
        },
        {
            Name: "Query 3",
            Value: "Query3"
        },
        {
            Name: "Query 4",
            Value: "Query4"
        },
        {
            Name: "Query 5",
            Value: "Query5"
        }
    ]

    const emailContColumns = [
        {
            name: "",
            selector: row => row.EmailContactID,
            cellExport: row => row.EmailContactID,
            omit: true
        },
        {
            name: "Name of List",
            selector: row => row.NameofList,
            cellExport: row => row.NameofList,
            sortable: true,
            center: true
        },
        {
            name: "Date Created",
            selector: row => row.DateCreated,
            cellExport: row => row.DateCreated,
            sortable: true,
            center: true
        },
        {
            name: "Create By",
            selector: row => row.CreateBy,
            cellExport: row => row.CreateBy,
            sortable: true,
            center: true
        },
        {
            name: "Study Name",
            selector: row => row.StudyName,
            cellExport: row => row.StudyName,
            sortable: true,
            center: true
        },
        {
            name: "Number of Recipients",
            selector: row => row.NumberofRecipients,
            cellExport: row => row.NumberofRecipients,
            sortable: true,
            center: true
        },
        {
            name: "Last Used",
            selector: row => row.LastUsed,
            cellExport: row => row.LastUsed,
            sortable: true,
            center: true,
            style: {
                'border-right-style': 'solid',
                'border-width': '1px',
                'border-color': 'rgba(0, 0, 0, 0.12)'
            },
        }
    ];

    let emailContData = [
        {
            EmailContactID: 1,
            NameofList: 'Demo 01',
            DateCreated: "02 January 2022",
            CreateBy: "",
            StudyName: "All",
            NumberofRecipients: 10,
            LastUsed: "21 January 2022"
        }
    ];

    const assignedContColumns = [
        {
            name: "AssignedContID",
            selector: row => row.AssignedContID,
            cellExport: row => row.AssignedContID,
            omit: true
        },
        {
            name: "Name of List",
            selector: row => row.NameofList,
            cellExport: row => row.NameofList,
            sortable: true,
            center: true
        },
        {
            name: "Date Created",
            selector: row => row.DateCreated,
            cellExport: row => row.DateCreated,
            sortable: true,
            center: true
        },
        {
            name: "Number of Recipients",
            selector: row => row.NumberofRecipients,
            cellExport: row => row.NumberofRecipients,
            sortable: true,
            center: true
        },
        {
            name: "Option",
            button: true,
            width: '100px',
            style: {
                'border-right-style': 'solid',
                'border-width': '1px',
                'border-color': 'rgba(0, 0, 0, 0.12)'
            },
            cell: (row) => (
                <div style={{ "padding": "5px", display: action == 'view' ? "none" : "" }}>
                    <button style={{ "margin": "5px" }} className="btn btn-sm btn-danger"> Delete </button>
                </div >
            )
        }
    ];

   

    let assignedContData = [
        {
            AssignedContID: 1,
            NameofList: 'Safety Event',
            DateCreated: "02 January 2022",
            NumberofRecipients: "10"
        },
        {
            AssignedContID: 2,
            NameofList: 'Application Required',
            DateCreated: "02 January 2022",
            NumberofRecipients: "12"
        },
        {
            AssignedContID: 3,
            NameofList: 'New Patient Enrolled',
            DateCreated: "02 January 2022",
            NumberofRecipients: "11"
        },
        {
            AssignedContID: 4,
            NameofList: 'Central Reader',
            DateCreated: "N/A",
            NumberofRecipients: "N/A"
        }
    ];

    function search(rows) {
        const columns = rows[0] && Object.keys(rows[0]);
        if (q != '') {
            let selString = q.toString();
            let searchText = selString.toLowerCase().split(",");
            return rows.filter((row) => {
                return columns.some((column) => {
                    if (searchText.length > 1)
                        return searchText.indexOf(row[column].toString().toLowerCase()) > -1
                    else
                        return row[column].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
                })
            })
        }
        else
            return rows;
    }
    const searchChangeHandler = (e) => {
        setQ(e.target.value);
        setInputVal(e.target.value);
    }

    const SubmitData = (event) => {
        navigate('/NotificationRuleList');
    };

    const HandleClose = (event) => {
        navigate('/NotificationRuleList');
    };

    const [rowEmailContData, setEmailContRows] = useState(emailContData);
    const [rowAssinContData, setAssignContRows] = useState(assignedContData);

    return (
        <Card>
            <BorderedContainer style={{ width: "100%", height: "auto" }}>
                <div className="row mt-30">
                    <div className="col-sm-12" style={{ textAlign: "center", marginBottom:"20px" }}>
                        <h5> <b> Notification Settings </b></h5>
                    </div>
                </div>
            <div className="row">
                <div className="col-sm-1">
                </div>
                <div className="col-sm-10">
                    <div className="row">
                        <div className="col-sm-4">
                            <strong>Notification Rule Name :</strong>
                                <InputString id="txtNotiRuletName" placeholder="Notification Rule Name" disabled={isDisabled} />
                        </div>
                        <div className="col-sm-4">
                            <strong>Study Name</strong>
                                <SelectList id="selectStudyName" dataSource={arrStudyName} disabled={isDisabled} />
                        </div>
                        <div className="col-sm-4">
                           
                            <strong>Notification Template</strong>
                                <SelectList id="selectNotiTemplate" dataSource={arrNotiTemplate} disabled={isDisabled} />
                        </div>
                    </div>
                </div>
                <div className="col-sm-1">
                </div>
            </div>
            <div className="row mt-5">
                    <div className="col-sm-7">
                        <div className="tableContainer">
                            <div className="row">
                                <div className="col-sm-4 d-flex align-items-center" style={{ textAlign: "left" }}>
                                    <h5><strong>Assign Email Contacts</strong></h5>
                                </div>
                                <div className="col-sm-8">
                                    <InputString id="txtSearch" onChangeHandler={searchChangeHandler} placeholder="Search" style={{ marginBottom: "6px" }} disabled={isDisabled} />
                                </div>
                            </div>
                            { search(rowEmailContData).length > 0 ? <DataTable
                                    columns={emailContColumns}
                                    data={search(rowEmailContData)}
                                    className="table table-striped table-bordered table-hover"
                                    customStyles={customStyles}
                                    selectableRows="true"
                                    selectableRowsHighlight
                                    striped
                            /> : <NoData headers={emailContColumns}></NoData>}
                            <div style={{ marginTop: "10px", float: "right", display: action == 'view' ? "none" : "" }}>
                                <Button className="btn btn-success">Assign Central Reader</Button>
                                <Button className="btn btn-success" style={{marginLeft:"5px"}}>Assign List</Button>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-5">
                        <div className="tableContainer">
                            <div className="row">
                                <div className="col-sm-6 d-flex align-items-center" style={{ textAlign: "left", marginBottom: "13px"  }}>
                                    <h5><strong>Assigned Contact</strong></h5>
                                </div>
                            </div>
                            { rowAssinContData.length > 0 ? <DataTable
                                    columns={assignedContColumns}
                                    data={rowAssinContData}
                                    className="table table-striped table-bordered table-hover"
                                    customStyles={customStyles}
                                    striped
                                /> : <NoData headers={assignedContColumns}></NoData> }
                        </div>
                        </div>
               
            </div>
            
            </BorderedContainer>
            <BorderedContainer style={{ width: "100%", height: "auto" }}>
                <div className="row mt-30">
                    <div className="col-sm-12" style={{ textAlign: "center", marginBottom: "20px" }}>
                        <h5> <b> Notification Rule </b></h5>
                    </div>
                </div>
                <div className="row ">
                    <div className="col-sm-1" style={{ width: "6%" }}>
                    </div>
                    <div className="col-sm-4" style={{ width: "40%" }}>
                        <div className="row mt-2">
                            <div className="col-sm-3">
                                <label>Rule Type</label>
                            </div>
                            <div className="col-sm-9">
                                <SelectList id="selectRuleType" dataSource={arrRuleType} disabled={isDisabled}/>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-sm-3">
                                <label>EDC Form</label>
                            </div>
                            <div className="col-sm-9">
                                <InputTextarea id="txtEDCForm" rows="4" cols="75" disabled={isDisabled}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-1" style={{ width: "6%" }}>
                    </div>
                    <div className="col-sm-5" style={{ width: "40%" }}>
                        <div className="row mt-2">
                            <div className="col-sm-3">
                                <label>EDC Query</label>
                            </div>
                            <div className="col-sm-9">
                                <SelectList id="selectEDCQuery" dataSource={arrEDCQuery} disabled={isDisabled}/>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-sm-3">
                                <label>EDC Visit</label>
                            </div>
                            <div className="col-sm-9">
                                <InputTextarea id="txtEDCVisit" rows="4" cols="75" disabled={isDisabled} />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-1" style={{ width: "6%" }}>
                    </div>
                </div>
            </BorderedContainer>

            <div className="row mt-5">
                <div className="col-sm-12" style={{ textAlign: "center" }}>
                    <Button variant="secondary" className="btn btn-success" onClick={SubmitData} style={{ display: action == 'view' ? "none" : "" }}>
                        Create Rule
                    </Button>
                    <Button variant="primary" className="btn btn-danger" style={{ marginLeft: "10px" }} onClick={HandleClose}>
                        Close
                    </Button>
                </div>
            </div>
        </Card>

    );
};

export default NotificationRuleDetails;
