import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { globalDataAction } from '../../app/globaldata';
import { Button } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import classes from './../TrialstatNoto.module.css';
import Designer from './Designer';
import EditIcon from '../../Images/Icons/edit.svg';
const ViewStudyModal = (props) => {
    const dispatch = useDispatch();
    dispatch(globalDataAction.storeStudyProtocolID(props.studyProtocolId));
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <Button alt="View" style={props.style} onClick={handleShow} className="btn btn-warning"> View </Button>
            <Modal show={show} onHide={handleClose} dialogClassName={classes.TSModalDailog_95pc} animation={false} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title style={{ textAlign: "center" }}><h5> {props.studyName + " - " + props.protocolVersion}</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Designer Action="View" onCloseHandler={handleClose} studyId={props.studyId} userRole={props.userRole} />
                </Modal.Body>
            </Modal>
        </>
    );
}
export default ViewStudyModal;