//Documentation@ https://blog.logrocket.com/create-collapsible-react-components-react-collapsed/
import React, { useState } from 'react';
import useCollapse from 'react-collapsed';
import extScreenImg from '../../../Images/Icons/ExpandScreen.svg'
import colScreenImg from '../../../Images/Icons/CollapseScreen.svg'
import classes from '../../TrialstatNoto.module.css'

const InputContainer = (props) => {
    let flagExpended = props.Expended ? props.Expended : false;
    const [isExpanded, setExpanded] = useState(flagExpended);
    const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });

    function handleOnClick() {
        // Do more stuff with the click event!
        // Or, set isExpanded conditionally 
        setExpanded(!isExpanded);
    }
    let lableText_Exp = <p>{props.header} <img className={classes.collapsible_icon} src = {extScreenImg} /> </p>
    let lableText_Col = <p>{props.header} <img className={classes.collapsible_icon} src = {colScreenImg} /> </p>
    return (
        <div className="collapsible" className={props.border && props.border === 'true' ? classes.collapsibleBodyBorder : null} style={props.stylePR && props.stylePR}>           
            <div className="header" className={classes.collapsibleHeader} {...getToggleProps({ onClick: handleOnClick })} style={props.style} >
                {isExpanded ? lableText_Exp : lableText_Col}
            </div>
            <div {...getCollapseProps()}>                
                    {props.children}
            </div>            
        </div>
    );
};
export default InputContainer;
