import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import classes from './../TrialstatNoto.module.css';
import SelectList from '../UI/Control/SelectList';
import InputString from '../UI/Control/InputString';
import MandatoryLabel from '../UI/Control/MandatoryLabel';
import { useSelector } from 'react-redux'

const CopyReadSettingsModal = (props) => {
    const [show, setShow] = useState(false);
    const [arrVisit, setVisitList] = useState([]);
    const EDCUserID = useSelector(state => state.auth.gValue01);
    const [arrSourceType, setArrSourceType] = useState([]);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        fillVisitList();
        setAPITextBox(false);
        setShow(true);        
    };

    function showHideOptions() {
        document.getElementById('txtAPILink').hidden = false;
        document.getElementById('ddlAPIVisits').hidden = true;
    }

    //Retrive the state from app store
    const fillVisitList = () => {
        fetch('api/ReadSettings/fillVisitList?studyProtocolId=' + props.studyProtocolID)
            .then(response => response.json())
            .then(data => {
                let arr = [{ Value: '', Name: '' }]
                for (var iCount = 0; iCount < data.length; iCount++) {
                    arr.push({ Value: data[iCount].Value, Name: data[iCount].Name });
                }
                setVisitList(arr);
                showHideOptions();
            });
        FillSourceTypeList();
        
    }

    //Retrive Source Type
    const FillSourceTypeList = () => {
        fetch('api/ReadSettings/FillSourceTypeList?studyProtocolId=' + props.studyProtocolID)
            .then(response => response.json())
            .then(data => {
                let arr = [{ Value: '0', Name: '' }]
                for (var iCount = 0; iCount < data.length; iCount++) {
                    arr.push({ Value: data[iCount].Value, Name: data[iCount].Name });
                }
                setArrSourceType(arr);
            });
    }

    function ValidateData() {
        const objVisit = document.getElementById('selectVisit');
        if (objVisit.value == '') {
            alert('Please select Visit.');
            objVisit.focus();
            return false;
        }

        const objType = document.getElementById('selectType');
        if (objType.value == '0') {
            alert('Please select read type.');
            objType.focus();
            return false;
        }

        if (document.getElementById('selectType').value == 1 || document.getElementById('selectType').value == 2) {
            let objAPILink = document.getElementById('ddlAPIVisits');
            if (showAPITextBox == true) {
                objAPILink = document.getElementById('txtAPILink');
                if (objAPILink.value.trim() == '') {
                    alert('API link should not be empty.');
                    objAPILink.focus();
                    return false;
                }
            }
            else {
                objAPILink = document.getElementById('ddlAPIVisits');
                if (objAPILink.value == '-1' || objAPILink.value == '') {
                    alert('API link should not be empty.');
                    objAPILink.focus();
                    return false;
                }
            }
        }

        return true;
    }

    const CopyReadSettingsData = (event) => {
        const retVal = ValidateData();
        if (retVal == false)
            return false;

        var selectedText = '';
        if (document.getElementById('selectType').value == 1 || document.getElementById('selectType').value == 2) {
            var objDDL = document.getElementById("ddlAPIVisits");
            
            if (showAPITextBox == true) {
                selectedText = document.getElementById('txtAPILink').value;
            }
            else {
                selectedText = objDDL.options[objDDL.selectedIndex].text;
            }
        }

        let readSettingsModel =
        {
            StudyReadSettingsID: props.studyReadSettingsID,
            StudyProtocolID: props.studyProtocolID,
            VisitShort: document.getElementById('selectVisit').value,
            StudyVisitID: document.getElementById('selectVisit').value,
            ReadType: document.getElementById('selectType').value,
            APILink: selectedText,
            Username: EDCUserID
        }
        
        fetch('api/ReadSettings/CopyReadSettings', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(readSettingsModel)
        }).then(r => r.json()).then(res => {
            if (res == -1) {
                alert('Read settings not copied, Something went wrong.');
                return false;
            }
            if (res == 0) {
                alert('Read settings already exist.');
                return false;
            }
            if (res == -2) {
                var objSelType = document.getElementById('selectType');
                var selectText = objSelType.options[objSelType.selectedIndex].innerHTML;
                alert('API link already exist for source type ' + selectText + '.');
                return false;
            }
            if (res > 0) {
                props.fetchReadSettingData();
                setShow(false);
            }
        })
        return true;
    };

    const [arrAPIVisits, setAPIVisits] = useState([]);
    const [showAPITextBox, setAPITextBox] = useState([]);
    const GetAPILinkVisitList = () => {
        var VistsItemList = [{ Value: "-1", Name: "-- Select API Link--" } ];
        setAPITextBox(false);
        var isShowTxtBox = false;
        fetch("api/CIMSServicesCall/GetVisitsInCIMSStudy?SpId=" + props.studyProtocolID)
            .then(response => response.text())
            .then(data => {

                if (data != "" && data != "emptyid") {
                    var apiRes = JSON.parse(data);
                    var cimsVisits = apiRes.visits;

                    if (cimsVisits.length == 0) {
                        isShowTxtBox = true;
                    }
                    else {
                        for (var iCount = 0; iCount < cimsVisits.length; iCount++) {

                            VistsItemList.push({ Value: cimsVisits[iCount], Name: cimsVisits[iCount] });
                        }
                    }
                }
                else if (data == "emptyid") {
                    //alert('API Link list not available, please add manually');
                    isShowTxtBox = true;
                }
                else
                    alert('Error while getting API Link details,  please look into the ErrorLog Table.');

                setAPIVisits(VistsItemList);
                
                if (isShowTxtBox == true) {
                    setAPITextBox(true);
                    document.getElementById('txtAPILink').hidden = false;
                    document.getElementById('ddlAPIVisits').hidden = true;
                }
                else if (props.studyReadSettingsID > 0) {
                    document.getElementById('txtAPILink').hidden = true;
                    document.getElementById('ddlAPIVisits').hidden = false;
                }
            })
    }   
    const HideAPILink = () => {
        setAPITextBox(false);
        document.getElementById('txtAPILink').hidden = true;
        document.getElementById('ddlAPIVisits').hidden = true;
        setAPIVisits([]);
        document.getElementById('divAPILink').hidden = true;
    };

    const slectTypeangeHandler = (e) => {
        if (document.getElementById('selectType').value == 1 || document.getElementById('selectType').value == 2) {
            let actType = document.getElementById("selectType");
            var selectedText = actType.options[actType.selectedIndex].text;
            document.getElementById('divAPILink').hidden = false;

            if (selectedText.toLowerCase() == "cims") {
                GetAPILinkVisitList();
                document.getElementById('txtAPILink').hidden = true;
                document.getElementById('ddlAPIVisits').hidden = false;
            }
            else {
                setAPITextBox(true);
                document.getElementById('txtAPILink').hidden = false;
                document.getElementById('ddlAPIVisits').hidden = true;
                setAPIVisits([]);
            }
        }
        else {
            HideAPILink();
        }
    };

    return (
        <>
            <button className="btn btn-secondary" type="button" onClick={handleShow}> Copy </button>

            <Modal show={show} onHide={handleClose} dialogClassName={classes.TSModalDailog_Ten} animation={false} backdrop="static">
                <Modal.Header className={classes.modalHeader}>
                    <div className="d-flex align-items-center justify-content-center" style={{ width: "100%" }}>
                        <Modal.Title>{props.header}</Modal.Title>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group" style={{ marginTop: "10px" }}>
                        <label htmlFor="selectVisit" className={classes.label_One}>Visit<MandatoryLabel></MandatoryLabel></label>
                        <SelectList id="selectVisit" dataSource={arrVisit} style={{ marginTop: "5px", lineHeight: "1.8rem", fontSize: "18px" }}></SelectList>
                    </div>
                    <div className="form-group" style={{ marginTop: "15px" }}>
                        <label htmlFor="selectType" className={classes.label_One}>Type<MandatoryLabel></MandatoryLabel></label>
                        <SelectList id="selectType" dataSource={arrSourceType} style={{ marginTop: "5px", lineHeight: "1.8rem", fontSize: "18px" }} onChangeHandler={slectTypeangeHandler} ></SelectList>
                    </div>
                    <div id="divAPILink" className="form-group" style={{ marginTop: "15px" }}>
                        <label htmlFor="txtAPILink" className={classes.label_One}>API LINK<MandatoryLabel/></label>
                        <SelectList id="ddlAPIVisits" dataSource={arrAPIVisits} style={{ marginTop: "5px", lineHeight: "1.8rem", fontSize: "18px" }} disabled={props.isDisabled} />
                        <InputString id="txtAPILink" style={{ marginTop: "5px", borderWidth: '1px', lineHeight: "1.8rem", fontSize: "18px" }} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex align-items-center justify-content-center" style={{ width: "100%" }}>
                        {!props.disabled && <Button className="btn btn-success" style={{ marginRight: "5px" }} onClick={CopyReadSettingsData}> Copy </Button>}
                        <Button variant="primary" className="btn btn-danger" onClick={handleClose}>
                            Cancel
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default CopyReadSettingsModal;