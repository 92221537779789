import React, { useState } from 'react'
import { Button, Row, Col } from 'react-bootstrap';
import TextArea from '../UI/Control/InputTextarea';
import InputString from '../UI/Control/InputString';
import InputRadio from '../UI/Control/InputRadio';
import DateTime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import MandatoryLabel from '../UI/Control/MandatoryLabel';

const TestVideo = (props) => {

    let taskId = props.taskId; 
    const [readingdate, setReadingDate] = useState('');

    const SaveTestVideo = (event) => {

        let objNotes = document.getElementById('txtNotes');
        if (objNotes.value.trim() == '') {
            alert('Notes should not be empty.');
            objNotes.focus();
            return false;
        }

        let objPageVisualizedVerified = document.querySelector("input[name=PageVisualizedVerified]:checked");
        if (objPageVisualizedVerified == null) {
            alert('Please select scanning page visualized and verified (if Applicable)?.');
            objPageVisualizedVerified.focus();
            return false;
        }

        if (readingdate == '') {
            alert('Date of recording should not be empty.');
            return false;
        }

        let objDeviceIDMatched = document.querySelector("input[name=DeviceIDMatched]:checked");
        if (objDeviceIDMatched == null) {
            alert('Please select device ID matches records?.');
            objDeviceIDMatched.focus();
            return false;
        }

        let objVideoInColor = document.querySelector("input[name=VideoInColor]:checked");
        if (objVideoInColor == null) {
            alert('Please select video is in colour?.');
            objVideoInColor.focus();
            return false;
        }

        let objImageScanned = document.querySelector("input[name=ImageScanned]:checked");
        if (objImageScanned == null) {
            alert('Please select sample images scanned?.');
            objImageScanned.focus();
            return false;
        }

        let objIdentifiersVisible = document.querySelector("input[name=IdentifiersVisible]:checked");
        if (objIdentifiersVisible == null) {
            alert('Please select potential identifiers/faces visible?.');
            objIdentifiersVisible.focus();
            return false;
        }

        let objWorkInstructionOpen = document.querySelector("input[name=WorkInstructionOpen]:checked");
        if (objWorkInstructionOpen == null) {
            alert('Please select correct study-specific Work Instruction open?.');
            objWorkInstructionOpen.focus();
            return false;
        }

        let objPCSReportOpent = document.querySelector("input[name=PCSReportOpent]:checked");
        if (objPCSReportOpent == null) {
            alert('Please select correct Participating Center Staff (PCS) Report open?.');
            objPCSReportOpent.focus();
            return false;
        }

        let objContactsSpelledCorrectly = document.querySelector("input[name=ContactsSpelledCorrectly]:checked");
        if (objContactsSpelledCorrectly == null) {
            alert('Please select all required Sponsor contacts included & spelled correctly?.');
            objContactsSpelledCorrectly.focus();
            return false;
        }

        let objNoteEDCSiteID = document.getElementById('txtNoteEDCSiteID');

        let objTestVideoModel = {
            TaskID: taskId,
            NoteEDCSiteID: objNoteEDCSiteID.value,
            Notes: objNotes.value,
            PageVisualizedVerified: objPageVisualizedVerified.value,
            RecordingDate: readingdate,
            DeviceIDMatched: objDeviceIDMatched.value,
            VideoInColor: objVideoInColor.value,
            ImageScanned: objImageScanned.value,
            IdentifiersVisible: objIdentifiersVisible.value,
            WorkInstructionOpen: objWorkInstructionOpen.value,
            PCSReportOpent: objPCSReportOpent.value,
            ContactsSpelledCorrectly: objContactsSpelledCorrectly.value,
        };

        fetch('api/ImageService/SaveTestVideo', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(objTestVideoModel)
        }).then(r => r.json()).then(res => {
            if (res == -1) {
                alert('Test Video not saved, Something went wrong.');
            }
            if (res > 0) {
                props.relaodAction();
            }
        })

    };

    function FormatedDate(d) {
        const date = new Date(d);
        const formattedDate = date.toLocaleDateString('en-GB', {
            day: 'numeric', month: 'short', year: 'numeric'
        }).replace(/ /g, '-');
        setReadingDate(formattedDate);
    }

    return (
        <div>            
            <Row className="mt-2 mb-2">
                <Col md={1}> Noto EDC Site ID: </Col>
                <Col md={4}><InputString id="txtNoteEDCSiteID" width="200px" maxlength="127" /></Col>
            </Row>
            <Row className="mt-2 mb-2">
                <Col md={6}>
                    <Row>
                        <Col md={2}>
                            Notes<MandatoryLabel/>:
                    </Col>
                        <Col md={10}>
                            <TextArea id="txtNotes" rows='12' cols='60' />
                        </Col>
                    </Row>
                </Col>
                <Col Col={6}>
                    <Row className="mt-2 mb-2">
                        <Col md={6}>
                            Scanning Page visualized and verified (if Applicable)? <MandatoryLabel /> :
             </Col>
                        <Col md={6}>
                            <InputRadio id="PageVisualizedVerifiedYes" orientation='horizontal' value="Y" name="PageVisualizedVerified" text="Yes" style={{ "margin-left": "10px", "margin-right": "5px" }}></InputRadio>
                            <InputRadio id="PageVisualizedVerifiedNo" orientation='horizontal' value="N" name="PageVisualizedVerified" text="No" style={{ "margin-left": "10px", "margin-right": "5px" }}></InputRadio>
                            <InputRadio id="PageVisualizedVerifiedNA" orientation='horizontal' value="N" name="PageVisualizedVerified" text="N/A" style={{ "margin-left": "10px", "margin-right": "5px" }}></InputRadio>
                        </Col>
                    </Row>
                    <Row className="mt-2 mb-2">
                        <Col md={6}>
                            Date of Recording <MandatoryLabel /> :
                        </Col>
                        <Col md={6} style={{ width: "200px" }}>
                            <DateTime name="txtDateofRecording" timeFormat="" dateFormat="DD-MMM-yyyy" onChange={(date) => {
                                const d = new Date(date);
                                FormatedDate(d);
                            }}/>
                        </Col>
                    </Row>
                    <Row className="mt-2 mb-2">
                        <Col md={6}>
                            Device ID matches records? <MandatoryLabel /> :
                        </Col>
                        <Col md={6}>
                            <InputRadio id="DeviceIDMatchedYes" orientation='horizontal' value="Y" name="DeviceIDMatched" text="Yes" style={{ "margin-left": "10px", "margin-right": "5px" }}></InputRadio>
                            <InputRadio id="DeviceIDMatchedNo" orientation='horizontal' value="N" name="DeviceIDMatched" text="No" style={{ "margin-left": "10px", "margin-right": "5px" }}></InputRadio>
                        </Col>
                    </Row>
                    <Row className="mt-2 mb-2">
                        <Col md={6}>
                            Video is in colour? <MandatoryLabel /> :
             </Col>
                        <Col md={6}>
                            <InputRadio id="VideoInColorYes" orientation='horizontal' value="Y" name="VideoInColor" text="Yes" style={{ "margin-left": "10px", "margin-right": "5px" }}></InputRadio>
                            <InputRadio id="VideoInColorNo" orientation='horizontal' value="N" name="VideoInColor" text="No" style={{ "margin-left": "10px", "margin-right": "5px" }}></InputRadio>
                        </Col>
                    </Row>
                    <Row className="mt-2 mb-2">
                        <Col md={6}>
                            Sample images scanned? <MandatoryLabel /> :
             </Col>
                        <Col md={6}>
                            <InputRadio id="ImageScannedYes" orientation='horizontal' value="Y" name="ImageScanned" text="Yes" style={{ "margin-left": "10px", "margin-right": "5px" }}></InputRadio>
                            <InputRadio id="ImageScannedNo" orientation='horizontal' value="N" name="ImageScanned" text="No" style={{ "margin-left": "10px", "margin-right": "5px" }}></InputRadio>
                        </Col>
                    </Row>
                    <Row className="mt-2 mb-2">
                        <Col md={6}>
                            Potential identifiers/faces visible? <MandatoryLabel /> :
             </Col>
                        <Col md={6}>
                            <InputRadio id="IdentifiersVisibleYes" orientation='horizontal' value="Y" name="IdentifiersVisible" text="Yes" style={{ "margin-left": "10px", "margin-right": "5px" }}></InputRadio>
                            <InputRadio id="IdentifiersVisibleNo" orientation='horizontal' value="N" name="IdentifiersVisible" text="No" style={{ "margin-left": "10px", "margin-right": "5px" }}></InputRadio>
                        </Col>
                    </Row>
                    <Row className="mt-2 mb-2">
                        <Col md={6}>
                            Correct study-specific Work Instruction open? <MandatoryLabel /> :
                        </Col>
                        <Col md={6}>
                            <InputRadio id="WorkInstructionOpenYes" orientation='horizontal' value="Y" name="WorkInstructionOpen" text="Yes" style={{ "margin-left": "10px", "margin-right": "5px" }}></InputRadio>
                            <InputRadio id="WorkInstructionOpenNo" orientation='horizontal' value="N" name="WorkInstructionOpen" text="No" style={{ "margin-left": "10px", "margin-right": "5px" }}></InputRadio>
                        </Col>
                    </Row>
                    <Row className="mt-2 mb-2">
                        <Col md={6}>
                            Correct Participating Center Staff (PCS) Report open? <MandatoryLabel /> :
                        </Col>
                        <Col md={6}>
                            <InputRadio id="PCSReportOpentYes" orientation='horizontal' value="Y" name="PCSReportOpent" text="Yes" style={{ "margin-left": "10px", "margin-right": "5px" }}></InputRadio>
                            <InputRadio id="PCSReportOpentNo" orientation='horizontal' value="N" name="PCSReportOpent" text="No" style={{ "margin-left": "10px", "margin-right": "5px" }}></InputRadio>
                        </Col>
                    </Row>
                    <Row className="mt-2 mb-2">
                        <Col md={6}>
                            All required Sponsor contacts included & spelled correctly? <MandatoryLabel /> :
                        </Col>
                        <Col md={4}>
                            <InputRadio id="ContactsSpelledCorrectlyYes" orientation='horizontal' value="Y" name="ContactsSpelledCorrectly" text="Yes" style={{ "margin-left": "10px", "margin-right": "5px" }}></InputRadio>
                            <InputRadio id="ContactsSpelledCorrectlyNo" orientation='horizontal' value="N" name="ContactsSpelledCorrectly" text="No" style={{ "margin-left": "10px", "margin-right": "5px" }}></InputRadio>
                        </Col>
                        <Col md={2}>
                            <Button className="btn-success" onClick={SaveTestVideo} > Submit </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>           
        </div>
        );
}
export default TestVideo;