import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";
import Card from '../UI/Card/Card';
import { Button, Row, Col } from "react-bootstrap";
import closeImg from '../../Images/Icons/CloseWindow.svg'
import InputTextarea from '../UI/Control/InputTextarea';
import MandatoryLabel from '../UI/Control/MandatoryLabel';


const ReaderEndoeCRF = () => {
    const EDCUserID = useSelector(state => state.auth.gValue01);
    const location = useLocation();   
    let { TaskID } = location.state;
    let { StudyParadigmCRFID } = location.state;
    const { ReadAssignmentID } = location.state;
    let { actionType } = location.state;
    let { readStage, procedureID } = location.state;
    let { UserType } = location.state;
    const [userRole, setUserRole] = useState('');
    const [procID, setProcID] = useState(procedureID);

    const [eCRFUrl, seteCRFUrl] = useState();
    const [httpError, setHttpError] = useState(null)

    const GetLRDetailsByTaskID = async () => {
        fetch('/api/ReadsAssigned/GetLRDetailsByTaskID?TaskID=' + TaskID)
            .then(response => response.json())
            .then(data => {
                setProcID(data.ProcedureID);
                fetcheCRFUrlSite(data.StudyParadigmCRFID, data.Stage);
            });
    }
    const fetcheCRFUrlSite = async (StudyParamCRFID, lrStage) => {
        fetch('/api/ReadsAssigned/eCRFAPIUrlSiteUser?TaskID=' + TaskID + '&StudyParadigmCRFID=' + StudyParamCRFID + '&actionType='+actionType+'&readStage=' + lrStage)
            .then(response => response.text())
            .then(data => {
                seteCRFUrl(data);
            })
    }
    const fetcheCRFUrl = async () => {
        const response = await fetch('/api/ReadsAssigned/eCRFAPIUrl?TaskID=' + TaskID + '&StudyParadigmCRFID=' + StudyParadigmCRFID + '&RdAssID=' + ReadAssignmentID + '&actionType=' + actionType + '&readStage=' + readStage)
        if (!response.ok) {
            throw new Error("Something went wrong, message :" + response.message + " \nHttp Error:" + response.httpError)
        }
        const eCRFAPIUrl = await response.text();
        seteCRFUrl(eCRFAPIUrl);
    }
    useEffect(() => {      
        if (UserType == "SiteUser") {
            GetLRDetailsByTaskID();
        }
        else {
            fetcheCRFUrl().catch((error) => {
                setHttpError(error.message)
            })
        }        
        getUserRole();
    }, []); 
    const navigate = useNavigate();    
    const winCloseHandler = () => {
        {/* userRole === 'Endoscopy Central Reader' ? navigate("/DashboardEndo") : navigate("/DashboardHisto") */ }
        if (userRole === 'Endoscopy Central Reader')
            navigate("/DashboardEndo")
        else if (userRole === 'Site User')
            navigate("/SiteDashboard")
        else
            navigate("/DashboardHisto") 
    }

    const getUserRole = () => {
        fetch('api/Users/GetRoleByID?userID=' + EDCUserID)
            .then(response => response.text())
            .then(usrRole => {
                setUserRole(usrRole);
            });
    }

    const handleNoChangeRequired = () => {
        let model = {
            ReadAssignmentID: ReadAssignmentID
        };

        fetch('api/ReadsAssigned/NoChangeRequired', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(model)
        }).then(r => r.json()).then(res => {
            if (res == -1 || res == 0) {
                alert('No change required not saved, Something went wrong.');
                return false;
            }
            if (res > 0) {
                { userRole == 'Endoscopy Central Reader' && navigate("/DashboardEndo") }
                { userRole == 'Histopathology Central Reader' && navigate("/DashboardHisto") }
            }
        })

    };

    window.addEventListener('message', receiveMessage, true);
    function receiveMessage(msg) {
        if (msg.data.action == 'hide') {
            document.getElementById('divNochangeReq').style.display = 'none';
        }
    }

    return (
        <Card>
            <Row>
                <Col id="divIFrame" md="12">
                    
                    <img id="ifrmBtnClose" onClick={winCloseHandler} style={{ "border": "1px solid #dcdcdc", "cursor": "pointer", "float": "right" }} height="25px" width="25px" src={closeImg} />
                    <div className="row">
                        <div className="col-sm-4">
                            <div className="alert alert-success" style={{ padding: "8px", marginBottom:"0px" }}>
                                <strong>Task ID :</strong> {TaskID} &ensp;&emsp;&ensp;&emsp;&ensp;&emsp; <strong>Procedure ID :</strong> {procID}
                            </div>
                        </div>
                        <div className="col-sm-4">
                        </div>
                        <div id="divNochangeReq" className="col-sm-4" style={{ display: (actionType == "read" || UserType == "SiteUser" ? "none" : "") }}>
                            <Button className="btn btn-md btn-success" onClick={handleNoChangeRequired} variant="primary" style={{ float: "right" }}> No Change Required </Button>
                        </div>
                    </div>
                    <div className="row" style={{ marginTop: "5px" }}>
                        <iframe style={{ "border": "1px solid #dcdcdc" }} height="750px" id="iframeCRF" width="100%" src={eCRFUrl}></iframe>
                    </div>
                </Col>                
            </Row>
        </Card>
    );
}
export default ReaderEndoeCRF;