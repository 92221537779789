import React from 'react';
import Button from '../UI/Button/Button';
import { useSelector } from 'react-redux'

const ServiceAgreement = (props) => {
    const EDCUserID = useSelector(state => state.auth.gValue01);
    const AcceptAgreement = (event) => {

        let objSecQues_Agreement = {
            EDCUserId: EDCUserID
        };

        fetch('api/Users/SerAgreement', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(objSecQues_Agreement)
        }).then(res => res.text()).then(data => {
            if (data == 0 || data == -1) {
                alert('Agreement does not accepted, Something went wrong.');
            }
            if (data > 0) {
                props.setAccAgrTrue();
            }
        });
    };

    const RejectAgreement = (event) => {
        fetch('api/Users/ClearSession', {
            method: 'POST'
        })
        .then(response => response.text())
        .then(data => {
            props.dispatchLogout();
        });
    };

    return (
        <div className="container" style={{ "width": "90%" }}>
            <div className="row">
                <div className="col-xs-0 col-sm-2 col-md-2 col-lg-0">
                </div>
                <div className="col-xs-12 col-sm-8 col-md-8 col-lg-12">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12" align="center">
                        <h2 align="center"><strong>Welcome To TrialStat EDC</strong></h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-0 col-sm-1 col-md-1 col-lg-0">
                        </div>
                        <div className="col-xs-12 col-sm-10 col-md-10 col-lg-12">
                            <div className="panel panel-default">
                                <div className="panel-body"><strong>To continue using the TrialStat EDC please read the standard end user's agreement below and click the "Accept" button. Once you have accepted the agreement, you will not see this screen in subsequent sessions.</strong></div>
                            </div>
                        </div>
                        <div className="col-xs-0 col-sm-1 col-md-1 col-lg-0">
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-0 col-sm-0 col-md-1 col-lg-0">
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-10 col-lg-12" align="left">
                            <div className="panel-group">
                                <h4 style={{ fontSize: "20px" }} align="center"><strong>Thank you for using TrialStat EDC.</strong></h4>
                                    <div className="panel panel-default">
                                    <div className="panel-heading" style={{ fontSize: "20px"}}><strong>Terms and Conditions for License and Use </strong></div>
                                    <div className="panel-body" style={{ fontSize: "20px" }}>THESE TERMS AND CONDITIONS CONTAIN LEGAL OBLIGATIONS. PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING A TRIALSTAT SOLUTIONS INC. SOFTWARE (OTHERWISE REFERRED TO AS "TRIALSTAT").</div>

                                    <div className="panel-heading" style={{ fontSize: "20px" }}><strong>Introduction</strong></div>
                                    <div className="panel-body" style={{ fontSize: "20px" }}>
                                            <p>These Terms and Conditions apply to all such products which reference these Terms and Conditions, including all sites found under or linked from the URL trialstat.com, unless otherwise noted within the site.</p>
                                            <p>TrialStat will not sell, lease or trade your contact or personal information to third parties. We will not use or disclose personal information other than for the purpose it was collected, unless we have your consent or as permitted or required by law.</p>
                                            <p>By using any of the products or services (collectively the "Services") available by TRIALSTAT, you agree, without limitation or qualification, to be bound by these terms and conditions and such other additional or alternative terms, conditions, rules and policies which are displayed or to which you may be directed in connection with any particular such site or Service, all as may be modified by TrialStat Solutions Inc. from time to time in their sole discretion (collectively the "Terms and Conditions"). If you do not agree with these Terms and Conditions, you may not use these services.</p>
                                            <p>Please note that we reserve the right, at our discretion, to change, modify, add, or remove portions of these Terms and Conditions at any time. Please check periodically for changes. Your continued use of the sites referred to herein following the posting of any changes to these Terms and Conditions will mean you accept those changes.</p>
                                        </div>
                                    <div className="panel-heading" style={{ fontSize: "20px" }}><strong>Registration, Accounts and Passwords</strong></div>
                                    <div className="panel-body" style={{ fontSize: "20px" }}>
                                            <p>Certain of the Services and related features made available on these sites may require registration or subscription. Should you choose to register or subscribe for any such Services or related features, you agree to provide accurate and current information about yourself as required by the relevant registration or subscription process, and to promptly update such information as necessary to ensure that it is kept accurate and complete. You agree to be responsible for: (a) maintaining the confidentiality of any passwords or other account identifiers which you choose or are assigned as a result of any registration or subscription on the sites referred to herein, and (b) all activities that occur under such password or account. You agree to notify Trialstat Solutions Inc. of any unauthorized use of your password or account. Trialstat Solutions Inc. shall not be responsible or liable, directly or indirectly, in any way for any loss or damage of any kind incurred as a result of, or in connection with, your failure to comply with this Section. This section applies whether registration was performed electronically, or by direct contact with a representative of Trialstat Solutions Inc.</p>
                                        </div>
                                    <div className="panel-heading" style={{ fontSize: "20px" }}><strong>Restrictions on Use of Content</strong></div>
                                    <div className="panel-body" style={{ fontSize: "20px" }}>
                                            <p>Unless otherwise specified, we grant you a non-exclusive, non-transferable, limited right to access and use the Services provided through the sites referred to herein and the material provided hereon for your use, provided that you comply fully with the provisions of these Terms &amp; Conditions. You acknowledge that all information in the websites you are accessing to use the Services, or in printed books or other material provided or obtained from TrialStat Solutions Inc., in either case including software, photos, video, text, graphics, questions, creative suggestions, messages, comments, feedback, ideas, notes, drawings, articles and other materials (collectively, "Content") are protected by copyrights, patents, industrial designs, trade-marks, trade secrets and/or other intellectual property or proprietary rights, and that these rights are valid and protected in all forms, media and technologies existing now or hereafter developed. All Content is copyrighted under Canadian copyright laws (and, if applicable, similar foreign laws), and we own a copyright in the "look and feel", selection, co-ordination, arrangement, and enhancement of such Content.</p>
                                            <p>Our commercial partners, suppliers, advertisers, sponsors, licensors, contractors and other third parties may also have additional proprietary rights in the Content which they make available on this Website.</p>
                                            <p>You may not modify, publish, transmit, distribute, perform, participate in the transfer or sale, create derivative works of, or in any way exploit, any of the Content, in whole or in part. Subject to the provisions below, when Content is downloaded to your computer, you do not obtain any ownership interest in such Content. Modification of the Content or use of the Content for any other purpose, including, but not limited to, use of any Content in printed form or on any other website or networked computer environment is strictly prohibited unless you receive our prior written consent. All Content is for your viewing and use only and at all times to be viewed, used and accessed on a confidential basis. You hereby agree not to disclose, publish or otherwise reveal any of the Content to any other party whatsoever except with the specific prior authorization of TrialStat Solutions Inc.. You agree not to use any Content except for the express purposes set out herein.  You may not redistribute, sell, de-compile, reverse engineer, disassemble, or otherwise reduce any aspect of the Content to a human-readable form.  You agree to notify Trialstat Solutions Inc. immediately of any unauthorized use, access or viewing of any Content.</p>
                                            <p>All trade-marks appearing on this Website are trade-marks of their respective owners. Any use of any trademarks appearing on a TRIALSTAT or any of the Services without the express written consent of the owner of the trademark is strictly prohibited.</p>
                                        </div>
                                    <div className="panel-heading" style={{ fontSize: "20px" }}><strong>Exception for Research Content</strong></div>
                                    <div className="panel-body" style={{ fontSize: "20px" }}>
                                            <p>Our Restriction of rights as set out above, does not apply to Research Content added by authorized users of the Services described herein. Research Content consists of:</p>
                                            <p>(1) Site or patient data from clinical research. Rights to this information remain with the researcher in accordance with the terms and conditions of the applicable agreements entered into between Trialstat Solutions Inc. and other parties with respect to those specific undertakings.</p>
                                            <p>By uploading materials including Research Content to any Service, chat board or forum available on a TRIALSTAT or otherwise submitting any materials to us through any other content generating or submitting means, you automatically grant (or warrant that the owner of such materials expressly grants) Trialstat Solutions Inc. a world-wide, perpetual, royalty-free, irrevocable and nonexclusive right and license to use, copy, adapt, transmit, communicate, distribute internally and create compilations and derivative works from such submitted materials, for the limited purposes of maintaining, upgrading, improving, backing up, or otherwise sustaining the Services being used by the user. Such license shall apply with respect to any form, media, or technology now known or later developed. In addition, you warrant that all "moral rights" in such materials have been waived.</p>
                                        </div>
                                    <div className="panel-heading" style={{ fontSize: "20px" }}><strong>Disclaimer</strong></div>
                                    <div className="panel-body" style={{ fontSize: "20px" }}>
                                            <p>YOU EXPRESSLY UNDERSTAND AND AGREE THAT SUBJECT ONLY TO THE EXPRESS PROVISIONS OF SPECIFIC AGREEMENTS ENTERED INTO WITH RESPECT TO SPECIFIC CLINICAL RESEARCH PROJECTS, (A) A TRIALSTAT AND ANY OF THE SERVICES MADE AVAILABLE THROUGH IT ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS AND THAT WE MAKE NO WARRANTIES, REPRESENTATIONS OR CONDITIONS (as used in this section "WARRANTIES") OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT AND THAT ANY SUCH WARRANTIES ARE HEREBY EXPRESSLY DISCLAIMED, AND (B) TRIALSTAT SOLUTIONS INC. SPECIFICALLY MAKE NO WARRANTIES THAT A TRIALSTAT SITE OR ANY OF THE SERVICES, INCLUDING ANY CONTENT, INFORMATION, PRODUCTS OR SERVICES OBTAINED FROM OR THROUGH THE USE OF A TRIALSTAT SITE OR ANY OF THE SERVICES, WILL BE PROVIDED ON AN UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE BASIS OR THAT SUCH SERVICES OR THE RESULTS DERIVED THEREFROM WILL MEET YOUR REQUIREMENTS OR EXPECTATIONS.</p>
                                        </div>
                                    <div className="panel-heading" style={{ fontSize: "20px" }}><strong>Indemnification</strong></div>
                                    <div className="panel-body" style={{ fontSize: "20px" }}>
                                            <p>You agree to defend, indemnify and hold harmless each of Trialstat Solutions Inc., its affiliates and licensors and each of their respective officers, directors, employees and agents, including all third parties mentioned on a TRIALSTAT SERVICES, from and against any and all claims, actions or demands, including without limitation reasonable legal and accounting fees, resulting from or related to: (a) your breach of any of these Terms and Conditions; (b) your access to or use of a TRIALSTAT, Services or Content; or (c) your use or reliance on, or publication, communication or distribution of anything on or from a TRIALSTAT or through any of the Services. You shall use your best efforts to cooperate with us in the defense of any claim. We reserve the right, at our own expense, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you.</p>
                                        </div>
                                    <div className="panel-heading" style={{ fontSize: "20px" }}><strong>Limitation of Liability</strong></div>
                                    <div className="panel-body" style={{ fontSize: "20px" }}>
                                            <p>YOU EXPRESSLY UNDERSTAND AND AGREE THAT IN NO EVENT SHALL TRIALSTAT SOLUTIONS INC., INCLUDING ITS AFFILIATES AND LICENSORS, BE LIABLE FOR ANY DAMAGES WHATSOEVER, INCLUDING ANY DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL OR EXEMPLARY DAMAGES, AND ANY DAMAGES FOR LOSS OF PROFITS, SAVINGS, GOODWILL OR OTHER INTANGIBLE LOSSES, REGARDLESS OF WHETHER TRIALSTAT SOLUTIONS INC. HAD BEEN ADVISED OF OR COULD HAVE FORESEEN THE POSSIBILITY OF SUCH DAMAGES, ARISING OUT OF OR IN CONNECTION WITH: (A) THE USE, INABILITY TO USE OR PERFORMANCE OF ANY OF THE SERVICES OF A TRIALSTAT, OR (B) ANY UNAUTHORIZED ACCESS TO OR MODIFICATION TO ANY OF YOUR CONTENT OR TRANSMISSIONS, OR (C) ANY OTHER MATTER RELATING TO A TRIALSTAT OR ANY OF THE SERVICES.</p>
                                            <p>You expressly acknowledge that TrialStat Solutions Inc. has entered into this agreement, and has and will make a TRIALSTAT Services available to you in reliance upon the limitations and exclusions of liability and the disclaimers set forth herein, and that the same form an essential basis of the bargain between you and Trialstat Solutions Inc. as the case may be. You expressly agree that the limitations and exclusions of liability and the disclaimers set forth herein will survive, and continue to apply in the case of, a fundamental breach or breaches, the failure of essential purpose of contract, the failure of any exclusive remedy or termination of this agreement.</p>
                                        </div>
                                    <div className="panel-heading" style={{ fontSize: "20px" }}><strong>Governing Law/Jurisdictional Issues</strong></div>
                                    <div className="panel-body" style={{ fontSize: "20px" }}>
                                            <p>These Terms and Conditions shall be interpreted, construed and governed by the laws in force in the Province of Ontario, Canada, without reference to its conflict of laws principles. Subject to the Arbitration and Actions paragraph below, each party hereby agrees to submit to the jurisdiction of the courts of the Province of Ontario and to waive any objections based upon venue. The United Nations Convention on Contracts for the International Sale of Goods does not apply to these Terms and Conditions.</p>
                                        </div>
                                    <div className="panel-heading" style={{ fontSize: "20px" }}><strong>Arbitration and Actions</strong></div>
                                    <div className="panel-body" style={{ fontSize: "20px" }}>
                                            <p>Any controversy, claim or dispute arising out of or relating to these Terms and Conditions, your use of any TRIALSTAT Service or the relationship which results from these Terms and Conditions, including without limitation, the performance, breach, enforcement, existence or validity of the matters provided for in these Terms and Conditions which cannot be amicably resolved, even if only one of the parties declares that there is a difference (collectively, a "Claim"), will be referred to and finally settled (to the exclusion of the courts) by private and confidential binding arbitration before a single arbitrator held in Ottawa, Ontario in English and governed by Ontario law pursuant to the Arbitration Act, 1991 (Ontario), as amended, replaced or re-enacted from time to time. The arbitrator shall be a person who is legally trained and who has experience in the information technology field in Canada and is independent of either party. Any such Claim shall be arbitrated on an individual basis, and shall not be consolidated in any arbitration with any claim, controversy or dispute of any other party. You agree to waive any right you may have to commence or participate in any className action against us related to any Claim and, where applicable, you also agree to opt out of any className proceedings against us. Notwithstanding the foregoing, we reserve the right to pursue the protection of intellectual property rights and confidential information through injunctive or other equitable relief through the courts.</p>
                                        </div>
                                    <div className="panel-heading" style={{ fontSize: "20px" }}><strong>Termination</strong></div>
                                    <div className="panel-body" style={{ fontSize: "20px" }}>
                                            <p>You acknowledge and agree that Trialstat Solutions Inc., in their sole and absolute discretion, may, without notice to you, suspend or terminate your account or your use of, or access to, a TRIALSTAT Service, and remove and discard any information or content related to a TRIALSTAT Services (and your use thereof), for any reason, including where we believe that you have violated any of these Terms and Conditions. You further agree that we shall not be liable to you or to any other person as a result of any such suspension or termination, and you will hold us harmless from such liability. You shall have an obligation to keep all Content and Software confidential (except Content or Software which was in the public domain prior to this agreement, or which became publicly available after this agreement other than pursuant to a breach of this Agreement or which can be established by written evidence to have been in the possession of you prior to receipt of same pursuant to this agreement) and such obligation shall survive termination.</p>
                                        </div>
                                    <div className="panel-heading" style={{ fontSize: "20px" }}><strong>Submission of Ideas and/or Suggestions</strong></div>
                                    <div className="panel-body" style={{ fontSize: "20px" }}>
                                            <p>TrialStat Solutions Inc. is always improving its websites and Services and developing new features. If you have ideas and/or suggestions regarding improvements or additions, we would like to hear them - but any submission will be subject to these Terms and Conditions. UNDER NO CIRCUMSTANCES SHALL ANY DISCLOSURE OF ANY IDEA AND/OR SUGGESTION OR RELATED MATERIAL TO TrialStat Solutions Inc. BE SUBJECT TO ANY OBLIGATION OF CONFIDENTIALITY OR EXPECTATION OF COMPENSATION. BY SUBMITTING THE IDEA AND/OR SUGGESTION OR ANY RELATED MATERIAL TO Trialstat Solutions Inc. YOU ARE WAIVING ANY AND ALL RIGHTS THAT YOU MAY HAVE IN THE IDEA AND/OR SUGGESTION OR ANY RELATED MATERIAL AND ARE REPRESENTING AND WARRANTING TO Trialstat Solutions Inc. THAT THE IDEA AND/OR SUGGESTION OR ANY RELATED MATERIAL ARE WHOLLY ORIGINAL WITH YOU, THAT NO ONE ELSE HAS ANY RIGHTS IN THE IDEA AND/OR SUGGESTION OR ANY RELATED MATERIAL AND THAT TRIALSTAT SOLUTIONS INC. IS FREE TO IMPLEMENT THE IDEA AND/OR SUGGESTION AND TO USE THE RELATED MATERIAL IF IT SO DESIRES, AS PROVIDED OR AS MODIFIED BY Trialstat Solutions Inc. WITHOUT OBTAINING PERMISSION OR LICENSE FROM ANY THIRD PARTY.</p>
                                        </div>
                                    <div className="panel-heading" style={{ fontSize: "20px" }}><strong>General Provisions</strong></div>
                                    <div className="panel-body" style={{ fontSize: "20px" }}>
                                            <p>This agreement is personal to you, and may be subject to additional provisions in agreements entered into between TrialStat Solutions Inc. and other parties, such as a Principal Investigator, Research Institute, corporation or university. You may not assign your rights or obligations to anyone. Except as mentioned herein, these Terms and Conditions constitute the entire agreement between Trialstat Solutions Inc. and you pertaining to your use of a TRIALSTAT Services. Our failure to insist upon or enforce strict performance of any right or provision of these Terms and Conditions shall not constitute or be construed as a waiver of any right or provision. If any of the provisions (or parts thereof) contained in these Terms and Conditions are determined to be void, invalid or otherwise unenforceable by a court of competent jurisdiction, such determination shall not affect the remaining provisions (or parts thereof) contained herein. No changes to these Terms and Conditions shall be made except by a revised posting on this page or except as otherwise expressly contemplated herein. The parties have required that these Terms and Conditions and all documents relating thereto be drawn up in English. Les parties ont demandé que cette convention ainsi que tous les documents QUI s'y rattachent soient rédigés en anglais.</p>
                                        </div>
                                    </div>
                            </div>
                            <div style={{ textAlign: "center", marginBottom: "15px" }}>
                                <Button className="btn btn-danger" style={{ marginRight: "10px" }} onClick={RejectAgreement}>
                                        Reject
                                    </Button>
                                <Button className="btn btn-success" onClick={AcceptAgreement}>
                                        Accept
                                    </Button>
                                </div>
                        </div>
                                <div className="col-xs-0 col-sm-0 col-md-1 col-lg-0">
                                </div>
                </div>
                        </div>
                <div className="col-xs-0 col-sm-2 col-md-2 col-lg-0">
                </div>
            </div>
        </div>
    );
};

export default ServiceAgreement;
