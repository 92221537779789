import React, { useState, useEffect } from 'react';
import { Button, Modal, Row, Col } from 'react-bootstrap';
import InputCheckbox from '../UI/Control/InputCheckbox';
import InputTextarea from '../UI/Control/InputTextarea';
import SelectList from '../UI/Control/SelectList';
import MandatoryLabel from '../UI/Control/MandatoryLabel';
import InputString from '../UI/Control/InputString';

const AddQueryByTaskID = (props) => {
   
    const [arrCommentType, setCommentType] = useState([]);
    const [arrQuerytType, setQueryType] = useState([]);
    //const [arrQueryStatus, setQueryStatus] = useState([]);

    useEffect(() => {
        FillCommentType();
        FillQueryType();
    }, [])

    const FillCommentType = () => {
        fetch('api/TaskQuery/FillQueryCommentType')
            .then(response => response.json())
            .then(data => {
                setCommentType(data);
                //FillQueryStatus();
                //SetShowControls();
            });
    }

    //function SetShowControls() {

    //    if (props.action == "add") {
    //        document.getElementById("divCommentType").hidden = false;
    //        document.getElementById("divQueryStatus").hidden = true;
    //        document.getElementById("divHideSite").hidden = false;
    //    }
    //    else {
    //        document.getElementById("divCommentType").hidden = true;
    //        document.getElementById("divQueryStatus").hidden = false;
    //        document.getElementById("divHideSite").hidden = true;
    //    }
    //}

    //const FillQueryStatus = () => {
    //    fetch('api/TaskQuery/FillQueryStatus')
    //        .then(response => response.json())
    //        .then(data => {
    //            setQueryStatus(data);
    //        });
    //}
    const AddNewQuery = (event) => {
            let objDDList = document.getElementById("ddlCommentType");
            if (objDDList.value == '-1') {
                alert('Please select the Comment Type.');
                objDDList.focus();
                return false;
        }

        let objQueryType = document.getElementById("ddlQueryType");
        if (objQueryType.value == '-1') {
            alert('Please select query type.');
            objQueryType.focus();
            return false;
        }

        var selectedText = objQueryType.options[objQueryType.selectedIndex].text;

        if (selectedText.toLowerCase() == 'other') {
            var objOtherQuery = document.getElementById('txtOtherQuery');
            if (objOtherQuery.value.trim() == '') {
                alert('Other query type should not be empty.');
                objOtherQuery.focus();
                return false;
            }
        }

        let objComment = document.getElementById('txtComment');
        if (objComment.value.trim() == '') {
            alert('Query Comment should not be empty.');
            objComment.focus();
            return false;
        }
        var hideSite = document.getElementById('chkHideSite').checked ? 'Y' : 'N';

        let objQuery = {
            QueryId: props.queryid,
            QueryStatusId: 1,
            TaskId: props.TaskId,
            CommentTypeId: document.getElementById("ddlCommentType").value,
            HideFromSite: hideSite,
            QueryComment: document.getElementById('txtComment').value.trim(),
            HappyFoxID: document.getElementById('txtHappyFoxID').value.trim(),
            QueryTypeID: objQueryType.value,
            QueryTypeLabel: selectedText.toLowerCase() == 'other' ? document.getElementById('txtOtherQuery').value.trim() : ''
        };

        fetch('api/TaskQuery/SaveTaskQuery', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(objQuery)
        }).then(r => r.text()).then(res => {

            if (res == "success") {
                props.relaodAction();
            }
            else if (res == "notsaved") {
                alert('Query not added, Something went wrong.');
                return false;
            }
            else if (res == "-2") {
                alert(document.getElementById('txtOtherQuery').value.trim() + ' Query type already exists.');
                return false;
            }
            else if (res.startsWith("Error:")) {
                alert(res);
                return false;
            }
        })
    };

    const onHappyFoxIDChangeHandler = (e) => {
        if (e.target.value.trim() != '') {
            document.getElementById('chkHideSite').checked = true;
        }
    }

    const FillQueryType = () => {
        fetch('api/TaskQuery/GetQueryType')
            .then(response => response.json())
            .then(data => {
                setQueryType(data);
            });
    }

    const onChangeQueryTypeHandler = (e) => {
        let queryType = document.getElementById("ddlQueryType");
        var selectedText = queryType.options[queryType.selectedIndex].text;

        if (selectedText.toLowerCase() == 'other') {
            document.getElementById('txtOtherQuery').style.display = 'block';
        }
        else {
            document.getElementById('txtOtherQuery').value = '';
            document.getElementById('txtOtherQuery').style.display = 'none';
        }
    }

    return (
        <>
            <div className="row mt-2" id="divCommentType">
                <div className="col-sm-1" />
                <div className="col-sm-1">
                    <label>Comment Type<MandatoryLabel />:</label>
                </div>
                <div className="col-sm-2">
                    <SelectList id='ddlCommentType' dataSource={arrCommentType} style={{ marginBottom: "10px" }} />
                </div>
                <div className="col-sm-1">
                    <label>Query Type<MandatoryLabel />:</label>
                </div>
                <div className="col-sm-2">
                    <SelectList id='ddlQueryType' dataSource={arrQuerytType} onChangeHandler={onChangeQueryTypeHandler} style={{ marginBottom: "10px" }} />
                </div>
                <div className="col-sm-3">
                    <InputString id="txtOtherQuery" maxlength="254" placeholder="Other query type" style={{ display: "none" }} />
                </div>
            </div>  
            <div className="row mt-2" id="divHappyFoxID">
                <div className="col-sm-1" />
                <div className="col-sm-1">
                    <label>Happy Fox ID:</label>
                </div>
                <div className="col-sm-2">
                    <InputString id="txtHappyFoxID" maxlength="50" onChangeHandler={onHappyFoxIDChangeHandler} style={{ marginBottom: "10px" }} />
                </div>
                <div className="col-sm-1">
                    <label>Hide From Site:</label>
                </div>
                <div className="col-sm-2">
                    <InputCheckbox id="chkHideSite" name="chkHideSite" />
                </div>

                <div className="col-sm-1" />
            </div>   
            <div className="row mt-2">
                <div className="col-sm-2" />
                <div className="col-sm-10">
                    <div className="alert alert-warning" style={{ padding: "0.5rem", width: "fit-content" }}>
                        Do Not Include Confidential Information
                     </div>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-sm-1" />
                <div className="col-sm-1">
                    <label>Comment<MandatoryLabel />:</label>
                </div>
                <div className="col-sm-7">
                    <InputTextarea id="txtComment" rows="5" cols="75" />
                </div>
                <div className="col-sm-3" style={{ justifyContent: "space-between" }}>
                    <Button style={{ marginTop: "90px" }} className="btn btn-md btn-success" variant="primary" onClick={AddNewQuery}> Submit </Button>
                    <label>&nbsp;</label>
                    {/*</div>
                <div className="col-sm-1" style={{ display:"none" }}> */}
                    <Button style={{ marginTop: "90px", display: "none" }} className="btn btn-md btn-success" variant="primary" onClick={props.relaodAction}> Cancel </Button>
                </div>
                
            </div>
        </>
    );
}
export default AddQueryByTaskID;